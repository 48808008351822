import { useCallback, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAllDecisions,
  getAllPayloads,
  getHasDecisions,
} from 'redux/modules/experiments/selectors';
import {
  getMonetateExperimentById,
  matchDecisionByExperiment,
} from 'constants/monetateExperiments';
import { trackExperimentView } from 'redux/modules/experiments/actions/track-experiment-view';

export function useExperiments() {
  const dispatch = useDispatch();
  const hasDecisions = useSelector(getHasDecisions);
  const decisions = useSelector(getAllDecisions);
  const payloads = useSelector(getAllPayloads);

  const toTrack = useRef(new Map());
  const tracked = useRef(new Map());

  const getDecisionById = useCallback(
    (id, { manualTracking = false } = {}) => {
      const experiment = getMonetateExperimentById(id);
      if (!experiment) {
        console.error(
          `\n\nExperiment [${id}] is not registered!\n\nRegister it in 'src/constants/monetateExperiments.js'\n\n`,
        );
      }

      const variant = matchDecisionByExperiment(id, decisions?.[id]);

      if (!manualTracking) {
        toTrack.current.set(id, variant);
      }

      return {
        variant,
        payload: payloads?.[id],
        trackExperimentView: (trackAll = false) => {
          if (manualTracking) {
            dispatch(trackExperimentView({ id, variant, trackAll }));
          }
        },
      };
    },
    [decisions, dispatch, payloads],
  );

  useEffect(() => {
    toTrack.current.forEach((variant, id) => {
      if (!tracked.current.has(id)) {
        dispatch(trackExperimentView({ id, variant }));
        tracked.current.set(id, 1);
      }
    });
  });

  return { hasDecisions, getDecisionById };
}
