import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ExpansionPanel from '@johnlewispartnership/wtr-ingredients/ingredients/ExpansionPanel';

import TextCard from 'components/TradingCell/TextCard';
import PromoLink from 'components/TradingCell/PromoLink';
import ExpansionButton from 'components/TradingCell/ExpansionButton';
import PromoImagery from 'components/PromoImagery';

import { useClickToBuyContext } from 'components/ClickToBuy';

import promoClick from 'analytics/aem/promo-click';

import { iconShape, ImageType, TextAreaType } from 'constants/types/TradingCell';

import { getImageAltTextAndUrl } from 'analytics/aem/get-image-alt-text-and-url';

import style from 'components/TradingCell/index.scss';
import { TRADING_CELL_NAME } from 'components/AemComponent/analyticsConstants';
import { citrusAdClick } from 'analytics/gtmServerFunctions';
import { usePromoImpression } from 'hooks/use-promo-impression';

const TradingCell = ({
  analytics,
  analyticsTags,
  componentId,
  cqResponsiveClasses,
  defaultNumOfCells,
  externalImageSrc,
  gridPosition,
  icons,
  image,
  isCitrusAd = false,
  isInEditor = false,
  position,
  products,
  taxonomyLevel,
  textArea,
}) => {
  const { isAccordionOpen, setIsAccordionOpen, isInClickToBuy } = useClickToBuyContext();
  const visualGridPosition = isCitrusAd || isInClickToBuy ? position : `grid-${gridPosition + 1}`;
  const { link: { url = '', newWindow = false } = {} } = textArea;

  const oneCell = defaultNumOfCells === 'oneCell';
  const fourCells = defaultNumOfCells === 'fourCells';

  const imageryProps = {
    icons,
    image,
    portrait: false,
    externalImageSrc,
    lazyLoad: gridPosition > 10,
  };

  const observeRef = usePromoImpression({ componentId, analytics, position, products });

  const promoClickCallback = () => {
    if (isCitrusAd) {
      citrusAdClick(componentId);
    }

    promoClick(
      {
        ...analytics,
        creative: 'Trading Module',
        id: componentId,
        name: analytics?.promoName,
        position,
        ...getImageAltTextAndUrl(image),
        linkUrl: url,
        newWindow,
        products,
      },
      taxonomyLevel,
    );
  };

  const imageComponent = (
    <PromoLink {...textArea} promoClick={promoClickCallback}>
      <PromoImagery {...imageryProps} />
    </PromoLink>
  );

  return (
    <div
      ref={observeRef}
      id={`trading-asset-${visualGridPosition}`}
      data-testid="trading-cell"
      className={classNames(style.tradingCell, cqResponsiveClasses, {
        [style.noSpacing]: isInClickToBuy,
        [style.cmsGridSpacing]: isInEditor && !isInClickToBuy,
      })}
      {...analyticsTags}
    >
      <div
        id={componentId}
        className={style.tradingCellInner}
        data-testid={`tradingCell_${defaultNumOfCells}`}
      >
        <div className={style.expansionPanelFlex}>
          {isInClickToBuy ? (
            <ExpansionPanel
              isOpen={!isAccordionOpen}
              alwaysOpen={{ xs: false, sm: true, md: true, lg: true, xl: true }}
            >
              {imageComponent}
            </ExpansionPanel>
          ) : (
            imageComponent
          )}
        </div>

        <div className={style.text}>
          <TextCard
            {...textArea}
            {...{ oneCell, fourCells }}
            promoClick={promoClickCallback}
            showButton={!isInClickToBuy}
          />
        </div>

        {isInClickToBuy && (
          <ExpansionButton setIsOpen={setIsAccordionOpen} isOpen={isAccordionOpen} />
        )}
      </div>
    </div>
  );
};

TradingCell.displayName = TRADING_CELL_NAME;

TradingCell.defaultProps = {
  analyticsTags: undefined,
  cqResponsiveClasses: [],
  isCitrusAd: false,
  externalImageSrc: false,
  gridPosition: 0,
  isInEditor: false,
  locationName: undefined,
  parent: '',
  products: [],
  taxonomyLevel: undefined,
};

TradingCell.propTypes = {
  analyticsTags: PropTypes.shape({}),
  componentId: PropTypes.string.isRequired,
  isCitrusAd: PropTypes.bool,
  cqResponsive: PropTypes.shape({
    deviceBreakpoints: PropTypes.arrayOf(
      PropTypes.shape({
        layout: PropTypes.string,
        name: PropTypes.string,
        visible: PropTypes.bool,
        width: PropTypes.string,
        offset: PropTypes.string,
      }),
    ),
  }).isRequired,
  resourceType: PropTypes.string.isRequired,
  defaultNumOfCells: PropTypes.oneOf(['oneCell', 'twoCells', 'threeCells', 'fourCells']).isRequired,
  image: ImageType.isRequired,
  textArea: TextAreaType.isRequired,
  icons: PropTypes.shape({
    offerRoundel: PropTypes.shape({
      ...iconShape,
    }),
    messageIcon: PropTypes.shape({
      display: PropTypes.bool,
      data: PropTypes.shape({
        message: PropTypes.oneOf(['none', 'New', 'Try']),
      }),
    }),
    brandIcon: PropTypes.shape({
      ...iconShape,
      position: PropTypes.oneOf(['floated', 'pinned']),
    }),
  }).isRequired,
  analytics: PropTypes.shape({
    promoName: PropTypes.string,
  }).isRequired,
  cqResponsiveClasses: PropTypes.arrayOf(PropTypes.string),
  isInEditor: PropTypes.bool,
  locationName: PropTypes.string,
  parent: PropTypes.string,
  gridPosition: PropTypes.number,
  position: PropTypes.string.isRequired,
  taxonomyLevel: PropTypes.string,
  externalImageSrc: PropTypes.bool,
  products: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })),
};

export default TradingCell;
