import React from 'react';
import { TableBody } from '@johnlewispartnership/wtr-ingredients/ingredients/Table';
import { buildElementStyling } from '../utils';
import { RichTextNode } from '../types';

const RichTextTableBody = ({ children, value }: RichTextNode) => {
  const { className, style } = buildElementStyling({ value });
  return (
    <TableBody className={className} style={style}>
      {children}
    </TableBody>
  );
};

RichTextTableBody.displayName = 'RichTextTableBody';

export default RichTextTableBody;
