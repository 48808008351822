import update from 'immutability-helper';

import {
  ADD_ALL_ITEMS_PENDING,
  ADD_ALL_ITEMS_FULFILLED,
  ADD_ALL_ITEMS_REJECTED,
} from 'redux/modules/add-all-items/actions/types';

import {
  CLEAR_ITEMS_SET_FOR_REMOVAL,
  CLEAR_LAST_REMOVED_TROLLEY_ITEM,
  CREATE_NEW_TROLLEY_ITEM,
  FILTER_TROLLEY_ANALYTICS,
  GET_MINIMUM_ORDER_VALUES_SUCCESS,
  GET_TROLLEY_RECOMMENDATION_SUCCESS,
  GET_SUBSTITUTION_PRODUCTS,
  GET_SUBSTITUTION_PRODUCTS_SUCCESS,
  GET_SUBSTITUTION_PRODUCTS_FAILED,
  LOCK_TROLLEY,
  OPTIMISTICALLY_TOGGLE_SUB_ALL,
  OPTIMISTICALLY_UPDATE_MULTIPLE_TROLLEY_ITEMS,
  OPTIMISTICALLY_UPDATE_TROLLEY_ITEM,
  ORDER_LOAD_FAILED,
  ORDER_LOADED,
  ORDER_LOADING,
  REMOVE_ALL_OPTIMISTIC_TROLLEY_ITEMS,
  REMOVE_ITEM_FROM_TROLLEY,
  REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY,
  REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY_FAILED,
  REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY_SUCCESS,
  REMOVE_MULTIPLE_OPTIMISTIC_TROLLEY_ITEMS,
  REMOVE_OPTIMISTIC_TROLLEY_ITEM,
  SET_UNAVAILABLE_ITEM_FOR_REMOVAL,
  SUB_ALL_UPDATING,
  SUB_ALL_UPDATED,
  SUB_ALL_UPDATE_FAILED,
  TOGGLE_EMPTY_TROLLEY_MODAL,
  TOGGLE_PERSONALISED_MESSAGE_MODAL,
  TOGGLE_SHOPPER_NOTES_MODAL,
  TOGGLE_SUB_ALL_MODAL,
  TOGGLE_SWAP_UNAVAILABLE_PRODUCT_MODAL,
  TROLLEY_ITEM_UPDATE_FAILED,
  TROLLEY_ITEM_UPDATED,
  TROLLEY_ITEM_UPDATING,
  TROLLEY_QUEUE_ITEM_UPDATE_FAILED,
  TROLLEY_QUEUE_ITEM_UPDATED,
  TROLLEY_QUEUE_ITEM_UPDATING,
  UNLOCK_TROLLEY,
  UNDO_REMOVE_TROLLEY_ITEM,
  UNDO_REMOVE_TROLLEY_ITEM_FAILURE,
  UNDO_REMOVE_TROLLEY_ITEM_SUCCESS,
  UPDATE_CAN_SUBSTITUTE,
  UPDATE_INSTANT_CHECKOUT_LOCATION,
  UPDATE_PERSONALISED_MESSAGE,
  UPDATE_SHOPPER_NOTES,
  UPDATE_QUANTITY_OF_ITEM_IN_TROLLEY,
  UPDATE_TROLLEY_SORT,
} from 'redux/modules/trolley/actions/types';

import { createBatchId } from 'redux/modules/trolley/utils/createBatchId';
import initialState from './initial-state';
import { updateAnalytics } from './update-analytics';

export default function trolley(state = initialState, action = {}) {
  const { result = {}, type } = action;

  switch (type) {
    case CLEAR_LAST_REMOVED_TROLLEY_ITEM:
      return {
        ...state,
        lastRemovedTrolleyItem: undefined,
        lastReaddedTrolleyItem: undefined,
      };
    case GET_MINIMUM_ORDER_VALUES_SUCCESS:
      return {
        ...state,
        trolleyTotals: {
          ...state.trolleyTotals,
          deliveryMinimumOrderValue: {
            amount: result.deliveryMinimumOrderValue,
            currencyCode: 'GBP',
          },
          collectionMinimumOrderValue: {
            amount: result.collectionMinimumOrderValue,
            currencyCode: 'GBP',
          },
        },
      };
    case LOCK_TROLLEY:
      return {
        ...state,
        locked: true,
      };
    case UNLOCK_TROLLEY:
      return {
        ...state,
        locked: false,
      };
    case TROLLEY_QUEUE_ITEM_UPDATING:
    case ADD_ALL_ITEMS_PENDING:
      return {
        ...state,
        errorResponse: null,
        loading: true,
        loadingAdd: true,
      };
    case ORDER_LOADING:
      return {
        ...state,
        errorResponse: null,
        loading: true,
        loadingOrder: true,
        loadingCustomerInfo: action.initialLoad || false,
      };
    case SUB_ALL_UPDATING:
    case TROLLEY_ITEM_UPDATING:
      return {
        ...state,
        errorResponse: null,
        loading: true,
        loadingOrder: true,
      };
    case GET_SUBSTITUTION_PRODUCTS:
      return {
        ...state,
        errorResponse: null,
        loadingSubs: true,
      };
    case ORDER_LOADED: {
      if (state.locked) return state;

      const { slotChangeable = true, trolley: resultTrolley, instantCheckout } = result;

      if (!resultTrolley) return state;

      const { amendingOrder, trolleyItems, trolleyTotals } = resultTrolley;

      return {
        ...state,
        ...update(state, {
          trolleyItems: { $set: trolleyItems },
          trolleyTotals: { $set: trolleyTotals },
        }),
        ...updateAnalytics(state, trolleyItems),
        amendingOrder,
        errorResponse: null,
        loaded: true,
        loading: false,
        loadingOrder: false,
        loadingCustomerInfo: false,
        slotChangeable,
        instantCheckout,
      };
    }
    case ADD_ALL_ITEMS_FULFILLED: {
      if (state.locked) return state;

      const { slotChangeable = true, trolley: resultTrolley, instantCheckout } = result;

      if (!resultTrolley) return state;

      const { amendingOrder, trolleyItems, trolleyTotals } = resultTrolley;

      return {
        ...state,
        ...update(state, {
          trolleyItems: { $set: trolleyItems },
          trolleyTotals: { $set: trolleyTotals },
        }),
        ...updateAnalytics(state, trolleyItems),
        amendingOrder,
        errorResponse: null,
        loaded: true,
        loading: false,
        loadingAdd: false,
        slotChangeable,
        instantCheckout,
      };
    }
    case ORDER_LOAD_FAILED:
      return {
        ...state,
        errorResponse: action.error,
        loading: false,
        loadingOrder: false,
        loadingCustomerInfo: false,
        wcsPriceLoading: false,
        wcsPrices: {
          trolleyTotals: {},
        },
      };
    case CREATE_NEW_TROLLEY_ITEM:
      return {
        ...state,
        trolleyItems: update(state.trolleyItems, {
          $push: [action.trolleyItem],
        }),
      };
    case TROLLEY_ITEM_UPDATED:
    case REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY_SUCCESS:
    case UNDO_REMOVE_TROLLEY_ITEM_SUCCESS: {
      const { trolleyItem: { lineNumber } = {} } = action;
      return {
        ...state,
        errorResponse: null,
        loading: false,
        loadingOrder: false,
        trolleyScreenAnnoucement: {
          type: 'undo',
          lineNumber,
        },
      };
    }
    case TROLLEY_QUEUE_ITEM_UPDATED: {
      if (!result.trolley) {
        return {
          ...state,
          loadingAdd: false,
        };
      }

      const trolleyItems = result.trolley?.trolleyItems ?? [];
      const { analytics } = updateAnalytics(state, trolleyItems);
      const trolleyItemsWithMatchingLineNumber = trolleyItems.filter(
        item => item.lineNumber === action.lineNumber,
      );

      /**
       * Ensure we do not affect other trolley items if we temporarily end up
       * with more than one with the same ID
       */
      if (trolleyItemsWithMatchingLineNumber.length > 1) {
        return {
          ...state,
          analytics,
          loadingAdd: false,
        };
      }

      return update(state, {
        loadingAdd: { $set: false },
        analytics: { $set: analytics },
        trolleyItems: {
          $set: result.trolley.trolleyItems,
        },
        trolleyTotals: {
          $set: {
            ...result.trolley.trolleyTotals,
            minimumSpendThresholdMet: state.trolleyTotals.minimumSpendThresholdMet,
          },
        },
        errorResponse: { $set: null },
      });
    }
    case TROLLEY_QUEUE_ITEM_UPDATE_FAILED:
    case ADD_ALL_ITEMS_REJECTED:
      return {
        ...state,
        errorResponse: action.error,
        loading: false,
        loadingAdd: false,
      };
    case SUB_ALL_UPDATE_FAILED:
      return {
        ...state,
        errorResponse: action.error,
        loading: false,
        loadingOrder: false,
      };
    case TROLLEY_ITEM_UPDATE_FAILED: {
      const trolleyItemIndex = state.trolleyItems.findIndex(
        item => item.trolleyItemId === action.trolleyItem.trolleyItemId,
      );

      return {
        ...state,
        errorResponse: action.error,
        loading: false,
        loadingOrder: false,
        trolleyItems: update(state.trolleyItems, {
          [trolleyItemIndex]: { $set: action.trolleyItem },
        }),
      };
    }
    case GET_SUBSTITUTION_PRODUCTS_FAILED:
      return {
        ...state,
        errorResponse: action.error,
        loadingSubs: false,
      };
    case UNDO_REMOVE_TROLLEY_ITEM_FAILURE: {
      const { trolleyItem: { lineNumber } = {} } = action;
      const removedTrolleyItemIndex = state.trolleyItems.findIndex(
        item => item.lineNumber === lineNumber,
      );
      return {
        ...state,
        errorResponse: action.error,
        loading: false,
        loadingOrder: false,
        lastRemovedTrolleyItem: action?.trolleyItem ?? {},
        lastReaddedTrolleyItem: undefined,
        trolleyItems: update(state.trolleyItems, {
          $splice: [[removedTrolleyItemIndex, 1]],
        }),
      };
    }
    case REMOVE_ITEM_FROM_TROLLEY: {
      const { trolleyItem: { trolleyItemId, lineNumber } = {}, hasHardConflict } = action;

      return {
        ...state,
        trolleyItems: state.trolleyItems.map(item =>
          item.trolleyItemId === trolleyItemId
            ? { ...item, quantity: { amount: 0, uom: item.quantity.uom } }
            : item,
        ),
        lastRemovedTrolleyItem: !hasHardConflict
          ? action?.trolleyItem ?? {}
          : state?.lastRemovedTrolleyItem,
        lastReaddedTrolleyItem: undefined,
        trolleyScreenAnnoucement: {
          type: 'remove',
          lineNumber,
        },
      };
    }
    case UPDATE_QUANTITY_OF_ITEM_IN_TROLLEY: {
      const { trolleyItem: { lineNumber, quantity } = {} } = action;

      return {
        ...state,
        trolleyItems: state.trolleyItems.map(item =>
          item.lineNumber === lineNumber
            ? update(item, {
                quantity: { $set: quantity },
              })
            : item,
        ),
        trolleyScreenAnnoucement: {
          type: quantity.amount === 0 ? 'remove' : null,
          lineNumber: quantity.amount === 0 ? lineNumber : null,
        },
      };
    }
    case TOGGLE_EMPTY_TROLLEY_MODAL:
      return {
        ...state,
        emptyTrolleyModal: {
          ...state.emptyTrolleyModal,
          isOpen: !state.emptyTrolleyModal.isOpen,
        },
        trolleyScreenAnnoucement: {
          type: null,
          lineNumber: null,
        },
      };
    case TOGGLE_SHOPPER_NOTES_MODAL:
      return {
        ...state,
        shopperNotesModal: {
          ...state.shopperNotesModal,
          currentProductId: action.productId,
          currentProductNotes: action.notes,
          currentTrolleyItemId: action.trolleyItemId,
          currentLineNumber: action.lineNumber,
          isEdit: (action.notes || '').length > 0,
          isOpen: !state.shopperNotesModal.isOpen,
          isMobile: action.isMobile,
        },
      };
    case TOGGLE_SUB_ALL_MODAL:
      return {
        ...state,
        subAllModal: {
          isOpen: !state.subAllModal.isOpen,
        },
      };
    case TOGGLE_PERSONALISED_MESSAGE_MODAL:
      return {
        ...state,
        personalisedMessageModal: {
          ...state.personalisedMessageModal,
          currentProductMessage: action.message,
          currentTrolleyItemId: action.trolleyItemId,
          isEdit: (action.message || '').length > 0,
          isOpen: !state.personalisedMessageModal.isOpen,
          maxPersonalisedMessageLength: action.maxPersonalisedMessageLength,
        },
      };

    case TOGGLE_SWAP_UNAVAILABLE_PRODUCT_MODAL:
      return {
        ...state,
        swapUnavailableProductModal: {
          lineNumber: action.lineNumber,
          trolleyItemId: action.trolleyItemId,
          isOpen:
            action.isOpen !== undefined ? action.isOpen : !state.swapUnavailableProductModal.isOpen,
        },
      };
    case UPDATE_SHOPPER_NOTES:
      return {
        ...state,
        trolleyItems: state.trolleyItems.map(item =>
          item.trolleyItemId === action.trolleyItemId
            ? update(item, {
                $merge: { noteToShopper: action.notes },
              })
            : item,
        ),
      };
    case UPDATE_PERSONALISED_MESSAGE:
      return {
        ...state,
        trolleyItems: state.trolleyItems.map(item =>
          item.trolleyItemId === state.personalisedMessageModal.currentTrolleyItemId
            ? update(item, {
                $merge: { personalisedMessage: action.message },
              })
            : item,
        ),
      };
    case UPDATE_CAN_SUBSTITUTE:
      return {
        ...state,
        trolleyItems: state.trolleyItems.map(item =>
          item.productId === action.productId
            ? update(item, {
                $merge: {
                  canSubstitute: !item.canSubstitute,
                },
              })
            : item,
        ),
      };
    case UNDO_REMOVE_TROLLEY_ITEM: {
      const { trolleyItem: { lineNumber } = {} } = action;
      const trolleyItemIndex = state.trolleyItems.findIndex(item => item.lineNumber === lineNumber);
      if (trolleyItemIndex < 0) {
        return {
          ...state,
          errorResponse: null,
          loading: true,
          loadingOrder: true,
          lastRemovedTrolleyItem: undefined,
          lastReaddedTrolleyItem: lineNumber,
          trolleyItems: update(state.trolleyItems, {
            $push: [action.trolleyItem],
          }),
        };
      }

      return {
        ...state,
        errorResponse: null,
        loading: true,
        loadingOrder: true,
        lastRemovedTrolleyItem: undefined,
        lastReaddedTrolleyItem: lineNumber,
        trolleyItems: update(state.trolleyItems, {
          [trolleyItemIndex]: { $set: action.trolleyItem },
        }),
      };
    }
    case OPTIMISTICALLY_TOGGLE_SUB_ALL: {
      const { trolleyItems } = action;
      const batchId = createBatchId();

      return {
        ...state,
        ...update(state, {
          optimisticTrolleyItems: { $push: trolleyItems.map(item => ({ ...item, batchId })) },
        }),
      };
    }
    case OPTIMISTICALLY_UPDATE_TROLLEY_ITEM: {
      const optimisticTrolleyItemIndex = state.optimisticTrolleyItems.findIndex(
        item => item.lineNumber === action.trolleyItem.lineNumber,
      );
      if (optimisticTrolleyItemIndex < 0) {
        return {
          ...state,
          optimisticTrolleyItems: update(state.optimisticTrolleyItems, {
            $push: [action.trolleyItem],
          }),
        };
      }

      return {
        ...state,
        optimisticTrolleyItems: update(state.optimisticTrolleyItems, {
          [optimisticTrolleyItemIndex]: { $set: action.trolleyItem },
        }),
      };
    }
    case OPTIMISTICALLY_UPDATE_MULTIPLE_TROLLEY_ITEMS: {
      const { trolleyItems } = action;
      let { optimisticTrolleyItems } = state;
      trolleyItems.forEach(newItem => {
        const optimisticTrolleyItemIndex = optimisticTrolleyItems.findIndex(
          item => item.lineNumber === newItem.lineNumber,
        );
        if (optimisticTrolleyItemIndex < 0) {
          optimisticTrolleyItems = update(optimisticTrolleyItems, {
            $push: [newItem],
          });
          return;
        }
        optimisticTrolleyItems = update(optimisticTrolleyItems, {
          [optimisticTrolleyItemIndex]: { $set: newItem },
        });
      });

      return {
        ...state,
        optimisticTrolleyItems,
      };
    }
    case REMOVE_OPTIMISTIC_TROLLEY_ITEM: {
      const optimisticTrolleyItemIndex = state.optimisticTrolleyItems.findIndex(
        item => item.lineNumber === action.lineNumber,
      );
      if (optimisticTrolleyItemIndex < 0) {
        return state;
      }

      return {
        ...state,
        optimisticTrolleyItems: update(state.optimisticTrolleyItems, {
          $splice: [[optimisticTrolleyItemIndex, 1]],
        }),
      };
    }
    case REMOVE_MULTIPLE_OPTIMISTIC_TROLLEY_ITEMS: {
      const { trolleyItems } = action;

      return {
        ...state,
        optimisticTrolleyItems: state.optimisticTrolleyItems.filter(item => {
          const trolleyItemIndex = trolleyItems.findIndex(
            ({ lineNumber }) => lineNumber === item.lineNumber,
          );
          return trolleyItemIndex < 0;
        }),
      };
    }
    case REMOVE_ALL_OPTIMISTIC_TROLLEY_ITEMS: {
      return { ...state, optimisticTrolleyItems: [] };
    }
    case FILTER_TROLLEY_ANALYTICS: {
      return {
        ...state,
        analytics: {
          ...state.analytics,
          trolleyFiltered: true,
        },
      };
    }
    case UPDATE_INSTANT_CHECKOUT_LOCATION: {
      const { location } = action;
      return {
        ...state,
        instantCheckoutLocation: location,
      };
    }
    case REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY: {
      const { trolleyItems } = action;

      return {
        ...state,
        errorResponse: null,
        loading: true,
        loadingOrder: true,
        trolleyItems: state.trolleyItems.map(item => {
          const trolleyItemIndex = trolleyItems.findIndex(
            ({ trolleyItemId }) => trolleyItemId === item.trolleyItemId,
          );
          return trolleyItemIndex < 0
            ? item
            : update(item, { $merge: { quantity: { amount: 0, uom: item.quantity.uom } } });
        }),
      };
    }
    case REMOVE_MULTIPLE_ITEMS_FROM_TROLLEY_FAILED: {
      const { trolleyItems } = action;

      return {
        ...state,
        errorResponse: action.error,
        loading: false,
        loadingOrder: false,
        trolleyItems: state.trolleyItems.map(item => {
          const trolleyItem = trolleyItems.find(
            ({ trolleyItemId }) => trolleyItemId === item.trolleyItemId,
          );
          return !trolleyItem
            ? item
            : update(item, {
                $merge: { quantity: trolleyItem.quantity },
              });
        }),
      };
    }

    case SUB_ALL_UPDATED: {
      const { allowSub } = action;

      return {
        ...state,
        trolleyItems: state.trolleyItems.map(item => ({
          ...item,
          canSubstitute: allowSub,
        })),

        errorResponse: null,
        loaded: true,
        loading: false,
        loadingOrder: false,
      };
    }
    case GET_SUBSTITUTION_PRODUCTS_SUCCESS: {
      const { lineNumber } = action;
      return {
        ...state,
        substitutionProducts: {
          [lineNumber]: result?.substitutions ?? [],
        },
        loadingSubs: false,
      };
    }
    case UPDATE_TROLLEY_SORT: {
      const { trolleySortValue } = action;
      return {
        ...state,
        trolleySortValue,
      };
    }
    case SET_UNAVAILABLE_ITEM_FOR_REMOVAL: {
      return {
        ...state,
        trolleyItemsSetForRemoval:
          state.trolleyItemsSetForRemoval.indexOf(action.trolleyItemId) < 0
            ? update(state.trolleyItemsSetForRemoval, {
                $push: [action.trolleyItemId],
              })
            : state.trolleyItemsSetForRemoval,
      };
    }
    case CLEAR_ITEMS_SET_FOR_REMOVAL: {
      return {
        ...state,
        trolleyItemsSetForRemoval: [],
      };
    }
    case GET_TROLLEY_RECOMMENDATION_SUCCESS:
      return {
        ...state,
        recommendations: action.result.products,
      };
    default:
      return state;
  }
}
