import { connect } from 'react-redux';

import { navigateToMultiSearch } from 'redux/modules/multi-search/actions/navigate-to-multi-search';
import {
  getMultiSearchCurrentValue,
  getMultiSearchValues,
} from 'redux/modules/multi-search/selectors';

import MultiSearchStrip from 'components/MultiSearch/Strip/MultiSearchStrip';

export default connect(
  state => ({
    values: getMultiSearchValues(state),
    current: getMultiSearchCurrentValue(state),
  }),
  {
    navigateToMultiSearch,
  },
)(MultiSearchStrip);
