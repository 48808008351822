import React from 'react';

import { buildElementStyling } from '../utils';
import { RichTextNode } from '../types';

const RichTextH1 = ({ children, value }: RichTextNode) => {
  const { className, style } = buildElementStyling({ baseClass: 'richTextH1', value });
  return (
    <h1 className={className} style={style}>
      {children}
    </h1>
  );
};

RichTextH1.displayName = 'RichTextH1';

export default RichTextH1;
