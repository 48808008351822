import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import isEqual from 'lodash/isEqual';
import { bool, func } from 'prop-types';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';

import { getAutoSuggestActive } from 'redux/modules/auto-suggest/selectors';
import getEligibleForAutoSuggest from 'redux/modules/auto-suggest/selectors/eligible-for-autosuggest';
import { getFunctionalCookieState } from 'redux/modules/cookies/selectors';
import { getCustomerSearchTerm } from 'redux/modules/search-and-browse/selectors/search';

import autoSuggestClear from 'redux/modules/auto-suggest/actions/auto-suggest-clear';
import autoSuggestSearch from 'redux/modules/auto-suggest/actions/auto-suggest-search';
import { updateSearchTerm } from 'redux/modules/search/actions/update-search-term';
import { setMobileOverlay } from 'redux/modules/page/actions';

import FocusTrap from 'components/FocusTrap';
import AutoComplete from 'components/Search/AutoComplete';
import { useSearchForm } from 'components/Search/SearchForm/use-search-form';
import SearchHistory from 'components/Search/SearchHistory';
import { SearchBox } from '@johnlewispartnership/wtr-ingredients/ingredients/SearchBox';
import MultiSearchModal from 'components/MultiSearch/Modal';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';

import styles from './MobileOverlay.scss';

const MobileOverlay = ({ clearValue, setClearValue }) => {
  const dispatch = useDispatch();

  const autoSuggestActive = useSelector(getAutoSuggestActive, isEqual);
  const eligibleForAutoSuggest = useSelector(getEligibleForAutoSuggest, isEqual);
  const functionalCookieConsent = useSelector(getFunctionalCookieState, isEqual);
  const initialValue = useSelector(getCustomerSearchTerm, isEqual);

  const {
    handleChange,
    handleClear,
    handleClick,
    handleFocus,
    handleKeyDown,
    handleSelectedAutoCompleteItem,
    handleSelectedSearchHistoryItem,
    handleSubmit,
    hasFocus,
    insideRef: formRef,
    inputRef,
    setValue,
    showSearchHistory,
    value,
  } = useSearchForm({
    autoSuggestActive,
    eligibleForAutoSuggest,
    functionalCookieConsent,
    initialValue,
    onChange: autoSuggestSearch,
    onClear: autoSuggestClear,
    onSubmit: updateSearchTerm,
    onSearch: setMobileOverlay,
  });

  const inputProps = {
    onFocus: handleFocus,
    ref: inputRef,
    id: 'mobileSearchInput',
    onClick: handleClick,
  };

  const insideRef = useRef();
  const [focusTrap, setFocusTrap] = useState(true);

  useEffect(() => {
    const newValue = clearValue ? setValue('') : setValue(initialValue);
    return newValue;
  }, [clearValue, initialValue, setValue]);

  const lockBodyScroll = useCallback(() => {
    disableBodyScroll(insideRef.current);
  }, [insideRef]);

  const handleFocusTrap = isOpen => {
    if (isOpen) {
      setFocusTrap(false);
    } else {
      dispatch(setMobileOverlay(false));
    }
  };

  useEffect(() => {
    if (focusTrap) {
      lockBodyScroll();
    } else {
      clearAllBodyScrollLocks();
    }
  }, [focusTrap, lockBodyScroll]);

  useEffect(() => {
    lockBodyScroll();
    return () => {
      clearAllBodyScrollLocks();
    };
  }, [handleClear, insideRef, lockBodyScroll]);

  const closeModal = () => {
    setClearValue(false);
    dispatch(setMobileOverlay(false));
    dispatch(autoSuggestClear());
  };

  return (
    <FocusTrap active={focusTrap}>
      <div
        ref={insideRef}
        role="dialog"
        aria-label="Search form"
        className={styles.dialog}
        data-test="mobile-overlay"
      >
        <div className={styles.header}>
          <div className={styles.search}>
            {focusTrap && (
              <SearchBox
                ariaActivedescendant={
                  showSearchHistory ? 'search-history-item' : 'autocomplete-suggestion'
                }
                ariaAutocomplete="list"
                ariaOwns={showSearchHistory ? 'search-history' : 'autocomplete-listbox'}
                focus={hasFocus}
                formRef={formRef}
                id="mobileSearch"
                initialValue={initialValue}
                inputProps={inputProps}
                inputRef={inputRef}
                onChange={handleChange}
                onClearClick={handleClear}
                onFocus={handleFocus}
                onKeyDown={handleKeyDown}
                onSubmit={handleSubmit}
                placeholder="Search groceries..."
                value={value}
              />
            )}
          </div>
          <div className={styles.cancel}>
            <TextLink
              underline="always"
              data-testid="close-overlay"
              onClick={closeModal}
              component="button"
            >
              Cancel
            </TextLink>
          </div>
        </div>
        <div className={styles.multisearch}>
          <MultiSearchModal
            hidden={false}
            mobile
            onSearch={() => dispatch(setMobileOverlay(false))}
            onToogleModal={handleFocusTrap}
          />
        </div>
        {!value.length && (
          <SearchHistory inputRef={inputRef} onSelectTerm={handleSelectedSearchHistoryItem} />
        )}
        <AutoComplete
          inline
          inputRef={inputRef}
          onClear={() => dispatch(autoSuggestClear())}
          onSelectItem={handleSelectedAutoCompleteItem}
        />
      </div>
    </FocusTrap>
  );
};

MobileOverlay.propTypes = {
  clearValue: bool,
  setClearValue: func,
};

MobileOverlay.defaultProps = {
  clearValue: false,
  setClearValue: () => {},
};

export default MobileOverlay;
