import React from 'react';

import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { rootPath } from 'utils/root-path';

import styles from './DropDownNavHidden.scss';

export const DropDownNavHidden = ({ taxonomy }) => (
  <div className={styles.dropDownG1G2Hidden}>
    {taxonomy.map(item => (
      <Link key={item.id} id={item.id} to={rootPath(item.path.toLowerCase())}>
        {item.name}
      </Link>
    ))}
  </div>
);

DropDownNavHidden.propTypes = {
  taxonomy: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
      id: PropTypes.string,
      level: PropTypes.number,
      parent: PropTypes.arrayOf(PropTypes.string),
    }),
  ),
};

DropDownNavHidden.defaultProps = {
  taxonomy: [],
};
