import memoize from 'lodash/memoize';
import { getFulfilmentTypeByURL } from 'components/BookSlot/utils/get-fulfilment-type-by-url';
import { fulfilmentTypes } from 'constants/fulfilmentTypes';
import { getSlotType, hasBookedSlot } from 'redux/modules/customer-slot/selectors';
import history from 'utils/history';

export const getPathName = () => history.getLocation().pathname;

export const getFulfilmentType = () =>
  memoize(pathname => getFulfilmentTypeByURL(pathname))(getPathName());

export const getFulfilmentTypeForBookedSlot = (state: WtrState) => {
  let result;
  const slotType = getSlotType(state);

  if (slotType === fulfilmentTypes.GROCERIES_AND_ENTERTAINING_COLLECTION.slotType) {
    result = fulfilmentTypes.GROCERIES_AND_ENTERTAINING_COLLECTION;
  }

  if (slotType === fulfilmentTypes.ENTERTAINING_COLLECTION.slotType) {
    result = fulfilmentTypes.ENTERTAINING_COLLECTION;
  }

  if (slotType === fulfilmentTypes.DELIVERY.slotType) {
    result = fulfilmentTypes.DELIVERY;
  }

  return result;
};

export const isBookedSlotSameFulfilmentTypeAsCurrentPage = (state: WtrState) => {
  if (!hasBookedSlot(state)) {
    return false;
  }
  const urlType = getFulfilmentType();
  const slotType = getFulfilmentTypeForBookedSlot(state);

  const bothAreDelivery =
    urlType?.slotType === fulfilmentTypes.DELIVERY?.slotType &&
    slotType?.slotType === fulfilmentTypes.DELIVERY?.slotType;

  const bothAreGroceriesCollection =
    (urlType?.slotType === fulfilmentTypes.GROCERIES_COLLECTION?.slotType ||
      urlType?.slotType === fulfilmentTypes.GROCERIES_AND_ENTERTAINING_COLLECTION?.slotType) &&
    (slotType?.slotType === fulfilmentTypes.GROCERIES_COLLECTION?.slotType ||
      slotType?.slotType === fulfilmentTypes.GROCERIES_AND_ENTERTAINING_COLLECTION?.slotType);

  const bothAreEntertainingCollection =
    urlType?.slotType === fulfilmentTypes.ENTERTAINING_COLLECTION?.slotType &&
    slotType?.slotType === fulfilmentTypes.ENTERTAINING_COLLECTION?.slotType;

  return (
    !!urlType &&
    !!slotType &&
    (bothAreDelivery || bothAreGroceriesCollection || bothAreEntertainingCollection)
  );
};

export const isCurrentPageCollection = () => (getFulfilmentType() || {}).category === 'COLLECTION';
