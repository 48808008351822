import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import { hasRoundel } from 'utils/promotionUtilities';

import BrandIcon from './BrandIcon';
import MessageIcon from './MessageIcon';
import MyWaitroseIcon from './MyWaitroseIcon';
import PromoRoundel from './PromoRoundel';

import styles from './PromoIconography.scss';

const isSupercharged = icons => get(icons, 'myWaitroseIcon.data.supercharged', false);

const PromoIconography = ({ hideMessage, icons }) => {
  const brandIconProps = {
    ...get(icons, 'brandIcon', {}),
    adjust: hideMessage,
  };
  const messageIconProps = {
    display: get(icons, 'messageIcon.display'),
    message: get(icons, 'messageIcon.data.message'),
    theme: get(icons, 'messageIcon.data.theme'),
  };
  const myWaitroseProps = {
    additionalReduction: get(icons, 'myWaitroseIcon.data.additionalReduction'),
    display: get(icons, 'myWaitroseIcon.display'),
    roundel: hasRoundel(icons),
    supercharged: isSupercharged(icons),
  };
  const roundelProps = {
    display: get(icons, 'offerRoundel.display'),
    image: get(icons, 'offerRoundel.image'),
    supercharged: isSupercharged(icons),
  };

  return (
    <div className={styles.wrapper}>
      {!hideMessage && <MessageIcon {...messageIconProps} />}
      <BrandIcon {...brandIconProps} />
      <MyWaitroseIcon {...myWaitroseProps} />
      <PromoRoundel {...roundelProps} />
    </div>
  );
};

PromoIconography.propTypes = {
  hideMessage: PropTypes.bool,
  icons: PropTypes.object,
};

export default PromoIconography;
