import { FORMAT, TIMEZONE, dayjs } from 'utils/date';

export function formatSlotTime(time: string) {
  // In case time is an empty string (or null/undefined when called from js code)
  if (!time) return undefined;

  const isIsoTime = time.includes('T');
  const timeObj = isIsoTime ? dayjs(time).tz() : dayjs.tz(time, FORMAT.ISO8601_TIME, TIMEZONE);
  const formattedTime = timeObj.format(FORMAT.HOUR_AMPM_UPPER);

  if (!isIsoTime && timeObj.hour() === 23 && timeObj.minute() === 30) return '12AM';

  return formattedTime;
}

/**
 * Formats the start/end slot time to be displayed.
 *
 * **If any of the values is null or undefined, an empty string will be returned**
 *
 * @example
 * `7-8pm`
 * `11-12pm`
 * `9-10am`
 */
export function formatSlotTimeRange(startTime: dayjs.ConfigType, endTime: dayjs.ConfigType) {
  if (!startTime || !endTime) {
    return '';
  }

  const start = dayjs(startTime).tz().format(FORMAT.HOUR);
  const end = dayjs(endTime).tz().format(FORMAT.HOUR_AMPM);

  return `${start}-${end}`;
}
