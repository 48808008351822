import React from 'react';
import PropTypes from 'prop-types';

import { LinkType } from 'constants/types/TradingCell';
import AnchorLink from 'components/AnchorLink';

import style from './PromoLink.scss';

const PromoLink = ({ children, link, promoClick }) => {
  const dataTest = 'promo-link';

  if (!link?.url) {
    return (
      <div data-testid={dataTest} className={style.image}>
        {children}
      </div>
    );
  }

  return (
    <AnchorLink
      data-testid={dataTest}
      className={style.image}
      to={link?.url}
      inNewTab={link?.newWindow}
      onClick={promoClick}
    >
      {children}
    </AnchorLink>
  );
};
export default PromoLink;

PromoLink.displayName = 'PromoLink';

PromoLink.propTypes = {
  link: LinkType,
  children: PropTypes.node,
  promoClick: PropTypes.func,
};

PromoLink.defaultProps = {
  link: {},
  children: undefined,
  promoClick: undefined,
};
