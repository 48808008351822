import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Picture from 'components/Image/Picture';
import ImageLink from 'components/Image/ImageLink';
import ResponsiveGrid from 'components/ResponsiveGrid';

import { getImageAltTextAndUrl } from 'analytics/aem/get-image-alt-text-and-url';
import { IMAGE_NAME } from 'components/AemComponent/analyticsConstants';
import { BREAKPOINT_MAP } from './constants';
import styles from './index.scss';

const getSizes = (cqResponsive, fullWidth) => {
  /*
   * The browser picks the best image to download based on the width of the image. However, this happens BEFORE
   * css is applied, therefore we need to tell the browser how wide the image will be. If we don't do so, the
   * browser will assume that the image is full width.
   *
   * For breakpoints that are 1200px or above, the images are constrained by the 1200px center column
   * layout (unless the image is full width). So for xl and above we should check for full width
   * and then multiply by the center column layout width.
   *
   * This is wrapped in a try, as if the analysis fails then we should fall back to assuming the image is 100% of the viewport.
   * At the moment, I haven't come up with a way to cause this block to fail and so is missing in unit test coverage.
   * */

  try {
    const mappedCqResponsive = cqResponsive?.deviceBreakpoints.reduce(
      (accumulator, { name, width, relativeWidth }) => {
        const breakpointWidth = relativeWidth || width;

        if (name === 'default') {
          return {
            ...accumulator,
            default: fullWidth ? '100vw' : `${105 * breakpointWidth}px`,
          };
        }

        return {
          ...accumulator,
          [name]: `${Math.floor((100 * breakpointWidth) / 12)}vw`,
        };
      },
      undefined,
    );

    return Object.entries(BREAKPOINT_MAP)
      .map(([breakpoint, [, max]]) => {
        if (breakpoint === 'default') return ` ${mappedCqResponsive[breakpoint] || '100vw'}`;
        return `(max-width: ${max}px)  ${mappedCqResponsive[breakpoint] || '100vw'}`;
      })
      .join(', ');
  } catch (err) {
    return '100vw';
  }
};

const hasOverlayContent = childComponents => {
  const [responsiveGrid] = childComponents;

  return !!responsiveGrid?.childComponents?.length;
};

const OVERLAY_POSITION = {
  top: 'flex-start',
  center: 'center',
  bottom: 'flex-end',
};

// The SPA passes the childComponents and placeholder as children (this is different to the data.childComponents
// that is used in the client), passing like this allows to overlay the components in the author.
const Image = ({ cqResponsiveClasses, children, position, preloadImages, ...data }) => {
  const {
    altText,
    analytics,
    analyticsTags,
    assetMetadataHeight,
    assetMetadataWidth,
    childComponents,
    childComponentsVerticalAlignment,
    childComponentsVerticalAlignmentMobile,
    childComponentsVerticalAlignmentTablet,
    componentId,
    cqResponsive,
    dynamicImages,
    elementId,
    fileReference,
    fullWidth,
    height,
    linkURL,
    mobileImage,
    newWindow,
    overlayColor,
    tabletImage,
  } = data;

  const className = classNames(styles.wrapper, cqResponsiveClasses, 'waitrose-image', {
    [styles.fullWidth]: fullWidth,
  });

  const sizes = getSizes(cqResponsive, fullWidth);

  const imageLinkProps = {
    analytics,
    componentId,
    linkURL,
    position,
    newWindow,
    getImageAltTextAndUrl: () => getImageAltTextAndUrl(data),
  };

  /*
    Fixed height images.

    There are two scenarios for using the images with a fixed height. The first scenario is when only the mandatory image exists and
    has a fixed height and the second scenario where multiple images are populated where some or all have a fixed height.

    We use CSS variables to pass through the heights. For each image, the CSS variable will be set with it's fixed height value or auto
    in the event no height is set. We return null to the variable in the event the image doesn't exist.

    Usage in the CSS class follows this pattern first attempts to set the height from it's variable, next will try to set it's height from
    the default variable (for the scenario where there is a single image with a fixed height) and finally fallback to auto.
  */

  return (
    <section
      data-testid="image"
      className={className}
      id={elementId || componentId}
      data-uniqueid={componentId}
      key={componentId}
      {...analyticsTags}
    >
      <div
        data-test="color-wrapper"
        className={classNames(styles.colorWrapper, styles.fixedHeightWrapper)}
      >
        <ImageLink {...imageLinkProps}>
          <div
            data-testid="fixed-height-images"
            style={{
              '--default-fixed-height': height && `${height}px`,
              '--tablet-fixed-height': tabletImage.fileReference
                ? (tabletImage.height && `${tabletImage.height}px`) || 'auto'
                : null,
              '--mobile-fixed-height': mobileImage.fileReference
                ? (mobileImage.height && `${mobileImage.height}px`) || 'auto'
                : null,
            }}
            className={styles.fixedHeightImages}
          >
            <Picture
              altText={altText}
              fileReference={fileReference}
              hasFixedHeight={!!height}
              height={height || assetMetadataHeight}
              scene7Url={dynamicImages?.highQuality95}
              scene7UrlMobile={mobileImage?.dynamicImages?.highQuality95}
              mobileHeight={mobileImage?.height || mobileImage?.assetMetadataHeight}
              mobileWidth={mobileImage?.assetMetadataWidth}
              scene7UrlTablet={tabletImage?.dynamicImages?.highQuality95}
              tabletHeight={tabletImage?.height || tabletImage?.assetMetadataHeight}
              tabletWidth={tabletImage?.assetMetadataWidth}
              sizes={sizes}
              width={assetMetadataWidth}
              preload={preloadImages}
            />
          </div>
          {(hasOverlayContent(childComponents) || children) && (
            <div
              data-testid="component-over-image-wrapper"
              className={classNames(styles.commonOverlayStyles, styles.overlay, {
                [styles.imageOverlayRelative]: false,
                [styles.imageOverlayAbsolute]: true,
              })}
              style={{
                '--default-vertical-alignment': OVERLAY_POSITION[childComponentsVerticalAlignment],
                '--tablet-vertical-alignment':
                  OVERLAY_POSITION[childComponentsVerticalAlignmentTablet],
                '--mobile-vertical-alignment':
                  OVERLAY_POSITION[childComponentsVerticalAlignmentMobile],
              }}
            >
              {children}
              <ResponsiveGrid childComponents={childComponents} cqResponsive={cqResponsive} />
            </div>
          )}

          {overlayColor && (
            <div
              data-testid="color-layer"
              className={styles.colorLayer}
              style={{ backgroundColor: overlayColor }}
            />
          )}
        </ImageLink>
      </div>
    </section>
  );
};

Image.displayName = IMAGE_NAME;

Image.defaultProps = {
  children: null,
  cqResponsiveClasses: [],
  isInEditor: false,
  overlayColor: '',
  assetMetadataHeight: undefined,
  assetMetadataWidth: undefined,
  elementId: undefined,
  locationName: '',
  altText: null,
  analyticsTags: {},
  analytics: {},
  childComponents: [],
  childComponentsVerticalAlignment: 'top',
  childComponentsVerticalAlignmentTablet: null,
  childComponentsVerticalAlignmentMobile: null,
  componentId: '',
  cqResponsive: {},
  dynamicImages: {},
  mobileImage: {},
  tabletImage: {},
  newWindow: false,
  fileReference: '',
  fullWidth: false,
  height: '',
  linkURL: '',
  preloadImages: false,
};

Image.propTypes = {
  children: PropTypes.node,
  cqResponsiveClasses: PropTypes.arrayOf(PropTypes.string),
  isInEditor: PropTypes.bool,
  altText: PropTypes.string,
  analyticsTags: PropTypes.shape({}),
  analytics: PropTypes.shape({
    analyticsId: PropTypes.string,
  }),
  assetMetadataHeight: PropTypes.string,
  assetMetadataWidth: PropTypes.string,
  childComponents: PropTypes.arrayOf(PropTypes.shape({})),
  childComponentsVerticalAlignment: PropTypes.string,
  childComponentsVerticalAlignmentTablet: PropTypes.string,
  childComponentsVerticalAlignmentMobile: PropTypes.string,
  componentId: PropTypes.string,
  cqResponsive: PropTypes.shape({
    deviceBreakpoints: PropTypes.arrayOf(
      PropTypes.shape({
        layout: PropTypes.string,
        name: PropTypes.string.isRequired,
        visible: PropTypes.bool.isRequired,
        width: PropTypes.string,
        offset: PropTypes.string,
        relativeWidth: PropTypes.string,
      }),
    ),
  }),
  dynamicImages: PropTypes.shape({
    highQuality95: PropTypes.string,
  }),
  elementId: PropTypes.string,
  mobileImage: PropTypes.shape({
    altText: PropTypes.string,
    assetMetadataHeight: PropTypes.string,
    assetMetadataWidth: PropTypes.string,
    dynamicImages: PropTypes.shape({
      highQuality95: PropTypes.string,
    }),
    fileReference: PropTypes.string,
    height: PropTypes.string,
  }),
  tabletImage: PropTypes.shape({
    altText: PropTypes.string,
    assetMetadataHeight: PropTypes.string,
    assetMetadataWidth: PropTypes.string,
    dynamicImages: PropTypes.shape({
      highQuality95: PropTypes.string,
    }),
    fileReference: PropTypes.string,
    height: PropTypes.string,
  }),
  newWindow: PropTypes.bool,
  fileReference: PropTypes.string,
  fullWidth: PropTypes.bool,
  height: PropTypes.string,
  linkURL: PropTypes.string,
  overlayColor: PropTypes.string,
  locationName: PropTypes.string,
  position: PropTypes.string.isRequired,
  preloadImages: PropTypes.bool,
};

export default Image;
