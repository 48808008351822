import env from 'env/env';

export const buildUrl = (src, external) => `${external ? '' : env.cfDomain}${src}`;

export const backgroundImage = (image, external = false) =>
  image && image.src
    ? {
        backgroundImage: `url(${buildUrl(image.src, external)})`,
      }
    : null;
