import { CqResponsive } from 'cms-components/types';

const MAX_COLUMNS = 12;

type CalculateColumnsRelativeToParent = (
  parentCqResponsive: CqResponsive | undefined,
  childCqResponsive: CqResponsive,
) => CqResponsive;

/**
 * This function calculates how many columns the child cqResponsive spans
 * within it's parent based on the parent's column width using the standard
 * 12 column grid system.
 *
 * This is needed because nested grids have 12 columns too so for example if the
 * parent component spans 6 columns and contains a component such as a Image that spans
 * full width of it; this would by default have a span of 12 columns. This
 * function will calculate that the Image should span 6 columns instead. This
 * allows Image to then correctly calculate the image size required based on
 * it's context to the parent.
 *
 * NOTE: This is only relevant for when the parent spans less than 12 columns.
 */
export const calculateColumnsRelativeToParent: CalculateColumnsRelativeToParent = (
  parentCqResponsive = undefined,
  childCqResponsive,
) => ({
  deviceBreakpoints: childCqResponsive?.deviceBreakpoints?.map(breakpoint => {
    const breakpointWidth = Number(breakpoint.width);
    const parentCqValue = parentCqResponsive?.deviceBreakpoints?.find(
      parentBreakpoint => parentBreakpoint.name === breakpoint.name,
    );
    const parentColumnWidth = Number(
      parentCqValue?.relativeWidth || parentCqValue?.width || MAX_COLUMNS,
    );

    if (parentColumnWidth < MAX_COLUMNS && breakpointWidth < MAX_COLUMNS) {
      return {
        ...breakpoint,
        relativeWidth: Math.ceil((parentColumnWidth / MAX_COLUMNS) * breakpointWidth).toString(),
      };
    }

    if (parentColumnWidth < MAX_COLUMNS) {
      return {
        ...breakpoint,
        relativeWidth: parentColumnWidth.toString(),
      };
    }

    return breakpoint;
  }),
});
