import urls from 'constants/urls';
import chunk from 'lodash/chunk';
import { FORMAT, dayjs } from 'utils/date';
import { getProducts } from 'redux/modules/entities/selectors/products';

import { getFeatureFlags } from 'utils/feature-flags';
import { getGridItems } from 'redux/modules/search-and-browse/selectors';
import { createSelector } from 'reselect';
import { getCustomerSlotDate } from 'redux/modules/customer-slot/selectors';
import { justDeal } from 'components/MealDealPage/Recommendations/utils/getMealDealBuilderMessages';
import { getBlendedOptimisticTrolleyItems } from '../../trolley/selectors/get-optimistic-items';

import { mealDeals } from './meal-deal-data';
import {
  ENDING_SOON_CAUSE,
  ENDING_SOON_TITLE_TACTICAL,
  INVALID_SLOT_CAUSE,
  INVALID_SLOT_TITLE_TACTICAL,
} from './constants';

const getMealDealFromStore = (state, mealDealId) => state.mealDeals[mealDealId];

export const getMealDealRecommendedProducts = createSelector(
  getMealDealFromStore,
  ({ recommendedProducts } = {}) => recommendedProducts,
);

const mealDealLines = mealDeals.reduce((acc, md) => {
  md.groups.forEach(({ groupId }) => {
    acc[groupId] = md;
  });
  return acc;
}, {});

const getSortedProducts = createSelector(getProducts, products =>
  Object.values(products).sort(
    (itemA, itemB) =>
      itemA.currentSaleUnitPrice?.price.amount - itemB.currentSaleUnitPrice?.price.amount,
  ),
);

export const getMealDeal = (_state, mdId) => mealDeals.find(({ id }) => mdId === id);

export const getMealDealTitle = (_state, mdId) =>
  mealDeals.find(({ id }) => Number(mdId) === id)?.name;

export const getMealDealTexts = mdId => ({
  mainText: mealDeals.find(({ id }) => mdId === id)?.mainText,
  subText: mealDeals.find(({ id }) => mdId === id)?.subText,
});

export const getMealDealGroups = id => getMealDeal(null, id)?.groups ?? [];

export const getMealDealGroupByGroupId = (_state, id) => {
  const intId = Number(id);
  return mealDealLines[intId]?.groups?.find(({ groupId }) => groupId === intId);
};

const getInvalidSlotMessage = ({ mealDealStartDate, mealDealEndDate }) =>
  `This offer applies to delivery/collections slots ${
    mealDealStartDate ? `from ${mealDealStartDate.format(FORMAT.LONG_DAY_MONTH_YEAR)}` : ''
  } ${
    mealDealEndDate
      ? `${mealDealStartDate ? 'to' : 'until'} ${mealDealEndDate.format(
          FORMAT.LONG_DAY_MONTH_YEAR,
        )}`
      : ''
  }`;

const getEndingSoonMessage = ({ mealDealEndDate }) =>
  `${
    mealDealEndDate
      ? `This offer applies for delivery/collection until ${mealDealEndDate.format(
          FORMAT.LONG_1DAY_MONTH,
        )}. Don't miss it`
      : ''
  }`;

const getMealDealEndDate = ({ endDate }) => {
  const {
    browse_mealDealEndDate1Day: mealDealEndDate1Day,
    browse_mealDealEndDate2Day: mealDealEndDate2Day,
  } = getFeatureFlags();

  if (mealDealEndDate1Day) {
    return dayjs.tz().add(1, 'day');
  }

  if (mealDealEndDate2Day) {
    return dayjs.tz().add(2, 'day');
  }

  if (endDate) {
    return dayjs(endDate).tz();
  }

  return null;
};

export const getMealDealDisabledState = createSelector(
  getCustomerSlotDate,
  (_, today) => today,
  (state, _, mealDealId) => getMealDeal(state, Number(mealDealId)),
  (slot, todaysDateMS, mealDeal = {}) => {
    const todaysDate = dayjs(todaysDateMS);
    const slotDate = slot ? dayjs(slot).tz() : null;
    const isSlotBooked = !!slotDate;

    const { endDate, startDate } = mealDeal;
    const mealDealEndDate = getMealDealEndDate({ endDate });
    const mealDealStartDate = startDate ? dayjs(startDate).tz() : null;

    const isSlotAfterStartDate =
      !startDate || !isSlotBooked || slotDate.isSameOrAfter(mealDealStartDate, 'day');
    const isSlotBeforeEndDate =
      !endDate || !isSlotBooked || slotDate.isSameOrBefore(mealDealEndDate, 'day');
    const isSlotValidForMealDeal = isSlotAfterStartDate && isSlotBeforeEndDate;

    const endsIn2DaysOrLess = mealDealEndDate
      ? todaysDate.isBefore(mealDealEndDate, 'day') &&
        todaysDate.isSameOrAfter(mealDealEndDate.subtract(2, 'day'), 'day')
      : false;

    const invalidSlotBooked = isSlotBooked && !isSlotValidForMealDeal;
    const noSlotAndEndingSoon = !isSlotBooked && endsIn2DaysOrLess;
    const noSlotOrInvalidSlotBooked = !isSlotBooked || invalidSlotBooked;

    const showAlert = invalidSlotBooked || noSlotAndEndingSoon;

    const isNowSameOrAfterEndDate = mealDealEndDate
      ? todaysDate.isSameOrAfter(mealDealEndDate, 'day')
      : false;

    const isNowBeforeStartDate = mealDealStartDate
      ? todaysDate.isBefore(mealDealStartDate, 'day')
      : false;

    const hideBuilder =
      isNowSameOrAfterEndDate || (isNowBeforeStartDate && noSlotOrInvalidSlotBooked);

    if (!showAlert) {
      return {
        showAlert,
        hideBuilder,
        showGrid: true,
        isNowBeforeStartDate: false,
        isNowAfterEndDate: false,
        isSlotBooked,
      };
    }

    const justDealWording = justDeal(mealDeal.id.toString());

    return {
      showAlert,
      cause: invalidSlotBooked ? INVALID_SLOT_CAUSE : ENDING_SOON_CAUSE,
      title: invalidSlotBooked
        ? INVALID_SLOT_TITLE_TACTICAL(justDealWording)
        : ENDING_SOON_TITLE_TACTICAL(justDealWording),
      message: invalidSlotBooked
        ? getInvalidSlotMessage({ mealDealStartDate, mealDealEndDate })
        : getEndingSoonMessage({ mealDealEndDate }),
      hideBuilder,
      showGrid: true,
      isNowBeforeStartDate: false,
      isNowAfterEndDate: false,
      comingSoonMessage: '',
    };
  },
);

export const getMealDealPath = promotionId => {
  const mdId = mealDealLines[promotionId]?.id;
  return mdId ? urls.mealDealPromoPath.concat(`/${mdId}`) : undefined;
};

export const getMealDealProductsGrouped = createSelector(
  getProducts,
  getGridItems,
  (_state, mealDealId) => getMealDealGroups(Number(mealDealId)),
  (products = {}, gridItems = [], groups) => {
    const groupIds = groups.map(({ groupId }) => groupId);
    const linesMap = new Map(groupIds.map(id => [id.toString(), []]));

    Object.values(products).forEach(prod => {
      prod.promotions.forEach(promoId => {
        if (groupIds.includes(promoId)) {
          const searchProd = gridItems.find(
            ({ searchProduct }) => searchProduct === prod.lineNumber,
          );
          if (searchProd) linesMap.get(promoId.toString()).push(searchProd);
        }
      });
    });
    return linesMap;
  },
);

export const getMealDealTrolleyItemsGrouped = createSelector(
  getSortedProducts,
  getBlendedOptimisticTrolleyItems,
  (_state, mealDealId) => getMealDealGroups(Number(mealDealId)),
  (products = {}, blendedTrolleyItems = [], groups) => {
    const groupIds = groups.map(({ groupId }) => groupId);
    const linesMap = new Map(groupIds.map(id => [id, []]));

    Object.values(products).forEach(prod => {
      prod.promotions?.forEach(promoId => {
        if (groupIds.includes(promoId)) {
          const trolleyItem = blendedTrolleyItems.find(item => item.lineNumber === prod.lineNumber);
          for (let i = 0; i < trolleyItem?.quantity.amount; i += 1) {
            linesMap.get(promoId).push(trolleyItem);
          }
        }
      });
    });
    return linesMap;
  },
);

const isMealDealGroupComplete = builderMealDealGroup =>
  !builderMealDealGroup.items.some(item => item === null);

const isMealDealComplete = builderMealDeal =>
  !builderMealDeal.groups.map(isMealDealGroupComplete).some(groupCompleted => !groupCompleted);

const nullsArr = (count = 1) => new Array(count).fill(null);

const chunkProducts = ([id, products]) => {
  const group = getMealDealGroupByGroupId({}, id) || { threshold: 1 };
  const slots = [...products, ...nullsArr(group.threshold - (products.length % group.threshold))];

  return [group, chunk(slots, group.threshold)];
};

export const getMealDealBuilderItems = createSelector(
  getMealDealTrolleyItemsGrouped,
  (state, mealDealId) => getMealDeal(state, Number(mealDealId)),
  (groupsMap, mealDeal) => {
    if (!mealDeal || !groupsMap) return [];
    const builderMealDeals = [];
    const productsChunked = [...groupsMap.entries()].map(chunkProducts);

    let mealDealComplete = true;

    for (let i = 0; mealDealComplete && i < 100; i += 1) {
      const builderMealDeal = {
        ...mealDeal,
        groups: productsChunked.map(([{ groupId, ...group }, chunks]) => ({
          ...group,
          groupId: `${groupId}`,
          items: chunks[i],
        })),
        builderId: i,
      };
      mealDealComplete = isMealDealComplete(builderMealDeal);
      builderMealDeal.completed = mealDealComplete;
      builderMealDeals.push(builderMealDeal);
    }

    return builderMealDeals;
  },
);

export const getProductMealDeal = (promotions = []) => {
  let mealDealData;
  promotions?.forEach(promotion => {
    mealDeals.forEach(mealDeal => {
      if (mealDeal.groups.find(group => group.groupId === promotion)) {
        mealDealData = {
          mealDealId: mealDeal.id,
          promotion,
        };
      }
    });
  });
  return mealDealData;
};

export const getNumberCompletedDeals = createSelector(getMealDealBuilderItems, builderMealDeals => {
  const completedLength = builderMealDeals.filter(
    mealDealBuilder => mealDealBuilder.completed === true,
  ).length;

  return completedLength;
});

export const getMealDealPromoGroup = ({ mealDealId, promotion }) =>
  getMealDealGroups(Number(mealDealId)).find(group => group.groupId === promotion);

export const getIncompleteGroups = createSelector(getMealDealBuilderItems, builderMealDeals =>
  builderMealDeals
    .at(-1)
    .groups.map(group => {
      const complete = isMealDealGroupComplete(group);

      return `${group.name} ${complete ? 'Complete' : 'Incomplete'}  (${group.groupId} ); `;
    })
    .join(''),
);

export const getMealDealBuilderLineNumbers = createSelector(
  getMealDealBuilderItems,
  mealDealBuilders => [
    ...new Set(
      []
        .concat(...mealDealBuilders.map(mealDeal => mealDeal.groups))
        .flatMap(group => group.items.map(item => item?.lineNumber))
        .filter(lineNumber => lineNumber),
    ),
  ],
);
