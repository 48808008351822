import { createSelector } from 'reselect';
import { getTrolleyItems } from './get-items';

export const getTrolley = state => state?.trolley;

const EMPTY_ARRAY = [];

export const getOptimisticTrolleyItems = createSelector(
  getTrolley,
  ({ optimisticTrolleyItems } = { optimisticTrolleyItems: EMPTY_ARRAY }) => optimisticTrolleyItems,
);

export const getOrderedOptimisticTrolleyItems = createSelector(getOptimisticTrolleyItems, items =>
  items.slice().reverse(),
);

const blendTrolleyItems = (trolleyItems, optimisticTrolleyItems) =>
  optimisticTrolleyItems.concat(
    trolleyItems.filter(
      trolleyItem => !optimisticTrolleyItems.find(oti => oti.lineNumber === trolleyItem.lineNumber),
    ),
  );

export const getBlendedOptimisticTrolleyItems = createSelector(
  [getTrolleyItems, getOptimisticTrolleyItems],
  blendTrolleyItems,
);

export const getOrderedBlendedOptimisticTrolleyItems = createSelector(
  [getBlendedOptimisticTrolleyItems],
  items =>
    items
      .filter(item => item.quantity.amount > 0)
      .slice()
      .sort((itemA, itemB) => itemB.trolleyItemId - itemA.trolleyItemId),
);

export const getAddedOrderedBlendedOptimisticTrolleyItems = createSelector(
  [getBlendedOptimisticTrolleyItems],
  items =>
    items
      .filter(item => item.quantity.amount > 0)
      .slice()
      .sort((itemA, itemB) => Math.abs(itemA.trolleyItemId) - Math.abs(itemB.trolleyItemId)),
);
