import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AemComponent from 'components/AemComponent';
import { CONTENT_FRAGMENT_NAME } from 'components/AemComponent/analyticsConstants';

const ContentFragment = ({
  cqResponsive,
  cqResponsiveClasses,
  fragmentData,
  position,
  uniqueId,
}) => (
  <section className={classNames(cqResponsiveClasses)} id={uniqueId} data-testid="content-fragment">
    <AemComponent
      component={{ ...fragmentData, cqResponsiveParent: cqResponsive }}
      position={position}
    />
  </section>
);

ContentFragment.displayName = CONTENT_FRAGMENT_NAME;

ContentFragment.propTypes = {
  cqResponsive: PropTypes.shape({}),
  cqResponsiveClasses: PropTypes.arrayOf(PropTypes.string),
  fragmentData: PropTypes.shape({}),
  position: PropTypes.string,
  uniqueId: PropTypes.string.isRequired,
};

ContentFragment.defaultProps = {
  cqResponsive: undefined,
  cqResponsiveClasses: undefined,
  fragmentData: undefined,
  position: undefined,
};

export default ContentFragment;
