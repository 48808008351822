import {
  ANOTHER_MODAL_OPEN,
  ANOTHER_MODAL_CLOSED,
  CLOSE_MODAL,
  OPEN_MODAL,
} from 'redux/modules/common-modal/actions/types';

import { ModalCloseOptions } from 'constants/modalCloseOptions';
import { BOOK_SLOT, CHANGE_SLOT_DATE, CHANGE_SLOT_TYPE } from 'constants/restrictions';
import { getSlotTypeConflictUrl } from 'redux/modules/slot-booking/selectors/get-slot-type-conflict-url';
import { getCommonModal } from 'redux/modules/common-modal/selectors/index';

import { bookSlotQueryString } from 'utils/book-slot-query-string';
import splitToLines from 'utils/split-to-lines';
import { getFromBlendedItemsById } from 'redux/modules/trolley/selectors/get-optimistic-item';
import { trackModalOpened, trackModalClosed, trackNotAdded } from 'analytics/modal-tracking';
import extractLineNumberFromSku from 'utils/extract-linenumber-from-sku';
import { getCustomerId } from 'redux/modules/sessions/selectors';
import { changeToAGroceriesCollectionModal, modalSeverityValues } from 'constants/modals';
import { ModalButton } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal/Buttons';
import { ButtonProps } from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import noop from 'lodash/noop';
import { openNativeDialog } from 'utils/webview/dialog';
import { push } from 'connected-react-router';

export interface IConflictModal {
  id?: string;
  messages: { title: string; reason: string; dismissal: string; aside: string };
  itemId: string;
  productId: string;
  resolutionActions: {
    id: string;
    message: string;
  }[];
  slotOptionDates: unknown[];
}

export interface IWebviewOptions {
  id: string;
  ctas?: (string | null)[];
  nativeMessage?: string[];
  hasCustomerCareButton?: boolean;
}

export interface IOpenModalArgs {
  asideText?: string;
  buttonOrientation?: 'horizontal' | 'vertical';
  buttons?: ModalButton[];
  cannotClose?: boolean;
  dataTest?: string;
  id?: string;
  messageIsHtml?: boolean;
  messageText?: string[];
  messageTextParams?: {
    [key: string]: boolean | number | string;
  };
  onCloseCallback?: (modalCloseValue?: unknown) => void;
  responseStatus?: number;
  /** This info comes from `modalSeverityValues` in `src/constants/modals.js` */
  severity: 'info' | 'error' | 'conflict';
  titleText?: string;
  useIngredientsModal?: boolean;
  webview?: IWebviewOptions;
}

export interface TypedModalButton<TValue extends ModalButton['buttonValue']> extends ModalButton {
  buttonValue?: TValue;
}

export interface ITypedOpenModalArgs<
  TButtonValue extends ModalButton['buttonValue'] = ModalButton['buttonValue'],
> extends Omit<IOpenModalArgs, 'onCloseCallback'> {
  buttons?: TypedModalButton<TButtonValue>[];
  onCloseCallback?: (modalCloseValue?: TButtonValue) => void;
}

let closeCallback: (modalCloseValue: unknown) => void = noop;

export function openModal<TButtonValue extends ModalButton['buttonValue']>(
  args: ITypedOpenModalArgs<TButtonValue>,
): WtrThunkAction<void>;
export function openModal(modal: IOpenModalArgs): WtrThunkAction<void> {
  return (dispatch, getState) => {
    const customerId = getCustomerId(getState());

    if (openNativeDialog(modal, customerId)) return;

    const {
      asideText,
      buttonOrientation,
      buttons,
      cannotClose = false,
      dataTest,
      id,
      messageIsHtml = false,
      messageText,
      messageTextParams,
      onCloseCallback,
      responseStatus,
      severity,
      titleText,
      useIngredientsModal,
      webview,
    } = modal;

    closeCallback = onCloseCallback ?? noop;

    trackModalOpened({
      id,
      severity,
      title: titleText,
      customerId,
    });

    dispatch({
      type: OPEN_MODAL,
      conflict: null,
      asideText,
      buttons,
      buttonOrientation,
      cannotClose,
      dataTest,
      id,
      messageIsHtml,
      messageText,
      messageTextParams,
      responseStatus,
      severity,
      titleText,
      useIngredientsModal,
      webview,
    });
  };
}

export const openConflictModal =
  (conflict: IConflictModal): WtrThunkAction<void> =>
  (dispatch, getState) => {
    closeCallback = noop;

    const trackingValues = {
      id: conflict.id || 'conflictModal',
      severity: modalSeverityValues.CONFLICT,
      title: conflict.messages.title,
      customerId: getCustomerId(getState()),
    };

    const editSlotUrl = getSlotTypeConflictUrl(getState());

    if (conflict.messages.reason.includes('left in stock')) {
      const { itemId, productId } = conflict;
      const lineNumber = extractLineNumberFromSku(productId);
      const trolleyItem = getFromBlendedItemsById(getState(), itemId);
      const { quantity: { amount = undefined } = {} } = trolleyItem;
      trackNotAdded(amount, lineNumber);
    }

    trackModalOpened(trackingValues);

    const isChangeToAGroceriesCollectionRequired =
      conflict.resolutionActions &&
      conflict.resolutionActions.find(
        action =>
          action.id === 'CHANGE_SLOT_TYPE' &&
          action.message.includes('Change to a Groceries collection'),
      );

    if (isChangeToAGroceriesCollectionRequired) {
      dispatch({
        type: OPEN_MODAL,
        conflict,
        ...changeToAGroceriesCollectionModal,
      });
      closeCallback = (closeValue: unknown) => {
        if (closeValue === ModalCloseOptions.CONFIRM) {
          dispatch(
            push(
              `${editSlotUrl}${bookSlotQueryString(editSlotUrl, conflict.slotOptionDates || [])}`,
            ),
          );
        }
      };
      return;
    }

    const buttons = [];

    if (conflict.messages.dismissal) {
      buttons.push({
        buttonText: conflict.messages.dismissal,
        buttonValue: ModalCloseOptions.OK,
        theme: 'primary' as ButtonProps['theme'],
      });
    }

    (conflict.resolutionActions || []).forEach(resolution => {
      if (resolution.id && resolution.message) {
        if (resolution.id === BOOK_SLOT || resolution.id === CHANGE_SLOT_DATE) {
          buttons.push({
            buttonText: resolution.message,
            theme: 'primary',
            to: `${editSlotUrl}${bookSlotQueryString(editSlotUrl, conflict.slotOptionDates || [])}`,
          });
        } else if (resolution.id === CHANGE_SLOT_TYPE) {
          buttons.push({
            buttonText: resolution.message,
            theme: 'primary',
            to: editSlotUrl,
          });
        }
      }
    });

    if (buttons.length > 1) {
      buttons[0].theme = 'secondary';
    }

    dispatch({
      type: OPEN_MODAL,
      asideText: conflict.messages.aside,
      buttons: buttons.length > 0 ? buttons : undefined, // Ensures the default button is used if buttons is empty
      cannotClose: true,
      conflict,
      id: trackingValues.id,
      messageText: splitToLines(conflict.messages.reason),
      titleText: trackingValues.title,
      severity: trackingValues.severity,
      useIngredientsModal: false,
    });
  };

export const closeModal =
  (
    modalCloseValue: ModalButton['buttonValue'],
    modalCloseText: ModalButton['buttonText'],
  ): WtrThunkAction<void> =>
  (dispatch, getState) => {
    const {
      id,
      severity,
      titleText: title,
    } = getCommonModal(getState() as { modal: unknown }) || {};

    dispatch({ type: CLOSE_MODAL });

    if (closeCallback) {
      setTimeout(() => {
        closeCallback(modalCloseValue);
      }, 0);
    }

    trackModalClosed({
      id,
      severity,
      title,
      response: (modalCloseText || modalCloseValue) as string,
      customerId: getCustomerId(getState()),
    });
  };

export interface NotifyModalArgs {
  id?: string;
  title?: string;
  severity?: IOpenModalArgs['severity'];
  response?: unknown;
}

export const notifyModalOpen =
  ({ id, title, severity }: NotifyModalArgs = {}): WtrThunkAction =>
  (dispatch, getState) => {
    dispatch({ type: ANOTHER_MODAL_OPEN });
    trackModalOpened({
      id,
      severity,
      title,
      customerId: getCustomerId(getState()),
    });
  };

export const notifyModalClosed =
  ({ id, severity, title, response }: NotifyModalArgs = {}): WtrThunkAction =>
  (dispatch, getState) => {
    dispatch({ type: ANOTHER_MODAL_CLOSED });
    trackModalClosed({
      id,
      severity,
      title,
      response: response as string,
      customerId: getCustomerId(getState()),
    });
  };
