import { push } from 'connected-react-router';
import queryString from 'query-string';

import urls from 'constants/urls';
import { getSearch } from 'redux/modules/multi-search/selectors';

const getMergedSearch = (state, value) => {
  const currentQuery = queryString.parse(getSearch(state));
  const { flt, sortBy } = currentQuery;
  return queryString.stringify({
    flt,
    sortBy,
    value,
  });
};

export const navigateToMultiSearch =
  (value, { preserveParams = false } = {}) =>
  (dispatch, getState) => {
    const search = preserveParams
      ? getMergedSearch(getState(), value)
      : queryString.stringify({ value });

    dispatch(
      push({
        pathname: urls.multiSearch,
        search,
      }),
    );
  };
