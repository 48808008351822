import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';

import { useExperiments } from './useExperiments';

export const context = createContext();
const { Provider } = context;

const Experiment = ({ children, id }) => {
  const { getDecisionById } = useExperiments();

  const decision = getDecisionById(id);

  return <Provider value={{ experimentId: id, decision }}>{children}</Provider>;
};
/**
 * @typedef {Object} Decision
 * @property {string} variant - The decision's variant
 * @property {any} payload - The payload that came with the decision
 * @property {function} trackExperimentView - Function to manually track experiment view
 */
/**
 *
 * @param {string} id - Experiment id from 'src/constants/monetateExperiments'
 * @returns {Decision} - Decision object
 */
export const useExperimentDecision = id => {
  const ctx = useContext(context);

  if (id !== ctx.experimentId) {
    console.error(
      `\n\nId [${id}] not available in this context!
      \n\nAvailable context experiment: ${ctx.experimentId}'\n\n`,
    );
  }

  return ctx;
};

Experiment.propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  id: PropTypes.string.isRequired,
};

export default Experiment;
