import slot from 'api/definitions/slot';
import splitDateAndTimeFromIsoDateTime from 'utils/split-date-and-time-from-iso-datetime';
import { SlotTypes } from 'constants/fulfilmentTypes';
import { CUSTOMER_SLOT_REQUEST_ACTIONS } from 'redux/modules/customer-slot/actions/types';
import { getCustomerSlotAddressAndBranch } from 'redux/modules/customer-slot/actions/get-customer-slot-address-and-branch';
import startSlotTimeout from 'redux/modules/customer-slot/actions/start-slot-timeout';
import { slotBeingBooked } from 'redux/modules/slots/actions/slot-being-booked';

export const getCustomerSlot = () => dispatch =>
  dispatch({
    types: CUSTOMER_SLOT_REQUEST_ACTIONS,
    apiCall: slot.getCurrentSlot(),
    callback: {
      success: ({ errors, data = {} } = {}) => {
        if (!errors && data?.currentSlot) {
          const { currentSlot } = data;
          const [slotDate, slotStartTime] = splitDateAndTimeFromIsoDateTime(
            currentSlot.startDateTime,
          );
          const [, slotEndTime] = splitDateAndTimeFromIsoDateTime(currentSlot.endDateTime);

          return {
            slotType: currentSlot.slotType,
            branchId: currentSlot.branchId,
            slotDate,
            slotStartTime,
            slotEndTime,
            slotExpiryTime: currentSlot.expiryDateTime,
            addressPostcode: currentSlot.postcode,
            addressId: currentSlot.addressId || '-1',
            substitutionPref: true,
            orderCutOff: currentSlot.orderCutoffDateTime,
            amendOrderCutOff: currentSlot.amendOrderCutoffDateTime,
            commonSlotType: currentSlot.slotType,
            slotReservationId: currentSlot.slotReservationId,
            slotGridType: currentSlot.slotGridType,
          };
        }
        return {
          addressId: null,
          addressName: '',
          branchId: null,
          slotDate: '',
          slotEndTime: '',
          slotExpiryTime: '',
          slotStartTime: '',
          slotType: SlotTypes.UNDEFINED,
          orderCutOff: '',
          amendOrderCutOff: '',
          slotReservationId: '',
        };
      },
    },
  }).then(currentSlot => {
    dispatch(getCustomerSlotAddressAndBranch());
    dispatch(startSlotTimeout());
    dispatch(slotBeingBooked(null));
    return currentSlot;
  });
