import { useContext } from 'react';
import PropTypes from 'prop-types';

import { getMonetateExperimentById } from 'constants/monetateExperiments';

import { context } from 'components/Experiment/Experiment';

import logInConsoleIfFindInconsistency from './logInConsoleIfFindInconsistency';

const VariantWithPayload = ({ render, name }) => {
  const {
    experimentId,
    decision: { variant, payload },
  } = useContext(context);
  const experiment = getMonetateExperimentById(experimentId);

  if (variant !== name) return null;

  logInConsoleIfFindInconsistency({ experimentId, experiment, name, variant });
  if (!payload) {
    // eslint-disable-next-line no-console
    console.error(
      `\n\nThe experiment id [${experimentId}] does't contain a payload set up in Monetate!
      \n\nCheck the documentation in 'wtr-website/docs/ab-tests.md' for more info'\n\n`,
    );
  }

  return render(payload);
};

VariantWithPayload.propTypes = {
  render: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
};

export default VariantWithPayload;
