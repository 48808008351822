import { createSelector } from 'reselect';
import { getItemsGroupedByCategoryLegacy } from 'redux/modules/favourites/selectors/get-items-grouped-by-category';
import { getAllPayloads } from 'redux/modules/experiments/selectors';
import { BOOST_CATS } from 'constants/monetateExperiments';

const EMPTY_ARRAY = [];

const getLineNumber = (_state, lineNumber) => lineNumber;

// TODO: this should consider the Favourites Experience groups as well.
const getBoostedProducts = createSelector(
  getItemsGroupedByCategoryLegacy,
  getAllPayloads,
  (originalGroups, payloads = []) => {
    const { categories = [] } = payloads?.[BOOST_CATS.experiment] || {};

    if (!originalGroups) {
      return EMPTY_ARRAY;
    }

    const groups = new Map(originalGroups);
    const boosted = categories.reduce((acc, catId) => {
      if (groups.has(catId)) {
        acc.push(groups.get(catId));
        groups.delete(catId);
      }

      return acc;
    }, []);

    // TODO: this only considers the first boosted category.
    return boosted[0]?.map(el => el.searchProduct) ?? EMPTY_ARRAY;
  },
);

export const getIsProductBoosted = createSelector(
  [getBoostedProducts, getLineNumber],
  (boostedProducts, lineNumber) => boostedProducts.includes(lineNumber),
);
