import { getFeatureFlags } from 'utils/feature-flags';
import { Promotion } from 'constants/products';
import { getSearchAndBrowseLoading } from 'redux/modules/search-and-browse/selectors';
import { getMealDealPath as getMealDealPathNew } from 'redux/modules/entities/selectors/promotions/meal-deal';
import { MealDealId } from '../types';
import {
  getMealDeal as getMealDealNew,
  getMealDealProductsGrouped as getMealDealProductsGroupedNew,
  getMealDealGroupByGroupId as getMealDealGroupByGroupIdNew,
  getMealDealBuilderItems as getMealDealBuilderItemsNew,
  getMealDealRecommendedProducts as getMealDealRecommendedProductsNew,
  getMealDealDisabledState as getMealDealDisabledStateNew,
  getMealDealNotFound as getMealDealNotFoundNew,
  getMealDealLoading as getMealDealLoadingNew,
  getMealDealTitle as getMealDealTitleNew,
  getMealDealBuilderLineNumbers as getMealDealBuilderLineNumbersNew,
  isResponseFailure as isResponseFailureNew,
} from './newIndex';
import {
  getMealDeal as getMealDealOld,
  getMealDealProductsGrouped as getMealDealProductsGroupedOld,
  getMealDealGroupByGroupId as getMealDealGroupByGroupIdOld,
  getMealDealBuilderItems as getMealDealBuilderItemsOld,
  getMealDealRecommendedProducts as getMealDealRecommendedProductsOld,
  getMealDealDisabledState as getMealDealDisabledStateOld,
  getMealDealPath as getMealDealPathOld,
  getMealDealTitle as getMealDealTitleOld,
  getMealDealBuilderLineNumbers as getMealDealBuilderLineNumbersOld,
} from './oldIndex';

const EMPTY_MEAL_DEAL = {};

export const getMealDeal = (state: WtrState, id: number) => {
  const { enableEnrichPromotionForWebsite: offersExperience } = getFeatureFlags();

  return offersExperience ? getMealDealNew(state) : getMealDealOld(state, id) || EMPTY_MEAL_DEAL;
};

export const isResponseFailure = (state: WtrState) => {
  const { enableEnrichPromotionForWebsite: offersExperience } = getFeatureFlags();

  return offersExperience ? isResponseFailureNew(state) : false;
};

export const getMealDealLoading = (state: WtrState) => {
  const { enableEnrichPromotionForWebsite: offersExperience } = getFeatureFlags();

  return offersExperience ? getMealDealLoadingNew(state) : getSearchAndBrowseLoading(state);
};

export const getMealDealNotFound = (state: WtrState) => {
  const { enableEnrichPromotionForWebsite: offersExperience } = getFeatureFlags();

  return offersExperience ? getMealDealNotFoundNew(state) : false;
};

export const getMealDealProductsGrouped = (state: WtrState, id: number) => {
  const { enableEnrichPromotionForWebsite: offersExperience } = getFeatureFlags();

  return offersExperience
    ? getMealDealProductsGroupedNew(state)
    : // eslint-disable-next-line
      // @ts-ignore: createSelector known issue, where the return function is typed as receiving a single arg
      getMealDealProductsGroupedOld(state, id);
};

export const getMealDealGroupByGroupId = (state: WtrState, id: string) => {
  const { enableEnrichPromotionForWebsite: offersExperience } = getFeatureFlags();

  return offersExperience
    ? getMealDealGroupByGroupIdNew(state, id)
    : getMealDealGroupByGroupIdOld(state, id);
};

export const getMealDealBuilderItems = (state: WtrState, id: string) => {
  const { enableEnrichPromotionForWebsite: offersExperience } = getFeatureFlags();

  return offersExperience
    ? getMealDealBuilderItemsNew(state)
    : // eslint-disable-next-line
      // @ts-ignore: createSelector known issue, where the return function is typed as receiving a single arg
      getMealDealBuilderItemsOld(state, id);
};

export const getMealDealRecommendedProducts = (state: WtrState, id: string) => {
  const { enableEnrichPromotionForWebsite: offersExperience } = getFeatureFlags();

  return offersExperience
    ? getMealDealRecommendedProductsNew(state)
    : // eslint-disable-next-line
      // @ts-ignore: createSelector known issue, where the return function is typed as receiving a single arg
      getMealDealRecommendedProductsOld(state, id);
};

export const getMealDealDisabledState = (state: WtrState, today: number, id: MealDealId) => {
  const { enableEnrichPromotionForWebsite: offersExperience } = getFeatureFlags();

  return offersExperience
    ? getMealDealDisabledStateNew(state, today)
    : // eslint-disable-next-line
      // @ts-ignore: createSelector known issue, where the return function is typed as receiving a single arg
      getMealDealDisabledStateOld(state, today, id);
};

export const getMealDealPath = (
  promotion: Pick<Promotion, 'discount' | 'promotionId' | 'groups'>,
) => {
  const { enableEnrichPromotionForWebsite: offersExperience } = getFeatureFlags();
  const { discount, promotionId, groups } = promotion;

  return offersExperience
    ? getMealDealPathNew(discount, promotionId, groups)
    : // eslint-disable-next-line
      // @ts-ignore: createSelector known issue, where the return function is typed as receiving a single arg
      getMealDealPathOld(promotionId);
};

export const getMealDealTitle = (state: WtrState, id: MealDealId) => {
  const { enableEnrichPromotionForWebsite: offersExperience } = getFeatureFlags();

  return offersExperience ? getMealDealTitleNew(state) : getMealDealTitleOld(state, id);
};

export const getMealDealBuilderLineNumbers = (state: WtrState, id: string) => {
  const { enableEnrichPromotionForWebsite: offersExperience } = getFeatureFlags();

  return offersExperience
    ? getMealDealBuilderLineNumbersNew(state)
    : // eslint-disable-next-line
      // @ts-ignore: createSelector known issue, where the return function is typed as receiving a single arg
      getMealDealBuilderLineNumbersOld(state, id);
};

export {
  getMealDealGroups,
  getProductMealDeal,
  getNumberCompletedDeals,
  getMealDealPromoGroup,
  getIncompleteGroups,
} from 'redux/modules/meal-deals/selectors/oldIndex';
