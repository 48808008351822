import { getCustomerId } from 'redux/modules/sessions/selectors';
import { getFeatureFlags } from 'utils/feature-flags';

const eligibleSegments = {
  10: ['9'],
  20: ['8', '9'],
  30: ['7', '8', '9'],
  40: ['6', '7', '8', '9'],
  50: ['5', '6', '7', '8', '9'],
  60: ['4', '5', '6', '7', '8', '9'],
  70: ['3', '4', '5', '6', '7', '8', '9'],
  80: ['2', '3', '4', '5', '6', '7', '8', '9'],
  90: ['0', '2', '3', '4', '5', '6', '7', '8', '9'], // -1 is anonymous, so using 0 instead
  100: ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'],
};

export default state => {
  const customerId = getCustomerId(state);
  const percentageToLetThrough = getFeatureFlags().autosuggest;

  if (!customerId || eligibleSegments[percentageToLetThrough] == null) return false;

  const eligible = eligibleSegments[percentageToLetThrough].includes(
    customerId[customerId.length - 1],
  );

  return eligible;
};
