import { Incentive } from 'constants/incentives';
import { Product } from 'constants/products';
import { ApiError } from 'constants/errors';
import { deepFreeze } from 'utils/deepFreeze';

export interface IncentivesState {
  voucherC2CPilot: boolean;
  incentivesList: Incentive[];
  products: Product[];
  isLoading: boolean;
  isLoaded: boolean;
  isLoadingProducts: boolean;
  isProcessingIncentiveSelection: boolean;
  isProcessingIncentiveDeselection: boolean;
  serverError: ApiError | null;
}

export const initialState: Readonly<IncentivesState> = deepFreeze({
  voucherC2CPilot: false,
  incentivesList: [],
  products: [],
  isLoading: false,
  isLoaded: false,
  isLoadingProducts: false,
  isProcessingIncentiveSelection: false,
  isProcessingIncentiveDeselection: false,
  serverError: null,
});

export default initialState;
