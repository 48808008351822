import { createSelector } from 'reselect';
import get from 'lodash/get';

import linkTypes from 'constants/link-types';

import {
  getFavouritesProducts,
  getFavouritesProductsLegacy,
} from 'redux/modules/favourites/selectors/get-favourites-products';
import { getProductById } from 'redux/modules/entities/selectors/products';
import { getFavourites } from './get-favourites';

const getProductEntity = state => id => getProductById(state, id);

const getFavouritesLinks = createSelector([getFavourites], favourites => favourites.links || []);

export const getFavouritesOfferFilterActiveStateLegacy = state =>
  get(state, 'favourites.offersFilter', false);

export const getFavouritesOfferFilterActiveState = createSelector(
  [getFavourites],
  favourites => favourites.offersFilter || false,
);

export const favouritesHasOfferLinks = createSelector([getFavouritesLinks], links =>
  links.some(link => get(link, 'metaData.group') === linkTypes.OFFER),
);

export const favouritesHasOfferProductsLegacy = createSelector(
  [getFavouritesProductsLegacy, getProductEntity],
  (products, getProductEntityById) =>
    products.some(
      product =>
        get(getProductEntityById(product.searchProduct), 'pricing.promotions', []).length > 0,
    ),
);

export const favouritesHasOfferProducts = createSelector(
  [getFavouritesProducts, getProductEntity],
  (products, getProductEntityById) =>
    products.some(
      product =>
        get(getProductEntityById(product.searchProduct), 'pricing.promotions', []).length > 0,
    ),
);
