import env from 'env/env';

import { pathJoin } from 'utils/general';

const formatClientApiUrl = path => pathJoin(env.apiPath, path);
const formatServerApiUrl = path => pathJoin(env.serverPath, path);
const formatJohnLewisServerUrl = path => pathJoin(env.johnLewisServerPath, path);

export const formatUrl = (url, server = __SERVER__) => {
  if (server) return formatServerApiUrl(url);
  return formatClientApiUrl(url);
};

export const formatJohnLewisUrl = url => formatJohnLewisServerUrl(url);
