import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';
import get from 'lodash/get';

import { backgroundImage } from 'utils/backgroundImage';

import styles from 'components/PromoIconography/BrandIcon/BrandIcon.scss';

const BrandIcon = pure(({ display, image, position, adjust }) => {
  if (!display || !image || !image.src) return null;

  const iconPosition = position ? get(styles, `${position}`, '') : '';
  const pull = adjust ? ` ${styles.notATradingCell}` : ''; // TODO [BM]: Remove after redesign

  return (
    <div className={`${iconPosition}${pull}`}>
      <div className={styles.brandImage} style={backgroundImage(image)} data-testid="brand-icon" />
    </div>
  );
});

BrandIcon.displayName = 'BrandIcon';

BrandIcon.propTypes = {
  adjust: PropTypes.bool, // TODO [BM]: Remove after redesign
  display: PropTypes.bool,
  image: PropTypes.object,
  position: PropTypes.string,
};

export default BrandIcon;
