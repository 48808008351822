import React from 'react';
import PropTypes from 'prop-types';
import pure from 'recompose/pure';

import { isDefined } from 'utils/validation';

import EventMonitor from 'components/EventMonitor/EventMonitor';

import Typography from '@johnlewispartnership/wtr-ingredients/foundations/typography';

import styles from './SiteFooterMinimal.scss';

const SiteFooterMinimal = pure(({ data }) =>
  data ? (
    <footer className={`${styles.footer} container-fluid`}>
      <div className={styles.links}>
        {isDefined(data.site.links) &&
          data.site.links.map((link, index) => {
            if (index < 3) {
              const eventProps = {
                actionType: 'redirect',
                originComponent: 'footer minimal link',
                shortDescription: `footer minimal link: site links: ${link.title}`,
                key: `footer-minimal-site-links-${index}`,
              };

              return (
                <EventMonitor {...eventProps}>
                  <a href={link.href} target={link.popup ? '_blank' : '_self'} rel="noreferrer">
                    <Typography element="span" styleAs="paragraphSmallLight">
                      {link.title}
                    </Typography>
                  </a>
                </EventMonitor>
              );
            }
          })}
      </div>
      <div className={styles.copyright}>
        <Typography element="span" styleAs="paragraphSmallLight">
          {data.copyright}
        </Typography>
      </div>
    </footer>
  ) : null,
);

SiteFooterMinimal.propTypes = {
  data: PropTypes.object,
};

SiteFooterMinimal.displayName = 'SiteFooterMinimal';

export default SiteFooterMinimal;
