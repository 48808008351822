import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';

import styles from 'components/PromoIconography/MyWaitroseIcon/MyWaitroseIcon.scss';

const reductionIsOversized = reduction => reduction && reduction.length > 4;

const additionalMessaging = (props = {}) => {
  const { additionalReduction } = props;

  if (!additionalReduction) return null;

  const additionalStyle = reductionIsOversized(additionalReduction)
    ? styles.oversized
    : styles.additional;

  return (
    <div data-testid="myWaitrose-messaging">
      <span className="sr-only">
        and an extra {additionalReduction} off with the my Waitrose card
      </span>
      <span className={styles.bridge} aria-hidden="true">
        and an extra
      </span>
      <span className={additionalStyle} aria-hidden="true">
        {additionalReduction}
        <br />
        OFF
      </span>
      <span className={styles.bridge} aria-hidden="true">
        with
      </span>
    </div>
  );
};

const MyWaitroseIcon = pure(props => {
  if (!props.display) return null;

  const { supercharged } = props;

  let myWaitroseStyle = supercharged ? styles.supercharged : styles.wrapper;

  if (props.roundel && !supercharged) myWaitroseStyle = styles.banner;

  return (
    <div className={myWaitroseStyle}>
      {additionalMessaging(props)}
      <div className={styles.image} />
    </div>
  );
});

MyWaitroseIcon.displayName = 'MyWaitroseIcon';

MyWaitroseIcon.propTypes = {
  display: PropTypes.bool,
  additionalReduction: PropTypes.string,
  roundel: PropTypes.bool,
  supercharged: PropTypes.bool,
};

export default MyWaitroseIcon;
