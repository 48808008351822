import React from 'react';
import classNames from 'classnames';

import { textCardShape } from 'constants/types/TradingCell';

import { Button } from '@johnlewispartnership/wtr-ingredients/ingredients/Button';
import AnchorLink from 'components/AnchorLink';

import style from './TextCard.scss';

const TextCard = ({
  buttonStyle,
  disclaimer,
  displayDisclaimer,
  fourCells,
  heading,
  link,
  offerHeading,
  oneCell,
  showButton = true,
  subheading,
  supportingTitle,
  theme,
  promoClick,
}) => {
  const showButtonLinks = showButton && link?.url;

  return (
    <div
      className={classNames(style.promoText, style.columns, {
        [style.red]: theme === 'red',
      })}
      data-testid={`textcard-${oneCell ? 'one-cell' : 'four-cells'}`}
    >
      <div className={style.mainColumn}>
        {supportingTitle && <p className={style.supportingTitle}>{supportingTitle}</p>}

        <h3 className={style.heading}>
          {offerHeading && <span className={style.offerHeading}>{offerHeading}</span>}
          {heading}
        </h3>

        {subheading && <p className={style.subheading}>{subheading}</p>}

        {showButtonLinks && (
          <div className={classNames(style.button, { [style.fourCells]: fourCells })}>
            <Button
              data-testid="text-card-btn"
              type={undefined}
              label={link.text}
              component={AnchorLink}
              to={link.url}
              inNewTab={link.newWindow}
              theme={buttonStyle === 'primaryLight' ? 'primaryWhite' : buttonStyle}
              width={oneCell ? 'full' : 'standard'}
              onClick={promoClick}
            />
          </div>
        )}

        {displayDisclaimer && <small className={style.disclaimer}>{disclaimer}</small>}
      </div>

      {showButtonLinks && (
        <div className={classNames(style.sideColumn, { [style.fourCells]: fourCells })}>
          <Button
            type={undefined}
            label={link.text}
            component={AnchorLink}
            to={link.url}
            inNewTab={link.newWindow}
            theme={buttonStyle === 'primaryLight' ? 'primaryWhite' : buttonStyle}
            width={oneCell ? 'full' : 'standard'}
            onClick={promoClick}
          />
        </div>
      )}
    </div>
  );
};
export default TextCard;

TextCard.propTypes = {
  ...textCardShape,
};

TextCard.defaultProps = {
  buttonStyle: 'primary',
  disclaimer: '',
  displayDisclaimer: false,
  fourCells: false,
  heading: '',
  link: {
    text: '',
    newWindow: false,
  },
  offerHeading: '',
  oneCell: false,
  subheading: '',
  supportingTitle: '',
  theme: 'light',
};
