const logInConsoleIfFindInconsistency = ({ experimentId, experiment, name, variant }) => {
  if (!experiment.variant[name]) {
    // eslint-disable-next-line no-console
    console.error(
      `\n\nVariant [${name}] is not a option of [${experimentId}]!
          \n\nAvailable options: ${Object.keys(experiment.variant).join()}
          \n\nRegister it in 'src/constants/monetateExperiments.js'\n\n`,
    );
  }

  if (!experiment.variant[variant]) {
    // eslint-disable-next-line no-console
    console.error(
      `\n\nVariant [${variant}] returned by Monetate is not a option of [${experimentId}]!
          \n\nAvailable options: ${Object.keys(experiment.variant).join()}
          \n\nRegister it in 'src/constants/monetateExperiments.js'\n\n`,
    );
  }
};
export default logInConsoleIfFindInconsistency;
