import React from 'react';
import PropTypes from 'prop-types';
import { BreadcrumbLink } from '@johnlewispartnership/wtr-ingredients/ingredients/Breadcrumbs/BreadcrumbLink';
import AnchorLink from 'components/AnchorLink';

const Back = ({ to }) => {
  if (!to) {
    return null;
  }

  const onClickBack = e => {
    e.preventDefault();
    to();
  };

  return (
    <BreadcrumbLink back component={AnchorLink} onClick={onClickBack}>
      Back
    </BreadcrumbLink>
  );
};

Back.propTypes = {
  to: PropTypes.func,
};

Back.defaultProps = {
  to: undefined,
};

export default Back;
