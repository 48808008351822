import React, { Fragment } from 'react';
import AnchorLink from 'components/AnchorLink';
import urls from 'constants/urls';
import { getFeatureFlags } from 'utils/feature-flags';
import styles from '../Registration.scss';

export const errors = () => {
  const loginUrl = getFeatureFlags().identity_enableOAuth2Web ? urls.loginOAuth2 : urls.login;

  return {
    ACCOUNT_ALREADY_EXISTS: {
      title: 'This email address is already registered',
      message: (
        <Fragment>
          Please enter another email address or{' '}
          <AnchorLink to={loginUrl} className={styles.errorLink}>
            log in to your existing account
          </AnchorLink>
          . If you have forgotten your password, you can{' '}
          <AnchorLink to={urls.forgotPassword} className={styles.errorLink}>
            reset it here
          </AnchorLink>
          .
        </Fragment>
      ),
    },
    CREATE_ACCOUNT_ERROR: {
      title: `Sorry, there's been a problem`,
      message: (
        <Fragment>
          We were not able to create your account please try again later.
          <br />
          <br />
          If the problem persists please contact Customer Care on{' '}
          <a href="tel:0800188884">0800 188 884</a>.
        </Fragment>
      ),
    },
  };
};
