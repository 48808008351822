import { BREAKPOINT_MAP } from './constants';

interface BreakpointValues {
  fallback: string | undefined;
  xs?: string;
  sm?: string;
  md?: string;
  lg?: string;
}

/**
 * A helper function to generate a "sizes" string by passing an object of sizes for each breakpoint
 * e.g. passing { xs: 100vw, lg: 50vw, fallback: 25vw}
 * returns `(max-width: 544px) 100vw, (max-width: 992px) 50vw, 25vw`
 */
export const generateSizesByBreakpoint = ({ fallback, xs, sm, md, lg }: BreakpointValues) => {
  const breakpoints = { lg, md, sm, xs }; // This is to ensure they are looped in the correct order, large to small
  return Object.entries(breakpoints).reduce((sizes, [bp, value]) => {
    if (!value) return sizes;
    const [, max] = BREAKPOINT_MAP[bp as keyof typeof breakpoints];
    return sizes ? `(max-width: ${max}px) ${value}, ${sizes}` : `(max-width: ${max}px) ${value}`;
  }, fallback);
};
