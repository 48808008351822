import React from 'react';
import propTypes from 'prop-types';
import { Helmet } from 'react-helmet-async';
import {
  ALL_IMAGE_WIDTH_SIZES,
  LG_IMAGE_WIDTH_SIZES,
  MD_IMAGE_WIDTH_SIZES,
  SM_IMAGE_WIDTH_SIZES,
} from './constants';

const getMedia = breakpoint => {
  if (breakpoint === 'mobile') return '(max-width: 544px)';
  if (breakpoint === 'tablet') return '(max-width: 991px) and (min-width: 544px)';
  if (breakpoint === 'desktop-and-tablet') return '(min-width: 544px)';
  return undefined;
};

const fit = 'fit=constrain%2C0';

const breakpointImageWidthSizes = {
  mobile: [...SM_IMAGE_WIDTH_SIZES, ...MD_IMAGE_WIDTH_SIZES],
  tablet: [...MD_IMAGE_WIDTH_SIZES, ...LG_IMAGE_WIDTH_SIZES],
};

export const buildSrcSet = (scene7Url, breakpoint) => {
  const imageWidthSizes = breakpointImageWidthSizes[breakpoint] || ALL_IMAGE_WIDTH_SIZES;
  return imageWidthSizes.map(width => `${scene7Url}&wid=${width}&${fit} ${width}w`).join(',');
};

function Sources({ scene7Url, breakpoint, sizes, media, height, width, preload }) {
  if (!scene7Url) return null;

  const srcSet = buildSrcSet(scene7Url, breakpoint);

  return (
    <>
      {preload && (
        <Helmet>
          <link
            rel="preload"
            as="image"
            href={scene7Url}
            // @ts-expect-error -> TS error is expected here while waiting to upgrade to React v18
            // eslint-disable-next-line react/no-unknown-property
            imagesrcset={srcSet}
            // eslint-disable-next-line react/no-unknown-property
            imagesizes={sizes}
            data-testid="image-preload-link"
            // eslint-disable-next-line react/no-unknown-property
            fetchpriority="high"
          />
        </Helmet>
      )}
      <source
        data-testid="source"
        srcSet={srcSet}
        media={media || getMedia(breakpoint)}
        sizes={sizes}
        height={height}
        width={width}
      />
    </>
  );
}

Sources.defaultProps = {
  scene7Url: undefined,
  breakpoint: undefined,
  sizes: undefined,
  media: undefined,
  height: undefined,
  width: undefined,
  preload: false,
};

Sources.propTypes = {
  scene7Url: propTypes.string,
  breakpoint: propTypes.string,
  sizes: propTypes.string,
  media: propTypes.string,
  height: propTypes.string,
  width: propTypes.string,
  preload: propTypes.bool,
};

export default Sources;
