import urls from 'constants/urls';
import locator from 'utils/locator';

export default error => () => {
  const status = error?.status;
  const code = error?.response?.body?.code;

  if (status === 409 && code === 'ORDER_PAYMENT_145') {
    locator.href = urls.blockTraFraudUser;

    return true;
  }

  return false;
};
