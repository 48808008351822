import { matchPath } from 'react-router-dom';
import locator from 'utils/locator';
import urls from 'constants/urls';

// List of URLs that should be excluded from executing the usual set of API calls before render
const urlList = [
  urls.forgotPassword,
  urls.forgotPasswordLocked,
  urls.checkout,
  urls.resolveOrderPayment,
];

export default () => urlList.some(path => !!matchPath(locator.pathname, { path }));
