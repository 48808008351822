// Using dynamic require to solve circular dependency build problem
/* eslint-disable global-require */
/* eslint-disable @typescript-eslint/no-var-requires */

import { StatusCodeHandler } from './types';

export const getHandlers = (): Record<number, StatusCodeHandler> => ({
  401: require('./401').handle401,
  403: require('./403').handle403,
  503: require('./503').handle503,
});
