import { createSelector } from 'reselect';
import chunk from 'lodash/chunk';
import { FORMAT, dayjs } from 'utils/date';
import { formatAsPounds } from 'utils/currency';
import { getFeatureFlags } from 'utils/feature-flags';
import { DateString } from 'utils/date-string.d';
import { getStateAtNamespaceKey } from 'redux/get-state-at-namespace-key';
import { getBlendedOptimisticTrolleyItems } from 'redux/modules/trolley/selectors/get-optimistic-items';
import { getProducts } from 'redux/modules/entities/selectors/products';
import { LegacyProductReference, BlendedTrolleyProduct, PromotionGroup } from 'constants/products';
import { OffersExperienceGroup } from 'api/definitions/offers-experience';
import { TransformedLegacySearchProduct } from 'redux/transforms/types';
import { getCustomerSlotDate } from 'redux/modules/customer-slot/selectors';
import {
  MealDealState,
  MealDealSuccessState,
  MealDealErrorState,
  MealDealErrorCode,
  MEAL_DEAL_BRANCH_NOT_APPLICABLE_ERROR_CODE,
  MEAL_DEAL_NOT_FOUND_ERROR_CODE,
  MEAL_DEAL_ONLY_CONTENT_AVAILABLE_ERROR_CODE,
  MEAL_DEAL_OFFER_NOT_ACTIVE_ERROR_CODE,
  MEAL_DEAL_UNKNOWN_ERROR_CODE,
  MEAL_DEAL_INVALID_ID_ERROR_CODE,
  MEAL_DEAL_ERROR_FETCHING_OFFER_ERROR_CODE,
  MEAL_DEAL_ERROR_FETCHING_PRODUCTS_ERROR_CODE,
  MEAL_DEAL_MISSING_CUSTOMER_ORDER_ID,
} from '../types';
import {
  ENDING_TODAY_CAUSE,
  ENDING_TODAY_TITLE,
  ENDING_SOON_CAUSE,
  ENDING_SOON_TITLE,
  INVALID_SLOT_CAUSE,
  INVALID_SLOT_TITLE,
  INVALID_BRANCH_CAUSE,
} from './constants';

type BlendedTrolleyProductWithPrice = BlendedTrolleyProduct & { price: { amount: number } };

type BuilderProduct = BlendedTrolleyProductWithPrice | null;

type MealDealBuilderGroup = Omit<OffersExperienceGroup, 'lineNumbers'> & {
  groupId: string;
  items: BuilderProduct[];
};

type MealDealBuilder = {
  groups: MealDealBuilderGroup[];
  builderId: number;
  completed: boolean;
  savings: string;
};

const responseFailureErrorCodes: MealDealErrorCode[] = [
  MEAL_DEAL_INVALID_ID_ERROR_CODE,
  MEAL_DEAL_ERROR_FETCHING_OFFER_ERROR_CODE,
  MEAL_DEAL_ERROR_FETCHING_PRODUCTS_ERROR_CODE,
  MEAL_DEAL_MISSING_CUSTOMER_ORDER_ID,
  MEAL_DEAL_UNKNOWN_ERROR_CODE,
];

const isMealDealSuccessfullyLoaded = (mealDeal: MealDealState): mealDeal is MealDealSuccessState =>
  'groups' in mealDeal;

const isMealDealError = (mealDeal: MealDealState): mealDeal is MealDealErrorState =>
  'errorCode' in mealDeal;

export const getMealDeal = (state: WtrState): MealDealState =>
  getStateAtNamespaceKey(state, 'strategicMealDeals');

export const isResponseFailure = createSelector(
  getMealDeal,
  mealDeal => isMealDealError(mealDeal) && responseFailureErrorCodes.includes(mealDeal.errorCode),
);

export const getMealDealLoading = createSelector(
  getMealDeal,
  mealDeal => !('loading' in mealDeal) || mealDeal.loading,
);

export const getMealDealNotFound = createSelector(
  getMealDeal,
  mealDeal => isMealDealError(mealDeal) && mealDeal.errorCode === MEAL_DEAL_NOT_FOUND_ERROR_CODE,
);

export const getMealDealSlotInvalid = createSelector(
  getMealDeal,
  mealDeal =>
    isMealDealError(mealDeal) && mealDeal.errorCode === MEAL_DEAL_OFFER_NOT_ACTIVE_ERROR_CODE,
);

export const getMealDealTitle = createSelector(getMealDeal, mealDeal =>
  isMealDealSuccessfullyLoaded(mealDeal) ? mealDeal.name : 'Meal Deal',
);

const EMPTY_GROUPS: OffersExperienceGroup[] = [];

const getInvalidSlotMessage =
  'This offer is available for delivery or click and collect for dates outside of your selected slot';

const getEndingSoonMessage = (mealDealEndDate: dayjs.Dayjs) =>
  `${
    mealDealEndDate
      ? `Book a collection or delivery before ${mealDealEndDate.format(FORMAT.LONG_DAY_MONTH)}`
      : ''
  }`;

const getEndingTodayMessage =
  'It is not available for delivery from your local Waitrose. It may be available in store.';

const getMealDealGroups = createSelector(getMealDeal, mealDeal =>
  isMealDealSuccessfullyLoaded(mealDeal) ? mealDeal.groups : EMPTY_GROUPS,
);

const getMealDealSavings = createSelector(getMealDeal, mealDeal =>
  isMealDealSuccessfullyLoaded(mealDeal)
    ? `All for just ${formatAsPounds(mealDeal.discount.value.amount)}`
    : '',
);

const toLegacyProductReference = (lineNumber: string): LegacyProductReference => ({
  searchProduct: lineNumber,
});

export const getMealDealProductsGrouped = createSelector(
  getMealDealGroups,
  groups =>
    new Map(
      groups.map(({ id, lineNumbers }) => [
        id.toString(),
        lineNumbers.map(toLegacyProductReference),
      ]),
    ),
);

export const getMealDealGroupByGroupId = createSelector(
  getMealDealGroups,
  (_state: WtrState, groupId: string) => Number(groupId),
  (groups, groupId) => groups.find(({ id }) => groupId === id),
);

const byPriceDescending = (
  itemA: BlendedTrolleyProductWithPrice,
  itemB: BlendedTrolleyProductWithPrice,
) => itemA.price.amount - itemB.price.amount;

const createNullArray = (size: number): null[] => new Array(size).fill(null);

const isMealDealGroupComplete = (mealDealBuilderGroup: MealDealBuilderGroup) =>
  mealDealBuilderGroup.items.every(Boolean);

const isMealDealComplete = (mealDealBuilderGroups: MealDealBuilderGroup[]) =>
  mealDealBuilderGroups.map(isMealDealGroupComplete).every(Boolean);

const EMPTY_MEAL_DEAL_BUILDERS: MealDealBuilder[] = [];

const getMealDealBuilderItemsInternal = (
  groups: (OffersExperienceGroup | PromotionGroup)[],
  savings: string,
  productEntities: Record<string, TransformedLegacySearchProduct>,
  blendedTrolleyItems: BlendedTrolleyProduct[],
) => {
  if (!groups.length) {
    return EMPTY_MEAL_DEAL_BUILDERS;
  }

  const mealDealGroupsWithChunkedItems = groups.map((group, index) => {
    const { lineNumbers, name, threshold } = group;
    const groupId = 'id' in group ? group.id : index + 1;
    const itemsArray: BlendedTrolleyProductWithPrice[] = [];

    lineNumbers.forEach(lineNumber => {
      const trolleyItemInGroup = blendedTrolleyItems.find(item => item.lineNumber === lineNumber);

      if (!trolleyItemInGroup) {
        return;
      }

      const price =
        'price' in trolleyItemInGroup
          ? trolleyItemInGroup.price
          : productEntities[lineNumber].currentSaleUnitPrice.price;

      for (let i = 0; i < trolleyItemInGroup.quantity.amount; i += 1) {
        itemsArray.push({ ...trolleyItemInGroup, price });
      }
    });

    const itemsSortedByPrice = itemsArray.sort(byPriceDescending);
    const itemsChunkedByThreshold: BuilderProduct[][] = chunk(
      (itemsSortedByPrice as BuilderProduct[]).concat(
        createNullArray(threshold - (itemsSortedByPrice.length % threshold)),
      ),
      threshold,
    );

    return {
      id: groupId,
      itemsChunkedByThreshold,
      name,
      threshold,
      groupId: String(groupId),
    };
  });

  const builderCount = Math.min(
    ...mealDealGroupsWithChunkedItems.map(
      ({ itemsChunkedByThreshold }) => itemsChunkedByThreshold.length,
    ),
  );

  const mealDealBuilders: MealDealBuilder[] = [];

  for (let i = 0; i < builderCount; i += 1) {
    const mealDealBuilderGroups = mealDealGroupsWithChunkedItems.map(
      ({ itemsChunkedByThreshold, ...rest }) => ({
        items: itemsChunkedByThreshold[i],
        ...rest,
      }),
    );

    const builderMealDeal = {
      builderId: i,
      completed: isMealDealComplete(mealDealBuilderGroups),
      groups: mealDealBuilderGroups,
      savings,
    };

    mealDealBuilders.push(builderMealDeal);
  }

  return mealDealBuilders;
};

export const getMealDealBuilderItems = createSelector(
  [getMealDealGroups, getMealDealSavings, getProducts, getBlendedOptimisticTrolleyItems],
  getMealDealBuilderItemsInternal,
);

export const getMealDealBuilderItemsFromPromotionGroups = createSelector(
  [
    (_state, groups: PromotionGroup[]) => groups,
    () => '',
    getProducts,
    getBlendedOptimisticTrolleyItems,
  ],
  getMealDealBuilderItemsInternal,
);

const EMPTY_RECOMMENDATIONS: LegacyProductReference[] = [];

export const getMealDealRecommendedProducts = createSelector(getMealDeal, mealDeal =>
  isMealDealSuccessfullyLoaded(mealDeal) ? mealDeal.recommendations : EMPTY_RECOMMENDATIONS,
);

const isContentOnlyAvailable = (mealDeal: MealDealState) =>
  isMealDealError(mealDeal) && mealDeal.errorCode === MEAL_DEAL_ONLY_CONTENT_AVAILABLE_ERROR_CODE;

const isMealDealBranchNotApplicable = (mealDeal: MealDealState) =>
  isMealDealError(mealDeal) && mealDeal.errorCode === MEAL_DEAL_BRANCH_NOT_APPLICABLE_ERROR_CODE;

const isMealDealSlotInvalid = (mealDeal: MealDealState) =>
  isMealDealError(mealDeal) && mealDeal.errorCode === MEAL_DEAL_OFFER_NOT_ACTIVE_ERROR_CODE;

const getMealDealEndDate = (endDate: DateString): dayjs.Dayjs => {
  const {
    browse_mealDealEndDateToday: mealDealEndDateToday,
    browse_mealDealEndDate1Day: mealDealEndDate1Day,
    browse_mealDealEndDate2Day: mealDealEndDate2Day,
  } = getFeatureFlags();

  if (mealDealEndDateToday) {
    return dayjs().tz();
  }

  if (mealDealEndDate1Day) {
    return dayjs().tz().add(1, 'day');
  }

  if (mealDealEndDate2Day) {
    return dayjs().tz().add(2, 'day');
  }

  return dayjs(endDate).tz();
};

const comingSoonMessage = (mealDeal: MealDealSuccessState) => {
  const startDate = dayjs(mealDeal.startDate);
  const endDate = dayjs(mealDeal.endDate);
  const isSameYear = startDate.year() === endDate.year();

  return `Offer applies to delivery/collection slots from
    ${startDate.format(FORMAT.DAY_MONTH)}${isSameYear ? '' : ` ${startDate.format(FORMAT.YEAR)}`} to
    ${endDate.format(FORMAT.LONG_2DAY_MONTH_YEAR)}.`;
};

type MealDealDisabled = {
  showAlert: boolean;
  hideBuilder: boolean;
  title?: string;
  cause?: symbol;
  message?: string;
  showGrid: boolean;
  isNowBeforeStartDate: boolean;
  isNowAfterEndDate: boolean;
  isSlotBooked: boolean;
  comingSoonMessage?: string;
};

const mealDealDisabledDefault: MealDealDisabled = {
  showAlert: false,
  hideBuilder: true,
  showGrid: true,
  isNowBeforeStartDate: false,
  isNowAfterEndDate: false,
  isSlotBooked: false,
};

export const getMealDealDisabledState = createSelector(
  getCustomerSlotDate,
  (_: WtrState, today: number) => today,
  getMealDeal,
  (slot: string | undefined, todayMS: number, mealDeal): MealDealDisabled => {
    if (isContentOnlyAvailable(mealDeal))
      return {
        ...mealDealDisabledDefault,
        showGrid: false,
        showAlert: false,
        hideBuilder: true,
      };

    if (isMealDealBranchNotApplicable(mealDeal))
      return {
        ...mealDealDisabledDefault,
        showGrid: false,
        showAlert: true,
        hideBuilder: true,
        cause: INVALID_BRANCH_CAUSE,
        title: 'This offer is unavailable at your store',
        message: 'This offer applies to specific stores, sorry for the inconvenience',
      };

    if (isMealDealSlotInvalid(mealDeal))
      return {
        ...mealDealDisabledDefault,
        showGrid: false,
        showAlert: true,
        hideBuilder: true,
        cause: INVALID_SLOT_CAUSE,
        title: INVALID_SLOT_TITLE,
        message: getInvalidSlotMessage,
      };

    if (!isMealDealSuccessfullyLoaded(mealDeal)) {
      return mealDealDisabledDefault;
    }

    const { endDate, startDate } = mealDeal;
    const todaysDate = dayjs(todayMS).tz();
    const mealDealStartDate = dayjs(startDate).tz();
    const mealDealEndDate = getMealDealEndDate(endDate);

    const isNowBeforeStartDate = todaysDate.isBefore(mealDealStartDate, 'day');
    const isNowSameOrAfterEndDate = todaysDate.isSameOrAfter(mealDealEndDate, 'day');
    const isNowAfterEndDate = todaysDate.isAfter(mealDealEndDate, 'day');

    const slotDate = slot ? dayjs(slot).tz() : null;
    const isSlotBooked = !!slotDate;

    const isSlotAfterStartDate =
      !startDate || !isSlotBooked || slotDate.isSameOrAfter(mealDealStartDate, 'day');
    const isSlotBeforeEndDate =
      !endDate || !isSlotBooked || slotDate.isSameOrBefore(mealDealEndDate, 'day');
    const isSlotValidForMealDeal = isSlotAfterStartDate && isSlotBeforeEndDate;

    const showGrid =
      (!isNowBeforeStartDate && !isNowAfterEndDate) || (isSlotBooked && isSlotValidForMealDeal);

    const endsIn2DaysOrLess = mealDealEndDate
      ? todaysDate.isBefore(mealDealEndDate, 'day') &&
        todaysDate.isSameOrAfter(mealDealEndDate.subtract(2, 'day'), 'day')
      : false;

    const endsToday = mealDealEndDate ? todaysDate.isSame(mealDealEndDate, 'day') : false;

    const invalidSlotBooked = isSlotBooked && !isSlotValidForMealDeal;
    const noSlotAndEndingSoon = !isSlotBooked && (endsIn2DaysOrLess || endsToday);
    const noSlotOrInvalidSlotBooked = !isSlotBooked || invalidSlotBooked;

    const showAlert = invalidSlotBooked || noSlotAndEndingSoon;

    const hideBuilder =
      isNowSameOrAfterEndDate || (isNowBeforeStartDate && noSlotOrInvalidSlotBooked);

    if (!showAlert) {
      return {
        showAlert,
        hideBuilder,
        showGrid,
        isNowBeforeStartDate,
        isNowAfterEndDate,
        isSlotBooked,
        comingSoonMessage: comingSoonMessage(mealDeal),
      };
    }

    let cause: symbol;
    let title: string;
    let message: string;

    if (invalidSlotBooked) {
      cause = INVALID_SLOT_CAUSE;
      title = INVALID_SLOT_TITLE;
      message = getInvalidSlotMessage;
    } else if (endsToday) {
      cause = ENDING_TODAY_CAUSE;
      title = ENDING_TODAY_TITLE;
      message = getEndingTodayMessage;
    } else {
      cause = ENDING_SOON_CAUSE;
      title = ENDING_SOON_TITLE;
      message = getEndingSoonMessage(mealDealEndDate);
    }

    return {
      showAlert,
      cause,
      title,
      message,
      hideBuilder,
      showGrid,
      isNowBeforeStartDate,
      isNowAfterEndDate,
      isSlotBooked,
      comingSoonMessage: comingSoonMessage(mealDeal),
    };
  },
);

export const getTotalCompletedMealDeals = createSelector(
  getMealDealBuilderItemsFromPromotionGroups,
  builderMealDeals => Math.max(0, builderMealDeals.length - 1),
);

export const getMealDealGroupCompletionStatus = createSelector(
  getMealDealBuilderItemsFromPromotionGroups,
  builderMealDeals =>
    builderMealDeals
      .at(-1)
      ?.groups.map(group => {
        const complete = isMealDealGroupComplete(group);

        return `${group.name} ${complete ? 'Complete' : 'Incomplete'}  (${group.groupId} ); `;
      })
      .join(''),
);

export const getMealDealBuilderLineNumbers = createSelector(
  getMealDealBuilderItems,
  mealDealBuilders => [
    ...new Set(
      ([] as MealDealBuilderGroup[])
        .concat(...mealDealBuilders.map(mealDeal => mealDeal.groups))
        .flatMap(group => group.items.map(item => item?.lineNumber))
        .filter(lineNumber => lineNumber),
    ),
  ],
);
