const mealIds = ['194'];

export const justDeal = (mealDealId: string): boolean => mealIds.includes(mealDealId);

// TODO: https://www.jlpit.com/jira/browse/WPIP-59322
export const getMealDealBuilderMessages = (mealDealId: string) => {
  const justDealWording = justDeal(mealDealId);

  return {
    multipleMessage: justDealWording ? 'Create another deal' : 'Create another meal deal',
    initialMessage: justDealWording ? 'Create your deal' : 'Create your meal deal',
    completeMessage: justDealWording ? 'Your deal is complete' : 'Your meal deal is complete',
    location: justDealWording ? 'deal' : 'meal deal',
    warning: justDealWording
      ? 'When two or more items from the same category are added, the cheapest will be included in the deal'
      : 'When two or more items from the same category are added, the cheapest will be included in the meal deal',
  };
};
