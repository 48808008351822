import { rootPath } from 'utils/root-path';

export const Links = {
  title: "Valentine's Day",
  panel1: {
    title: {
      name: '',
    },
    links: [
      {
        name: "Valentine's Day Food & Drink",
        url: rootPath('shop/browse/groceries/valentines_day'),
      },
      {
        name: "Valentine's Day Chocolate & Sweets",
        url: rootPath('shop/browse/groceries/valentines_day/valentines_day_chocolate_and_sweets'),
      },
      {
        name: "Valentine's Day Gifts, Cards & Wrap",
        url: rootPath('shop/browse/groceries/valentines_day/valentines_day_gifts_cards_and_wrap'),
      },
      {
        name: "Valentine's Day Drinks",
        url: rootPath('shop/browse/groceries/valentines_day/valentines_day_drinks'),
      },
    ],
  },
  panel2: {
    title: {
      name: '',
    },
    links: [
      {
        name: "Valentine's Day Recipes",
        url: rootPath('recipes/special-occasion/valentines-day'),
      },
      {
        name: "Valentine's Day Baking Recipes",
        url: rootPath('recipes/special-occasion/valentines-day/valentines-day-baking'),
      },
      {
        name: "Valentine's Day Steak Recipes",
        url: rootPath('recipes/special-occasion/valentines-day/steak'),
      },
    ],
  },
  images: [
    {
      name: "Valentine's Day Meal Deal",
      url: rootPath('shop/featured/meal-deals/150'),
      src: 'https://waitrose-prod.scene7.com/is/image/waitroseprod/13463-Feb24-Seasonal-Dropdown-ValentinesDay-DineIn?$Waitrose-Image-Preset-95$&wid=550&fit=constrain,0',
    },
    {
      name: "Valentine's Day Bubbles & Truffles",
      url: rootPath('shop/featured/bundles/valentines-gift-bundle'),
      src: 'https://waitrose-prod.scene7.com/is/image/waitroseprod/13463-Feb24-Seasonal-Dropdown-ValentinesDay-Bundle?$Waitrose-Image-Preset-95$&wid=550&fit=constrain,0',
    },
    {
      name: "Valentine's Day Flowers",
      url: 'https://www.waitroseflorist.com/valentines-day',
      src: 'https://waitrose-prod.scene7.com/is/image/waitroseprod/13463-Feb24-Seasonal-Dropdown-ValentinesDay-Florist?$Waitrose-Image-Preset-95$&wid=550&fit=constrain,0',
      target: '_blank',
    },
  ],
};
