import { arrayOf, node, oneOfType } from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { useStore } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RedirectToLoginPage } from 'utils/login/RedirectToLoginPage';
import { makeActions } from './action-factories';
import actions from './actions';
import { isAuthorised } from './authorisation';

export const RouteChange = ({ children }) => {
  const store = useStore();
  const { pathname, search } = useLocation();
  const location = useMemo(() => ({ pathname, search }), [pathname, search]); // get stable location
  const runtimeActions = useMemo(() => makeActions({ location, store }), [location, store]);
  const blockRender = runtimeActions.length > 0;

  useEffect(() => {
    runtimeActions.concat(actions).forEach(action => action({ location, store }));
  }, [runtimeActions, location, store]);

  if (!isAuthorised({ location, store })) return <RedirectToLoginPage />;
  if (blockRender || !children) return null;
  return <div>{children}</div>;
};

export default RouteChange;

RouteChange.propTypes = {
  children: oneOfType([arrayOf(node), node]),
};

RouteChange.defaultProps = {
  children: null,
};
