import root from 'window-or-global';
import env from 'env/env';

export const getFeatureFlags = () => root.features || root.defaultFeatures || {};

export const getFeatureFlagsToPassToAPI = () =>
  Object.keys(root.features || {})
    .filter(flag => env.featureFlagsToPassToAPI.includes(flag))
    .reduce(
      (featureFlags, flag) => ({
        ...featureFlags,
        [flag]: root.features[flag],
      }),
      {},
    );

export const featureFlagsToQueryString = featureFlags =>
  Object.keys(featureFlags)
    .reduce(
      (featureFlagsArray, flagName) =>
        featureFlags[flagName] ? featureFlagsArray.concat(flagName) : featureFlagsArray,
      [],
    )
    .join(',');
