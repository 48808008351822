import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { isPaymentPage } from 'constants/payment-pages';
import { getCookies } from 'redux/modules/cookies/selectors';

import AnalyticsScripts from './AnalyticsScripts';
import MarketingScripts from './MarketingScripts';

export const CookiesScripts = memo(() => {
  const { pathname } = useLocation();
  const { marketing, analytics } = useSelector(getCookies);

  const isInPaymentPage = isPaymentPage(pathname);

  if (isInPaymentPage) {
    return null;
  }

  return (
    <>
      {marketing ? <MarketingScripts /> : null}
      {analytics ? <AnalyticsScripts /> : null}
    </>
  );
});
