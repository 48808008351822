import { isDefined } from './validation';

export const hasBrandImage = icons =>
  isDefined(icons) && isDefined(icons.brandIcon) && !!icons.brandIcon.display;

export const hasImage = data => isDefined(data) && isDefined(data.image);

export const hasMessage = icons =>
  isDefined(icons) &&
  isDefined(icons.messageIcon) &&
  !!icons.messageIcon.display &&
  isDefined(icons.messageIcon.data) &&
  isDefined(icons.messageIcon.data.message);

export const hasRoundel = icons =>
  isDefined(icons) && isDefined(icons.offerRoundel) && !!icons.offerRoundel.display;

export const hasTextArea = data => isDefined(data) && isDefined(data.textArea);

export const displayDisclaimer = data => hasTextArea(data) && !!data.textArea.displayDisclaimer;

export const hasCallToAction = data =>
  hasTextArea(data) && isDefined(data.textArea.callToActionURL);

export const hasImageAndTextArea = data => hasImage(data) && hasTextArea(data);

export const hasLink = data => isDefined(data) && isDefined(data.link) && isDefined(data.link.url);
