import React, { ReactNode } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';

import type { Analytics, AnalyticsTags, ChildComponents, CqResponsive } from 'cms-components/types';
import AemComponentGrid from 'components/AemComponentGrid';
import { CONTAINER_NAME } from 'components/AemComponent/analyticsConstants';
import { isInternal } from 'utils/is-internal';
import promoClick from 'analytics/aem/promo-click';
import styles from 'components/Container/index.scss';

export type ContainerProps = {
  analytics?: Analytics;
  analyticsTags: AnalyticsTags;
  backgroundColor?: string;
  childComponents: ChildComponents;
  constrainContent?: boolean;
  cqResponsive: CqResponsive;
  cqResponsiveClasses: string[];
  elementId?: string;
  fullWidth?: boolean;
  linkPath?: string;
  newWindow?: boolean;
  position: string;
  uniqueId: string;
  preloadImages?: boolean;
};

const ContainerComponents = ({
  constrainContent = false,
  childComponents,
  cqResponsive,
  preloadImages = false,
}: {
  constrainContent: boolean;
  childComponents: ChildComponents;
  cqResponsive: CqResponsive;
  preloadImages?: boolean;
}) => {
  if (constrainContent) {
    return (
      <div className="container-fluid">
        <AemComponentGrid
          childComponents={childComponents}
          cqResponsive={cqResponsive}
          preloadImages={preloadImages}
        />
      </div>
    );
  }

  return (
    <AemComponentGrid
      childComponents={childComponents}
      cqResponsive={cqResponsive}
      preloadImages={preloadImages}
    />
  );
};

const Container = ({
  analytics,
  analyticsTags,
  backgroundColor,
  childComponents,
  constrainContent = false,
  cqResponsive,
  cqResponsiveClasses,
  elementId,
  fullWidth = false,
  linkPath,
  newWindow = false,
  position,
  uniqueId,
  preloadImages,
}: ContainerProps) => {
  const props = {
    'data-testid': 'container',
    className: classNames(cqResponsiveClasses, {
      [styles.fullWidth]: fullWidth,
    }),
    id: elementId || uniqueId,
    'data-uniqueid': uniqueId,
    style: { backgroundColor },
  };

  const ContainerWrapper = ({ children }: { children: ReactNode }) => {
    if (linkPath) {
      const promoClickProps = {
        ...analytics,
        creative: CONTAINER_NAME,
        id: uniqueId,
        linkURL: linkPath,
        name: analytics?.promoName,
        newWindow,
        position,
      };

      const linkProps = {
        onClick: () => promoClick(promoClickProps),
        ...props,
        ...analyticsTags,
      };

      if (isInternal(linkPath) && !newWindow) {
        return (
          <TextLink component={Link} to={linkPath} {...linkProps}>
            {children}
          </TextLink>
        );
      }

      return (
        <TextLink href={linkPath} target={newWindow ? '_blank' : undefined} {...linkProps}>
          {children}
        </TextLink>
      );
    }

    return <section {...props}>{children}</section>;
  };

  return (
    <ContainerWrapper>
      <ContainerComponents
        constrainContent={fullWidth && constrainContent}
        childComponents={childComponents}
        cqResponsive={cqResponsive}
        preloadImages={preloadImages}
      />
    </ContainerWrapper>
  );
};

Container.displayName = CONTAINER_NAME;

export default Container;
