import { Component } from 'react';
import PropTypes from 'prop-types';

import { findRouteMatch } from 'route-data/find-route-match';
import { objectWithNumericKeys } from 'utils/prop-types/object-with-numeric-keys';

const isEqualLocation = (
  { pathname: pathname1, search: search1 },
  { pathname: pathname2, search: search2 },
) => pathname1 === pathname2 && search1 === search2;

export default class DataProvider extends Component {
  static propTypes = {
    clearPreloadedIfLocationChanged: PropTypes.func.isRequired,
    clientFetch: objectWithNumericKeys,
    clientPageLoaded: PropTypes.func.isRequired,
    clientPageLoading: PropTypes.func.isRequired,
    fetch: objectWithNumericKeys,
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
      search: PropTypes.string.isRequired,
      state: PropTypes.shape({
        refinement: PropTypes.bool,
      }),
    }).isRequired,
    preloaded: PropTypes.bool,
  };

  componentDidMount() {
    this.fetchRouteData(this.props);
  }

  componentDidUpdate(prevProps) {
    const { location: prevLocation, preloaded: prevPreloaded } = prevProps;
    const { location, preloaded } = this.props;

    if (!isEqualLocation(prevLocation, location) || prevPreloaded !== preloaded) {
      this.fetchRouteData(this.props);
    }
  }

  fetchRouteData(props) {
    const { clearPreloadedIfLocationChanged, location, clientPageLoaded, clientPageLoading } =
      props;

    clearPreloadedIfLocationChanged(location);
    const routeMatch = findRouteMatch(location.pathname);
    if (!routeMatch) {
      clientPageLoaded();
      return;
    }

    const { fetch, clientFetch, index, match } = routeMatch;

    if (!location.state?.refinement) clientPageLoading();

    Promise.resolve(!props.preloaded && fetch ? props.fetch[index]({ location, match }) : null)
      .then(() => (clientFetch ? props.clientFetch[index]({ location, match }) : null))
      .then(clientPageLoaded);
  }

  render() {
    return null;
  }
}
