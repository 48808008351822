import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container } from '@johnlewispartnership/wtr-ingredients/foundations/grid';
import BreadcrumbsWtr from '@johnlewispartnership/wtr-ingredients/ingredients/Breadcrumbs';
import { BreadcrumbLink } from '@johnlewispartnership/wtr-ingredients/ingredients/Breadcrumbs/BreadcrumbLink';
import AnchorLink from 'components/AnchorLink';
import Back from 'components/Breadcrumbs/Back';

import { enrichBreadcrumbs } from 'utils/breadcrumbs';
import urls from 'constants/urls';

import styles from './index.scss';

const Breadcrumbs = ({ backTo, breadcrumbs, baseUrl, white, backgroundWhite, className }) => {
  const enrichedBreadcrumbs = enrichBreadcrumbs({ baseUrl, breadcrumbs });

  return (
    <Container
      className={classNames(styles.container, {
        [styles.white]: white,
        [styles.hide]: enrichedBreadcrumbs.length < 2,
        [styles.backgroundWhite]: backgroundWhite,
      })}
    >
      <BreadcrumbsWtr className={className} light={white}>
        <Back to={backTo} />
        <BreadcrumbLink component={AnchorLink} to={urls.homepage}>
          Home
        </BreadcrumbLink>
        {enrichedBreadcrumbs.map(({ name, path }) => (
          <BreadcrumbLink component={AnchorLink} to={path} key={path}>
            {name}
          </BreadcrumbLink>
        ))}
      </BreadcrumbsWtr>
    </Container>
  );
};

Breadcrumbs.propTypes = {
  baseUrl: PropTypes.string,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      path: PropTypes.string,
    }),
  ),
  backTo: PropTypes.func,
  white: PropTypes.bool,
  backgroundWhite: PropTypes.bool,
  className: PropTypes.string,
};

Breadcrumbs.defaultProps = {
  breadcrumbs: null,
  baseUrl: undefined,
  backTo: undefined,
  white: false,
  backgroundWhite: false,
  className: undefined,
};

Breadcrumbs.displayName = 'Breadcrumbs';

export default Breadcrumbs;
