import React from 'react';
import Spinner from '@johnlewispartnership/wtr-ingredients/ingredients/Spinner';

/*
  Until this is agreed/designed, we will just render a transparent span to hold the space
  The Skeleton component can be used whilst a component is loading to reduce CLS
*/
interface SkeletonProps extends React.ComponentProps<'span'> {
  component?: React.ElementType;
  height?: string;
  width?: string;
  withSpinner?: boolean;
  spinnerPosition?: 'top' | 'center';
  backgroundColor?: string;
}

const Skeleton: React.FC<SkeletonProps> = ({
  component,
  height = '1rem',
  width = '100%',
  withSpinner = false,
  spinnerPosition = 'center',
  children,
  backgroundColor,
  ...rest
}) => {
  const Component = component || 'span';

  return (
    <Component
      {...(!rest.className && {
        style: {
          height,
          width,
          display: 'flex',
          alignItems: spinnerPosition === 'top' ? 'flex-start' : 'center',
          justifyContent: 'center',
          padding: '100px',
          backgroundColor,
        },
      })}
      {...rest}
    >
      {withSpinner ? <Spinner isActive /> : null}
      {children}
    </Component>
  );
};

export default Skeleton;
