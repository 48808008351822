import { createHistory } from 'utils/create-history';
import { findRouteMatch } from 'route-data/find-route-match';

export const fetchOnServer = (req, { dispatch }) => {
  const routeMatch = findRouteMatch(req.path);
  if (routeMatch && routeMatch.fetch) {
    const history = createHistory(req.url);
    const { match } = routeMatch;
    const { location } = history;
    return dispatch(routeMatch.fetch({ location, match }));
  }
  return Promise.resolve();
};
