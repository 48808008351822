import { setClickContext } from 'utils/clickContext';

export const CLICK_TO_BUY_CONTEXT_TYPE = 'clickToBuy';

// based on src/components/ClickToBuy/ClickToBuyInner.js
const isClickToBuyWrapper = elem => elem.dataset?.testid === 'click-to-buy-component';

const makeClickToBuyContext = elem => ({
  type: CLICK_TO_BUY_CONTEXT_TYPE,
  aemPromotion: {
    type: elem.dataset.analyticsCreative,
    // promo name is in trading-cell child component
    name: elem.querySelector('[data-testid="trading-cell"]')?.dataset?.analyticsName,
  },
});

export function setClickToBuyContext(e) {
  if (isClickToBuyWrapper(e.currentTarget))
    setClickContext(makeClickToBuyContext(e.currentTarget), e);
}
