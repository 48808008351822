import Modal from 'components/Modal';
import React, { FC } from 'react';
import { Modal as IngredientsModal } from '@johnlewispartnership/wtr-ingredients/ingredients/Modal';

export type ModalSelectorProps = {
  useIngredientsModal?: boolean;
  isOpen: boolean;
  closeModal?: () => void;
  [x: string]: any;
};

const ModalSelector: FC<ModalSelectorProps> = ({ useIngredientsModal = true, isOpen, ...rest }) => {
  if (!isOpen) return null;

  return useIngredientsModal ? <IngredientsModal isOpen {...rest} /> : <Modal isOpen {...rest} />;
};

export default ModalSelector;
