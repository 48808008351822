import React from 'react';
import PropTypes from 'prop-types';

import HorizontalSlider from 'components/MultiSearch/HorizontalSlider';
import MultiSearchButton from 'components/MultiSearch/Button';
import SkipLink from 'components/SkipLink';

import styles from './MultiSearchStrip.scss';

const MultiSearchStrip = ({ current, navigateToMultiSearch, values }) =>
  values && values.length ? (
    <div className={styles.outer}>
      <div className="container-fluid">
        <SkipLink destination="#main">Skip to main content</SkipLink>
        <HorizontalSlider resetKey={JSON.stringify(values)}>
          <ol className={styles.strip} data-testid="multi-search-strip">
            {values.map(value => (
              <li className={styles.value} key={value}>
                <MultiSearchButton
                  onClick={() => navigateToMultiSearch(value, { preserveParams: true })}
                  selected={value === current}
                  value={value}
                />
              </li>
            ))}
          </ol>
        </HorizontalSlider>
      </div>
    </div>
  ) : null;

MultiSearchStrip.defaultProps = {
  current: null,
  values: null,
};

MultiSearchStrip.propTypes = {
  current: PropTypes.string,
  navigateToMultiSearch: PropTypes.func.isRequired,
  values: PropTypes.arrayOf(PropTypes.string),
};

export default MultiSearchStrip;
