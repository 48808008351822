import React from 'react';

import { links } from 'constants/specialistShopsLinks';

import SpecialistShopLink from 'components/SpecialistShopsLinks/SpecialistShopLink';

import styles from './SpecialistShopsLinks.scss';

const SpecialistShopsLinks = () => (
  <div className={styles.wrapper} data-testid="specialist-shop-links">
    <span className={styles.legend}>More from Waitrose:</span>
    {links.map(link => (
      <SpecialistShopLink link={link} key={`specialistShops-desktop-${link.name}-link`} />
    ))}
  </div>
);

SpecialistShopsLinks.displayName = 'SpecialistShopsLinks';

export default SpecialistShopsLinks;
