import { Response } from 'superagent';
import { trackPersonalisation } from 'analytics/personalisation-tracking';
import { canShowRecommended } from 'components/RecommendedCarousel/canShowRecommended';
import { getPersonalisationIdsAndSaveToCookie } from './get-personalisation-ids-and-save-to-cookie';
import { METADATA_HEADER_KEY, parseMetadataHeader } from './metadata-header';

export interface Personalisation {
  customer_id: string;
  monetate_id: string;
  [personalisationKey: string]: string;
}

const withPersonalisationHeader = (
  res: Pick<Response, 'header'>,
  cb: ({ personalisation }: { personalisation: Personalisation }) => void,
) => {
  const personalisationHeader = res.header?.[METADATA_HEADER_KEY];

  if (!personalisationHeader) {
    return;
  }

  const personalisationMetadata = parseMetadataHeader(personalisationHeader);
  const { monetateId, customerId } = getPersonalisationIdsAndSaveToCookie(personalisationMetadata);

  cb({
    personalisation: {
      ...personalisationMetadata,
      customer_id: customerId,
      monetate_id: monetateId,
    },
  });
};

export const handlePersonalisationHeader = (res: Pick<Response, 'header'>) => {
  withPersonalisationHeader(res, trackPersonalisation);
};

export const handlePersonalisationHeaderWithPath =
  (path: string) => (res: Pick<Response, 'header' | 'body'>) => {
    withPersonalisationHeader(res, ({ personalisation }) => {
      const { products: recommendations = [], fallbackRecommendations: fallback = false } =
        res?.body?.data?.[path]?.recommendedProducts || {};

      trackPersonalisation({
        personalisation,
        otherTrackingValues: {
          is_carousel_visible: canShowRecommended(recommendations) ? 'YES' : 'NO',
        },
        fallback,
      });
    });
  };
