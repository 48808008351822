export const MIDDLE_MEGA_MENU = {
  key: 'menu/middle-mega-menu/master',
  location: 'middleMegaMenu',
};

export const SEASONAL_MENU = {
  key: 'menu/seasonal-menu/seasonal-menu-content/master',
  location: 'seasonalMenu',
};

export const SITE_WIDE_BANNER = {
  key: 'Site-Wide-Banner/Site-Banner-Content/master',
  location: 'siteWideBannerContent',
};
