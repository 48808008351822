import history from 'utils/history';
import urls from 'constants/urls';
import locator from 'utils/locator';
import { getFeatureFlags } from 'utils/feature-flags';

export const onLoginPage = () => locator.pathname === urls.login;

export const loginPagePath = () => {
  const url = getFeatureFlags().identity_enableOAuth2Web ? urls.loginOAuth2 : urls.login;

  return `${url}?redirect=${encodeURIComponent(locator.pathname + locator.search)}`;
};

export const registrationPagePath = () => {
  return getFeatureFlags().identity_enableOAuth2Web ? urls.registrationOAuth2 : urls.registration;
};

// use only in event handlers / effects
// in render phase use RedirectToLoginPage
export const redirectToLogin = () => {
  if (getFeatureFlags().identity_enableOAuth2Web) {
    locator.href = loginPagePath();
  } else {
    history.push(loginPagePath());
  }
};
