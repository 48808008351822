import update from 'immutability-helper';
import extractNextPageStartIndex from 'redux/modules/orders/reducers/extract-next-page-start-index';

import { initialState } from 'redux/modules/orders/state';
import {
  CANCEL_ORDER_FAILED,
  CANCEL_ORDER_LOADED,
  CANCEL_ORDER_LOADING,
  CLEAR_CUSTOMER_ORDER_STATS,
  FETCH_CUSTOMER_ORDER_STATS_LOADED,
  GET_COMPLETED_ORDERS_FAILED,
  GET_COMPLETED_ORDERS_LOADED,
  GET_COMPLETED_ORDERS_LOADING,
  GET_PENDING_ORDERS_FAILED,
  GET_PENDING_ORDERS_LOADED,
  GET_PENDING_ORDERS_LOADING,
  GET_PREVIOUS_ORDERS_FAILED,
  GET_PREVIOUS_ORDERS_LOADED,
  GET_PREVIOUS_ORDERS_LOADING,
  GET_RECENT_ORDERS,
} from 'redux/modules/orders/actions/types';

export default function orders(state = initialState, action = {}) {
  const { type } = action;

  switch (type) {
    case CANCEL_ORDER_FAILED: {
      const { customerOrderId } = action;

      return {
        ...state,
        cancellationsInProgress: state.cancellationsInProgress.filter(id => id !== customerOrderId),
      };
    }
    case CANCEL_ORDER_LOADED: {
      const { customerOrderId, result: { errors, data: { cancelOrder } = {} } = {} } = action;

      if (errors || cancelOrder === undefined || cancelOrder?.failures) {
        return {
          ...state,
          cancellationsInProgress: state.cancellationsInProgress.filter(
            id => id !== customerOrderId,
          ),
        };
      }

      return {
        ...state,
        cancellationsInProgress: state.cancellationsInProgress.filter(id => id !== customerOrderId),

        pendingOrders: {
          ...state.pendingOrders,
          orderIds: state.pendingOrders.orderIds.filter(id => id !== customerOrderId),
        },

        previousOrders: {
          ...state.previousOrders,
          orderIds: [...state.previousOrders.orderIds, customerOrderId],
        },
      };
    }
    case CANCEL_ORDER_LOADING: {
      const { customerOrderId } = action;

      return {
        ...state,
        cancellationsInProgress: update(state.cancellationsInProgress, {
          $push: [customerOrderId],
        }),
      };
    }

    case GET_COMPLETED_ORDERS_LOADING: {
      return { ...state, completedOrders: { ...state.completedOrders, loading: true } };
    }
    case GET_COMPLETED_ORDERS_FAILED: {
      return { ...state, completedOrders: { ...state.completedOrders, loading: false } };
    }
    case GET_COMPLETED_ORDERS_LOADED: {
      const { content = [] } = action.result;
      return {
        ...state,
        completedOrders: {
          loading: false,
          orderIds: [...new Set([...state.completedOrders.orderIds, ...content])],
        },
      };
    }

    case GET_PENDING_ORDERS_LOADING: {
      return { ...state, pendingOrders: { ...state.pendingOrders, loading: true } };
    }
    case GET_PENDING_ORDERS_FAILED: {
      return { ...state, pendingOrders: { ...state.pendingOrders, loading: false } };
    }
    case GET_PENDING_ORDERS_LOADED: {
      const { content = [], links = [] } = action.result;
      const next = links.find(({ rel }) => rel === 'next');
      return {
        ...state,
        pendingOrders: {
          loading: false,
          orderIds: [...new Set([...state.pendingOrders.orderIds, ...content])],
          start: extractNextPageStartIndex(next),
        },
      };
    }

    case GET_PREVIOUS_ORDERS_LOADING: {
      return { ...state, previousOrders: { ...state.previousOrders, loading: true } };
    }
    case GET_PREVIOUS_ORDERS_FAILED: {
      return { ...state, previousOrders: { ...state.previousOrders, loading: false } };
    }
    case GET_PREVIOUS_ORDERS_LOADED: {
      const { content = [], links = [] } = action.result;
      const next = links.find(({ rel }) => rel === 'next');

      return {
        ...state,
        previousOrders: {
          loading: false,
          orderIds: [...new Set([...state.previousOrders.orderIds, ...content])],
          start: extractNextPageStartIndex(next),
        },
      };
    }

    case GET_RECENT_ORDERS.request: {
      return {
        ...state,
        recentOrders: {
          loading: true,
          orderIds: [],
        },
      };
    }
    case GET_RECENT_ORDERS.failure: {
      return {
        ...state,
        recentOrders: {
          loading: false,
          orderIds: [],
        },
      };
    }
    case GET_RECENT_ORDERS.success: {
      const { content = [] } = action.result;

      return {
        ...state,
        recentOrders: {
          loading: false,
          orderIds: [...new Set([...state.recentOrders.orderIds, ...content])],
        },
      };
    }

    case FETCH_CUSTOMER_ORDER_STATS_LOADED: {
      const { customerHasACompletedOrder, daysSinceLastCompletedOrder } = action.result;

      return {
        ...state,
        userStats: { customerHasACompletedOrder, daysSinceLastCompletedOrder },
      };
    }
    case CLEAR_CUSTOMER_ORDER_STATS:
      return {
        ...state,
        userStats: undefined,
      };
    default:
      return state;
  }
}
