import update from 'immutability-helper';
import { deepFreeze } from 'utils/deepFreeze';

import { LOCATION_CHANGE } from 'connected-react-router';

import extractLineNumberFromSku from 'utils/extract-linenumber-from-sku';

import {
  SHOPPING_LIST_DELETED,
  SHOPPING_LIST_EDIT_NAME,
  SHOPPING_LIST_EDIT_NAME_CANCELLED,
  SHOPPING_LIST_FULFILLED,
  SHOPPING_LIST_PENDING,
  SHOPPING_LIST_PRODUCTS_FULFILLED,
  SHOPPING_LIST_PRODUCTS_PENDING,
  SHOPPING_LIST_REJECTED,
  SHOPPING_LIST_UPDATE_NAME,
  SHOPPING_LIST_TOGGLE_MEMBERSHIP,
} from 'redux/modules/shopping-list/actions/types';

const initialState = deepFreeze({
  id: null,
  editingName: false,
  error: false,
  errorResponse: null,
  items: [],
  loading: false,
  productsLoading: false,
});

export default function shoppingList(state = initialState, action = {}) {
  switch (action.type) {
    case SHOPPING_LIST_DELETED:
      return {
        ...state,
        loading: false,
      };
    case SHOPPING_LIST_EDIT_NAME:
      return {
        ...state,
        editingName: true,
      };
    case LOCATION_CHANGE:
    case SHOPPING_LIST_EDIT_NAME_CANCELLED:
      return {
        ...state,
        editingName: false,
      };
    case SHOPPING_LIST_FULFILLED:
      return {
        ...state,
        id: action.result,
        error: false,
        loading: false,
      };
    case SHOPPING_LIST_PENDING:
      return {
        ...state,
        id: null,
        error: false,
        errorResponse: null,
        items: [],
        loading: true,
      };
    case SHOPPING_LIST_PRODUCTS_FULFILLED: {
      const {
        lineNumbers = [],
        result: { products = [] },
      } = action;

      const mappedProducts = lineNumbers
        .map(lineNumber =>
          products.find(product => extractLineNumberFromSku(product.searchProduct) === lineNumber),
        )
        .filter(product => product);

      return {
        ...state,
        items: update(state.items, { $push: mappedProducts }),
        productsLoading: false,
      };
    }
    case SHOPPING_LIST_PRODUCTS_PENDING:
      return {
        ...state,
        productsLoading: true,
      };
    case SHOPPING_LIST_REJECTED:
      return {
        ...state,
        error: true,
        errorResponse: action.error,
        loading: false,
      };
    case SHOPPING_LIST_UPDATE_NAME:
      return {
        ...state,
        editingName: false,
      };
    case SHOPPING_LIST_TOGGLE_MEMBERSHIP: {
      const { lineNumber } = action.payload;
      const { items } = state;

      const nextItems = items.filter(item => item?.searchProduct !== lineNumber);

      return update(state, {
        items: {
          $set: nextItems,
        },
      });
    }
    default:
      return state;
  }
}
