import urls from 'constants/urls';

export const pagesWithCheckoutScaffolding = [
  {
    url: '/ecom/checkout',
  },
  {
    url: '/ecom/resolve-order-payment/',
    title: 'Payment',
  },
];

export const pagesWithMinimalScaffolding = [
  {
    url: '/ecom/registration/confirmation',
  },
  {
    url: '?changeslot',
  },
  {
    url: urls.forgotPassword,
  },
  {
    url: urls.login,
  },
  {
    url: urls.registrationSuccess,
  },
  {
    url: urls.registration,
    showMinimalHeaderSignIn: true,
  },
  {
    url: urls.resetPassword,
  },
  {
    url: urls.logout,
  },
];

export const pagesWithoutAmendBar = [
  ...pagesWithMinimalScaffolding,
  {
    url: urls.orderConfirmation,
  },
  {
    url: urls.paymentConfirmationPage,
  },
];

export const pagesWithSpinnerOnlyScaffolding = [
  // the following URL is no longer used, but the comment is here for future reference
  // {
  //   url: '/ecom/some/url',
  // },
];
