import PropTypes from 'prop-types';

import { DynamicImagesType } from './image';

export const iconImageShape = {
  fileReference: PropTypes.string,
  altText: PropTypes.string,
  dynamicImages: DynamicImagesType,
};

export const IconImageType = PropTypes.shape({ ...iconImageShape });

export const iconShape = {
  display: PropTypes.bool,
  image: IconImageType,
};
