import React from 'react';
import { extractInlineStyles } from '../utils';
import styles from '../RichText.scss';
import { RichTextNode } from '../types';

const RichTextUnorderedList = ({ children, value }: RichTextNode) => (
  <ul className={styles.richTextList} style={extractInlineStyles(value)}>
    {children}
  </ul>
);

RichTextUnorderedList.displayName = 'RichTextUnorderedList';

export default RichTextUnorderedList;
