import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import ChevronDown from '@johnlewispartnership/wtr-ingredients/dist/Icon/ChevronDown';

import style from './index.scss';

function CarouselExpansionButton({ setIsOpen, isOpen }) {
  return (
    <div
      className={classNames('visible-xs-block', style.hide, style.buttonWrapper, {
        [style.buttonWrapperOpen]: isOpen,
      })}
    >
      <button
        aria-label="Click to show associated products"
        className={classNames(style.roundButton, { [style.roundButtonOpen]: isOpen })}
        data-testid="carousel-expansion-button"
        type="button"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        <ChevronDown />
      </button>
    </div>
  );
}

CarouselExpansionButton.propTypes = {
  setIsOpen: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

export default CarouselExpansionButton;
