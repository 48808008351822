import { push } from 'connected-react-router';

import urls from 'constants/urls';
import { getFeatureFlags } from 'utils/feature-flags';
import getOrderWithRecommendations from './get-order-with-recommendations';

const getOrder =
  ({ customerOrderId, iHandleStatusCodes = undefined, pageType = undefined }) =>
  dispatch => {
    const { getGraphqlOrderWithRecs } = getFeatureFlags();
    const failure = error => {
      if (['403', '404'].includes(error.message?.slice(0, 3)))
        return dispatch(push(urls.myOrdersPage));
      throw error;
    };

    return dispatch(
      getOrderWithRecommendations({
        customerOrderId,
        handleError: iHandleStatusCodes && failure,
        recommendationsSize: getGraphqlOrderWithRecs && pageType === 'shop-from-order' ? 8 : 0,
      }),
    );
  };

export default getOrder;
