import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import urls from 'constants/urls';
import { getFeatureFlags } from 'utils/feature-flags';
import { loginPagePath, registrationPagePath } from 'utils/login';
import { User } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';

import styles from './SignIn.scss';

const SignIn = ({ className, loggedIn }) => {
  const loginLinkProps = getFeatureFlags().identity_enableOAuth2Web
    ? { href: loginPagePath() }
    : { component: Link, to: urls.login };

  const registrationLinkProps = getFeatureFlags().identity_enableOAuth2Web
    ? { href: registrationPagePath() }
    : { component: Link, to: urls.registration };

  return !loggedIn ? (
    <>
      <TextLink
        {...loginLinkProps}
        className={className}
        data-test="loginAnchor"
        underline="underline"
      >
        <User className={styles.signInIcon} />
        Sign in
      </TextLink>
      <TextLink
        {...registrationLinkProps}
        className={className}
        data-test="registerAnchor"
        underline="underline"
      >
        Register
      </TextLink>
    </>
  ) : null;
};

SignIn.propTypes = {
  className: PropTypes.string,
  loggedIn: PropTypes.bool.isRequired,
};

SignIn.defaultProps = {
  className: '',
};

SignIn.displayName = 'SignIn';

export default SignIn;
