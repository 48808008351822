import React from 'react';

import CmsErrorBoundary from 'cms-components/CmsErrorBoundary';
import { log } from 'utils/error-logging';

import { progressiveGridSizing } from 'utils/gridUtils';

import getCMSAnalyticsTags from 'analytics/get-cms-analytics-tags';
import aemComponentMap, { AemComponentMapProps } from './aem-component-map';

interface AemComponentProps {
  component: any; // Should be AemResponseData
  position?: string;
  index?: number;
  preloadImages?: boolean;
}

const AemComponent = ({ component, position, index, preloadImages = false }: AemComponentProps) => {
  if (!component) {
    return null;
  }

  const { component: Component, analyticsName } =
    aemComponentMap.get(component?.resourceType) || ({} as AemComponentMapProps);

  if (!Component) {
    log(`No mapped component for resource type: ${component.resourceType}`, {
      logger: 'aem',
      section: 'AemComponent',
      extra: {
        resourceType: component.resourceType,
        componentId: component.componentId,
      },
    });
    return null;
  }

  const analyticsTags = analyticsName
    ? getCMSAnalyticsTags(component.componentId, component.analytics, analyticsName, position)
    : undefined;

  // Compute the appropriate classes based on CqResponsive.
  // Often the stubs are incomplete and therefore the catch is mostly in effect on stubs
  let cqResponsiveClasses;
  try {
    cqResponsiveClasses = progressiveGridSizing([], component.cqResponsive, 12, true);
  } catch (err) {
    cqResponsiveClasses = '';
  }

  /*

    BODGE:
    These styles in src/styles/base/_proportional-grid.scss cause quite a few issues, the primary problem is that
    this selector means the order of the classes matters (the selector below checks what the className starts with)

    [class^='col-'] {
      flex: 0 0 100%;
      float: none;
      width: auto;
    }

    Changing or adapting this class is likely to have wide ranging and potentially unforseen consequences, to allow this branch to progress,
    I'm going to insert a dumb class at the start of the classes on every AEM component to prevent the above selector interacting with the Aem components.

    Once the above css (and anything like it) is gone, this bodge can be removed.

  */

  const cqResponsiveClassesWithBodge = ['aem-grid-blocker-class', ...cqResponsiveClasses];

  const componentProperties = {
    ...component,
    analyticsTags,
    cqResponsiveClasses: cqResponsiveClassesWithBodge,
    position,
    index,
    preloadImages,
  };

  return (
    <CmsErrorBoundary>
      <Component {...componentProperties} />
    </CmsErrorBoundary>
  );
};

export default AemComponent;
