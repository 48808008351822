export const COOKIE_CONSENT = 'wtr_cookie_consent';
export const COOKIE_ADVERTISING = 'wtr_cookies_advertising';
export const COOKIE_ANALYTICS = 'wtr_cookies_analytics';
export const COOKIE_FUNCTIONAL = 'wtr_cookies_functional';
export const COOKIE_DATALAYER = 'gdpr_consent';

export const COOKIE_EXPERIMENTAL_UI = 'mt.experimentalUI';
export const COOKIE_MONETATE_ID = 'mt.v';

export const COOKIE_APP_BANNER_DISMISS = 'wtr_app_banner_dismiss';
export const COOKIE_PRODUCT_RECOMMENDATIONS_DECISION_MEMOIZE_ID =
  'wtr_product_recommendations_decision_memoize_id';
export const COOKIE_CUSTOMER_ID = 'customerId';
export const COOKIE_BRANCH_ID = 'branchId';
