import { BREAKPOINTS } from 'constants/grid';

/*
  160,
  320,
  400,
  450,
  544,
  600,
  680,
  768,
  880,
  992,
  1200,
  1500,
  1800,
  2100,
  2400,
  2800,
  3200,
  4000,
*/

export const SM_IMAGE_WIDTH_SIZES = [160, 320, 544];
export const MD_IMAGE_WIDTH_SIZES = [768, 992, 1200];
export const LG_IMAGE_WIDTH_SIZES = [1500, 1800, 2400];

export const ALL_IMAGE_WIDTH_SIZES = [
  ...SM_IMAGE_WIDTH_SIZES,
  ...MD_IMAGE_WIDTH_SIZES,
  ...LG_IMAGE_WIDTH_SIZES,
];

export const BREAKPOINT_MAP = {
  xs: [0, BREAKPOINTS.sm],
  sm: [BREAKPOINTS.sm, BREAKPOINTS.md],
  md: [BREAKPOINTS.md, BREAKPOINTS.lg],
  lg: [BREAKPOINTS.lg, BREAKPOINTS.xl],
  default: [BREAKPOINTS.xl, undefined],
};
