import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { showContactAddressNotPresentNotification as getShowContactAddressNotPresentNotification } from 'redux/modules/address/selectors/get-address-selectors';

import { pushMenuClick } from 'utils/gtm';

import urls from 'constants/urls';

import { dataLayer } from 'analytics/data-layer';
import { getPathName } from 'redux/modules/slot-booking/selectors/get-fulfilment-type';

import AnchorLink from 'components/AnchorLink';
import {
  ChevronDown,
  Document,
  DocumentAdd,
  HeartInactive,
  MyWaitrose,
  User,
  Warning,
} from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import SignOutButton from 'components/AuthenticationAction/SignOut/Button';

import { useOnClickOutside } from 'hooks';

import styles from './MyAccount.scss';

export const myAccountLabels = {
  myWaitroseLoyalty: 'myWaitrose loyalty',
  lists: 'Lists',
  myDetails: 'My details',
  myOrders: 'My orders',
  favourites: 'Favourites',
  signOut: 'Sign out',
};

const logAnalytics = (button, showContactAddressNotPresentNotification) => {
  const getDataLayerCAText = notificationPresent => {
    if (notificationPresent) {
      switch (button) {
        case 'Header':
          return 'YES: Red Badge';
        case myAccountLabels.myDetails:
          return 'YES: Red Exclamation';
        default:
          return undefined;
      }
    }
    return undefined;
  };

  dataLayer.push({
    event: 'account_menu_click',
    my_account_menu: {
      button,
    },
    prompt: {
      contact_address: getDataLayerCAText(showContactAddressNotPresentNotification),
    },
    temporary: true,
  });
};

const MyAccount = ({ homePagePlacement }) => {
  const currentPageIsGroceriesHomePage = getPathName() === urls.groceriesHome;
  const showContactAddressNotPresentNotification = useSelector(
    getShowContactAddressNotPresentNotification,
  );
  const [hidden, setHidden] = useState(true);
  const insideRef = useRef();

  const toggleVisibility = useCallback(() => {
    setHidden(!hidden);
  }, [hidden]);

  const handleButtonClick = () => {
    if (hidden) {
      logAnalytics('Header', showContactAddressNotPresentNotification);
    }
    toggleVisibility();
  };

  useOnClickOutside(insideRef, () => {
    if (!hidden) {
      setHidden(true);
    }
  });

  const handleBlur = useCallback(() => {
    if (!hidden) {
      toggleVisibility();
    }
  }, [hidden, toggleVisibility]);

  const handleFocus = useCallback(() => {
    if (hidden) {
      toggleVisibility(false);
    }
  }, [hidden, toggleVisibility]);

  const handleLinkClick = useCallback(
    button => {
      toggleVisibility();
      pushMenuClick(`My Account: ${button}`);
      logAnalytics(button, showContactAddressNotPresentNotification);
    },
    [showContactAddressNotPresentNotification, toggleVisibility],
  );

  useEffect(() => {
    if (showContactAddressNotPresentNotification && currentPageIsGroceriesHomePage) {
      dataLayer.push({
        event: 'contact_address_red_badge_shown',
        temporary: true,
      });
    }
  }, [currentPageIsGroceriesHomePage, showContactAddressNotPresentNotification]);

  return (
    <nav
      aria-labelledby="myAccountAuthenticated"
      ref={insideRef}
      className={styles.accountActionWrapper}
      id="authenticated"
      data-testid="my-account-menu"
    >
      <button
        aria-controls="account-actions"
        aria-expanded={!hidden}
        className={classNames(styles.button, homePagePlacement ? styles.buttonHome : '')}
        data-testid="my-account-button"
        id="account-details"
        onClick={handleButtonClick}
        tabIndex="-1"
        type="button"
      >
        <span
          className={classNames(styles.iconWrapper, {
            [styles.CANotPresent]: showContactAddressNotPresentNotification,
          })}
          data-testid="my-account-icon-wrapper"
        >
          <User className={styles.buttonIcon} />
        </span>
        <span className={homePagePlacement ? styles.buttonText : ''} id="myAccountAuthenticated">
          My account
        </span>
        <ChevronDown className={styles.chevronIcon} size="xsmall" />
      </button>
      <div className={classNames(styles.accountPopup, { 'sr-only': hidden })}>
        <ul className={styles.accountActions} id="account-actions">
          <li>
            <AnchorLink
              data-testid="my-details-link"
              id="my-details"
              onBlur={handleBlur}
              onClick={() => handleLinkClick(myAccountLabels.myDetails)}
              onFocus={handleFocus}
              to={urls.myDetailsPage}
            >
              <User className={styles.accountMenuIcon} size="medium" />
              {myAccountLabels.myDetails}
              {showContactAddressNotPresentNotification && (
                <Warning data-testid="warningIcon" className={styles.iconNoCAWarning} />
              )}
            </AnchorLink>
          </li>
          {homePagePlacement && (
            <>
              <li>
                <AnchorLink
                  id="site-header-my-orders"
                  to={urls.myOrdersPage}
                  data-testid="site-header-my-orders"
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  onClick={() => handleLinkClick(myAccountLabels.myOrders)}
                >
                  <Document className={classNames(styles.accountMenuIcon, styles.documentNormal)} />
                  {myAccountLabels.myOrders}
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  id="site-header-favourites"
                  to={urls.favourites}
                  data-testid="site-header-favourites"
                  onBlur={handleBlur}
                  onFocus={handleFocus}
                  onClick={() => handleLinkClick(myAccountLabels.favourites)}
                >
                  <HeartInactive className={styles.accountMenuIcon} />
                  {myAccountLabels.favourites}
                </AnchorLink>
              </li>
            </>
          )}
          <li>
            <AnchorLink
              data-testid="lists-link"
              onBlur={handleBlur}
              onClick={() => handleLinkClick(myAccountLabels.lists)}
              onFocus={handleFocus}
              to={urls.lists}
            >
              <DocumentAdd className={styles.accountMenuIcon} size="medium" />
              {myAccountLabels.lists}
            </AnchorLink>
          </li>
          {!homePagePlacement && (
            <li>
              <AnchorLink
                data-testid="my-waitrose-link"
                onBlur={handleBlur}
                onClick={() => handleLinkClick(myAccountLabels.myWaitroseLoyalty)}
                onFocus={handleFocus}
                to={urls.myWaitrosePage}
              >
                <MyWaitrose className={styles.accountMenuIcon} size="medium" />
                {myAccountLabels.myWaitroseLoyalty}
              </AnchorLink>
            </li>
          )}
          <li className={styles.signOut}>
            <SignOutButton
              data-testid="account-sign-out"
              className={styles.signOutButton}
              onClick={() => handleLinkClick(myAccountLabels.signOut)}
              onBlur={handleBlur}
              onFocus={handleFocus}
              width="full"
            />
          </li>
        </ul>
      </div>
    </nav>
  );
};

MyAccount.displayName = 'MyAccount';

MyAccount.propTypes = {
  homePagePlacement: PropTypes.bool,
};

MyAccount.defaultProps = {
  homePagePlacement: false,
};

export default MyAccount;
