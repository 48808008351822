import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { reducer as form } from 'redux-form';
import get from 'lodash/get';

import accounts from 'redux/modules/accounts/reducers';
import addAllItems from 'redux/modules/add-all-items/reducer';
import addresses from 'redux/modules/address/reducers';
import addressSuggestion from 'redux/modules/address-suggestion/reducers';
import amendOrder from 'redux/modules/amend-order/reducer';
import appleWallet from 'redux/modules/apple-wallet/reducers';
import autoSuggest from 'redux/modules/auto-suggest/reducer';
import branches from 'redux/modules/branches/reducers';
import browserInfo from 'redux/modules/browserInfo/reducers';
import bundle from 'redux/modules/bundle/reducers';
import checkout from 'redux/modules/checkout/reducer';
import cms from 'redux/modules/cms/reducer';
import conflicts from 'redux/modules/conflicts/reducer';
import content from 'redux/modules/content/reducer';
import cookies from 'redux/modules/cookies/reducer';
import customerAddress from 'redux/modules/customer-address/reducers';
import cmsHybridRecipesPage from 'redux/modules/cms-hybrid-recipes-page/reducers';
import mealPlanner from 'redux/modules/meal-planner/reducers';
import cmsPage from 'redux/modules/cms-page/reducers';
import customerServiceReduxForm from 'redux/modules/customer-service/reducers/customer-service-forms';
import productIssueForm from 'redux/modules/customer-service/reducers/product-issues-form';
import customerSlot from 'redux/modules/customer-slot/reducers';
import entities from 'redux/modules/entities/reducer';
import experiments from 'redux/modules/experiments/reducer';
import experienceFragments from 'redux/modules/experience-fragments/reducer';
import favourites from 'redux/modules/favourites/reducer';
import favouritesExperience from 'redux/modules/favourites/reducer/newIndex';
import favouritesProducts from 'redux/modules/favourites-products/reducer';
import forgotPassword from 'redux/modules/forgot-password/reducer';
import header from 'redux/modules/header/reducer';
import incentiveOfferPropositions from 'redux/modules/incentive-offer-propositions/reducer';
import incentives from 'redux/modules/incentives/reducer';
import instantCheckout from 'redux/modules/instant-checkout/reducer';
import interstitials from 'redux/modules/interstitials/reducer';
import login from 'redux/modules/login/reducers';
import marketingPreferences from 'redux/modules/marketing-preferences/reducers';
import mealDeals from 'redux/modules/meal-deals/reducer';
import strategicMealDeals from 'redux/modules/meal-deals/reducer/newIndex';
import megaMenu from 'redux/modules/mega-menu/reducer';
import missedOffers from 'redux/modules/missed-offers/reducer';
import modal from 'redux/modules/common-modal/reducer';
import multisearch from 'redux/modules/multi-search/reducer';
import myAccount from 'redux/modules/my-account/reducer';
import myDetails from 'redux/modules/my-details/reducers/my-details-reducers';
import myWaitrose from 'redux/modules/mywaitrose/reducer';
import offers from 'redux/modules/offers/reducer';
import order from 'redux/modules/order/reducer';
import orders from 'redux/modules/orders/reducers';
import page from 'redux/modules/page/reducer';
import payment from 'redux/modules/payment/reducer';
import personalisation from 'redux/modules/personalisation/reducer';
import productGrid from 'redux/modules/product-grid/reducer';
import productDetails from 'redux/modules/product-details/reducer';
import productPicker from 'redux/modules/product-picker/reducer';
import promotions from 'redux/modules/promotions/reducer';
import recipes from 'redux/modules/recipes/reducers';
import registration from 'redux/modules/registration/reducers';
import resetPassword from 'redux/modules/reset-password/reducer';
import reviews from 'redux/modules/reviews/reducer';
import rollouts from 'redux/modules/rollouts/reducer';
import search from 'redux/modules/search/reducer';
import searchAndBrowse from 'redux/modules/search-and-browse/reducer';
import sessions, { initialState as sessionsInitialState } from 'redux/modules/sessions/reducer';
import shoppingList from 'redux/modules/shopping-list/reducer';
import shoppingLists from 'redux/modules/shopping-lists/reducer';
import slotBooking from 'redux/modules/slot-booking/reducers';
import slotDates from 'redux/modules/slot-dates/reducers';
import slotExperience from 'redux/modules/slot-experience/reducer';
import slots from 'redux/modules/slots/reducers';
import snackbar from 'redux/modules/common-snackbar/reducers';
import taxonomy from 'redux/modules/taxonomy/reducer';
import trolley from 'redux/modules/trolley/reducer';
import trolleyErrors from 'redux/modules/trolley-errors/reducer';
import trolleyPricing from 'redux/modules/pricing/reducer';

import { SESSION_GLOBAL_RESET } from 'redux/modules/sessions/actions/types';

import checkoutInitialState from 'redux/modules/checkout/state/initial-state';

import {
  BRANCH_FEEDBACK_NAME,
  MY_WAITROSE_CARD_REPLACEMENT_NAME,
  ONLINE_ORDER_ISSUE_NAME,
  PRODUCT_ISSUES_NAME,
  STOCK_QUERIES_NAME,
} from 'constants/customerServiceForms';

const allReducers = history =>
  combineReducers({
    accounts,
    addAllItems,
    addresses,
    addressSuggestion,
    amendOrder,
    appleWallet,
    autoSuggest,
    branches,
    browserInfo,
    bundle,
    checkout,
    cms,
    cmsHybridRecipesPage,
    cmsPage,
    conflicts,
    content,
    cookies,
    customerAddress,
    customerSlot,
    entities,
    experienceFragments,
    experiments,
    favourites,
    favouritesExperience,
    favouritesProducts,
    forgotPassword,
    form: form.plugin({
      [BRANCH_FEEDBACK_NAME]: customerServiceReduxForm,
      [MY_WAITROSE_CARD_REPLACEMENT_NAME]: customerServiceReduxForm,
      [ONLINE_ORDER_ISSUE_NAME]: customerServiceReduxForm,
      [PRODUCT_ISSUES_NAME]: productIssueForm,
      [STOCK_QUERIES_NAME]: customerServiceReduxForm,
    }),
    header,
    incentiveOfferPropositions,
    incentives,
    instantCheckout,
    interstitials,
    login,
    marketingPreferences,
    mealDeals,
    mealPlanner,
    strategicMealDeals,
    megaMenu,
    missedOffers,
    modal,
    multisearch,
    myAccount,
    myDetails,
    myWaitrose,
    offers,
    order,
    orders,
    page,
    payment,
    personalisation,
    productDetails,
    productGrid,
    productPicker,
    promotions,
    recipes,
    registration,
    resetPassword,
    reviews,
    rollouts,
    router: connectRouter(history),
    search,
    searchAndBrowse,
    sessions,
    shoppingList,
    shoppingLists,
    slotBooking,
    slotDates,
    slotExperience,
    slots,
    snackbar,
    taxonomy,
    trolley,
    trolleyErrors,
    trolleyPricing,
  });

export default history => (state, action) => {
  let existingState = state;
  if (action && action.type === SESSION_GLOBAL_RESET) {
    const {
      checkout: { waiting: existingWaiting } = {},
      cookies: existingCookies,
      content: existingContent,
      experienceFragments: existingExperienceFragments,
      experiments: existingExperiments,
      form: existingForm,
      login: existingLogin,
      registration: existingRegistration,
      router: existingRouting,
      sessions: {
        itemToAddToTrolleyPostAuthentication: existingItemToAddToTrolleyPostAuthentication,
        itemToFavouritePostAuthentication: existingItemToFavouritePostAuthentication,
      } = {},
      slotBooking: existingSlotBooking,
      taxonomy: existingTaxonomy,
    } = state;

    const existingSessions = {
      ...sessionsInitialState,
      itemToAddToTrolleyPostAuthentication: existingItemToAddToTrolleyPostAuthentication,
      itemToFavouritePostAuthentication: existingItemToFavouritePostAuthentication,
    };
    const existingCheckout = {
      ...checkoutInitialState,
      waiting: existingWaiting,
    };

    existingState = {
      ...(action.shouldKeepSlotBooking && {
        slotBooking: {
          lastNonSlotBookingPath: get(existingSlotBooking, 'lastNonSlotBookingPath'),
          restrictedProductGroups: get(existingSlotBooking, 'restrictedProductGroups'),
          gridLength: get(existingSlotBooking, 'gridLength'),
        },
      }),
      checkout: existingCheckout,
      cookies: existingCookies,
      content: existingContent,
      experienceFragments: existingExperienceFragments,
      experiments: existingExperiments,
      form: existingForm,
      login: existingLogin,
      registration: {
        isActive: get(existingRegistration, 'isActive'),
      },
      router: existingRouting,
      sessions: action.existingSessionToKeep || existingSessions,
      taxonomy: existingTaxonomy,
    };
  }
  return allReducers(history)(existingState, action);
};
