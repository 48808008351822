import { createSelector, lruMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';

import { CATEGORY_RANKING } from 'constants/productsSorting';
import { OTHERS } from 'constants/categoryIds';

import { getFavouritesSortingMethod } from 'redux/modules/favourites/selectors';
import { getFavouritesProducts } from 'redux/modules/favourites/selectors/get-favourites-products';

import { getProductCategoriesById } from 'redux/modules/product-details/selectors/product-category';
import { getProducts } from 'redux/modules/entities/selectors/products';
import { getFavourites, isFavouritesSuccessfullyLoaded } from './get-favourites';

const MAX_GROUP_SIZE = 20;

const areInputsEquivalent = (a, b) => {
  // Product Entities input considered equivalent if have the same products.
  // Assumption is that even if a product is updated in state, it's categories
  // should not have changed, so sufficient to compare the old vs new set of
  // product IDs.
  if (typeof a === 'object' && !Array.isArray(a)) {
    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    return isEqual(keysA, keysB);
  }

  return isEqual(a, b);
};

export const getItemsGroupedByCategoryLegacy = createSelector(
  [getFavouritesSortingMethod, getProducts, getFavouritesProducts],
  (sortMethod, productEntities, products = []) => {
    if (sortMethod !== CATEGORY_RANKING) {
      return false;
    }

    if (products.length < MAX_GROUP_SIZE) {
      return new Map().set(null, products);
    }

    const state = { entities: { products: productEntities } };

    const numberOfProductsPerCategory = products.reduce((acc, product) => {
      const { searchProduct } = product;
      const productCategoryHierarchy = getProductCategoriesById(state, searchProduct);

      productCategoryHierarchy.forEach(({ id }) => {
        acc[id] = (acc[id] || 0) + 1;
      });

      return acc;
    }, {});

    return products.reduce((categories, product) => {
      const { searchProduct } = product;

      const productCategoryHierarchy = getProductCategoriesById(state, searchProduct) || [];

      if (!productCategoryHierarchy.length) {
        if (!categories.has(OTHERS)) {
          categories.set(OTHERS, []);
        }

        categories.get(OTHERS).push({ searchProduct });

        return categories;
      }

      const { id: categoryId } =
        productCategoryHierarchy?.find(
          ({ id }) => numberOfProductsPerCategory[id] <= MAX_GROUP_SIZE,
        ) || productCategoryHierarchy?.[productCategoryHierarchy.length - 1];

      if (!categories.has(categoryId)) {
        categories.set(categoryId, []);
      }

      categories.get(categoryId).push({ searchProduct });

      return categories;
    }, new Map());
  },
  {
    memoize: lruMemoize,
    memoizeOptions: {
      equalityCheck: areInputsEquivalent,
    },
  },
);

const EMPTY_FAVOURITES_CATEGORIES_ARRAY = [];

const getFavouritesCategories = createSelector(
  getFavourites,
  favourites =>
    isFavouritesSuccessfullyLoaded(favourites)
      ? favourites.categories
      : EMPTY_FAVOURITES_CATEGORIES_ARRAY,
  {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  },
);

// TODO: GTM DataLayer actions, though probably best to refactor so no longer
// part of the selectors concerns, and done in the component instead.
// https://www.jlpit.com/jira/browse/WPIP-62683
const PLACEHOLDER_ACTIONS_TO_DISPATCH = [];

// TODO: handle sorting order that isn't category (i.e. purchase frequency)
export const getItemsGroupedByCategoryExperience = createSelector(
  [getFavouritesCategories, getProducts],
  (categories, products) => {
    const groups = new Map(
      categories.map(category => [
        category.id,
        category.orderedComponentsAndProducts.map(({ id }) => ({
          searchProduct: id,
          sponsored: products[id]?.isSponsored,
        })),
      ]),
    );

    return { groups, actionsToDispatch: PLACEHOLDER_ACTIONS_TO_DISPATCH };
  },
);
