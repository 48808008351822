import { MEGA_MENU_MAX_LENGTH, MEGA_MENU_PATH } from 'redux/modules/page/constants';
import { deepFreeze } from 'utils/deepFreeze';

interface Navigation {
  history: unknown[];
}

interface MegaMenu {
  isOpen: boolean;
  activeItem: unknown;
  activeLevel: number;
  path: string;
  menus: unknown[];
  menuRoot: string;
  maxLength: number;
  rootCategory: unknown;
}

export interface PageState {
  currentPageDepth?: number;
  navigation: Navigation;
  isClientPageLoaded: boolean;
  isMobileOverlayOpen: boolean;
  isValueClear: boolean;
  isPageLoading: boolean;
  isPinBarOpen: boolean;
  megaMenu: MegaMenu;
  preloadedPath?: string;
  requestCookies?: string;
  responseCookies?: string[];
  serverGtmEvents?: unknown[];
  serverMonetateEvents?: unknown[];
}

export const initialState = deepFreeze({
  currentPageDepth: undefined,
  navigation: {
    history: [],
  },
  isClientPageLoaded: false,
  isMobileOverlayOpen: false,
  isValueClear: false,
  isInitialPageLoaded: false,
  isPageLoading: false,
  isPinBarOpen: false,
  megaMenu: {
    isOpen: false,
    activeItem: undefined,
    activeLevel: -1,
    path: MEGA_MENU_PATH,
    menus: [],
    menuRoot: 'Shop',
    maxLength: MEGA_MENU_MAX_LENGTH,
    rootCategory: undefined,
  },
  preloadedPath: '',
  requestCookies: '',
  responseCookies: [],
  serverGtmEvents: [],
  serverMonetateEvents: [],
});
