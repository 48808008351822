import React, { type ComponentType } from 'react';
import { Route, Switch } from 'react-router-dom';

import { Head } from 'components/App/Head';
import { CookiesScripts } from 'components/CookieAlert/CookiesScripts';
import DataProvider from 'components/DataProvider';
import ErrorBoundary from 'components/ErrorBoundary';
import ExperimentsReporter from 'components/Experiment/ExperimentsReporter';
import { CheckoutWrapper } from 'components/NewCheckout/CheckoutWrapper';
import RefreshMessaging from 'components/RefreshMessaging';
import { SimpleErrorBoundary } from 'components/SimpleErrorBoundary/SimpleErrorBoundary';
import { loadable } from 'components/Skeleton/LoadableSkeleton'; // This HAS to be called `loadable` to work with SSR
import WebVitalsReporter from 'components/WebVitalsReporter';
import { withBlockingApis } from 'components/withBlockingApis';
import urls from 'constants/urls';
import { hasCustomerSlotInitialLoadCompleted } from 'redux/modules/customer-slot/selectors';
import { hasGetMembershipStatusInitialLoadCompleted } from 'redux/modules/marketing-preferences/selectors/get-membership-status';
import hasTrolleyLoadCompleted from 'redux/modules/trolley/selectors/get-loaded';

import styles from './App.scss';

const withLegacyBlockingApis = (
  WrappedComponent: ComponentType,
  fallbackOverride?: JSX.Element | null,
) =>
  withBlockingApis(
    WrappedComponent,
    [
      hasCustomerSlotInitialLoadCompleted,
      hasGetMembershipStatusInitialLoadCompleted,
      hasTrolleyLoadCompleted,
    ],
    fallbackOverride,
  );

const noFallback = {
  fallback: null as never,
};

const CommonModal = loadable(
  () =>
    import(
      /* webpackChunkName: "CommonModal" */
      'components/Modal/CommonModal'
    ),
  noFallback,
);

const CommonSnackbar = loadable(
  () =>
    import(
      /* webpackChunkName: "CommonSnackbar" */
      'components/CommonSnackbar'
    ),
  noFallback,
);

const Checkout = loadable(
  () =>
    import(
      /* webpackChunkName: "checkout" */
      'components/NewCheckout/CheckoutLoader'
    ),
  { fallback: <CheckoutWrapper /> },
);

const ResolveOrderPayment = loadable(
  () => import('components/ResolveOrderPayment/ResolveOrderPaymentLoader'),
  { fallback: <CheckoutWrapper title="Payment" /> },
);

const OrderConfirmation = withLegacyBlockingApis(
  loadable(() => import('components/OrderConfirmation')),
);

export interface WebviewAppProps {
  pageTitle: string;
}

export function WebviewApp() {
  return (
    <>
      <WebVitalsReporter />
      <ExperimentsReporter />
      {/* Calls the route loaders */}
      <DataProvider />
      {/* Renders the scripts related to cookies, if enabled */}
      <CookiesScripts />
      <Head />
      <SimpleErrorBoundary
        logger="App"
        section="top-level-error-boundary"
        errorComponent={<RefreshMessaging />}
      >
        <Switch>
          <Route
            exact
            path={urls.checkout}
            render={routeProps => (
              <ErrorBoundary logger="checkout-component" section="checkout">
                <Checkout {...routeProps} />
              </ErrorBoundary>
            )}
          />
          <Route
            exact
            path={`${urls.resolveOrderPayment}/:customerOrderId`}
            component={ResolveOrderPayment}
          />
          <Route>
            <main className={styles.appMain} role="main" id="main">
              <SimpleErrorBoundary
                logger="App"
                section="main-section-error-boundary"
                errorComponent={<RefreshMessaging />}
              >
                <Switch>
                  <Route
                    component={OrderConfirmation}
                    path={`${urls.orderConfirmation}/:customerOrderId`}
                  />
                </Switch>
              </SimpleErrorBoundary>
            </main>
          </Route>
        </Switch>
      </SimpleErrorBoundary>
      <CommonModal />
      <CommonSnackbar />
    </>
  );
}
