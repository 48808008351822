import {
  SUB_ALL_UPDATING,
  SUB_ALL_UPDATED,
  SUB_ALL_UPDATE_FAILED,
} from 'redux/modules/trolley/actions/types';

import addAllItems from 'api/definitions/addAllItems';
import { handleUpdateTrolleyErrors } from 'redux/modules/trolley/error-handling';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';

export const toggleSubAll = trolleyItemsSubAllowed => (dispatch, getState) => {
  const canSubstitute = trolleyItemsSubAllowed[0]?.canSubstitute ?? false;
  const state = getState();
  dispatch({ type: SUB_ALL_UPDATING });
  return addAllItems
    .patch({
      merge: false,
      orderId: getCustomerOrderId(state),
      body: [...trolleyItemsSubAllowed],
    })({ dispatch, getState })
    .then(response => {
      const { data: { updateTrolleyItems } = {}, errors } = response;

      if (errors || !updateTrolleyItems || updateTrolleyItems.failures) {
        let error = new Error('Graph call succeeded but returned no data or an unknown error');
        dispatch({
          error: 'Graph call succeeded but returned no data or an unknown error',
          type: SUB_ALL_UPDATE_FAILED,
        });

        if (errors?.length) {
          [error] = errors;
        }

        if (updateTrolleyItems?.failures?.length) {
          const { failures } = updateTrolleyItems;
          [error] = failures;
        }
        return handleUpdateTrolleyErrors(dispatch, getState)(error);
      }

      return dispatch({
        type: SUB_ALL_UPDATED,
        result: updateTrolleyItems,
        allowSub: canSubstitute,
      });
    })
    .catch(err => {
      dispatch({
        error: 'Update trolley items call to graph failed',
        type: SUB_ALL_UPDATE_FAILED,
      });
      return handleUpdateTrolleyErrors(dispatch, getState)(err);
    });
};
