import PropTypes from 'prop-types';

import { textAreaShape } from './text-area';

export const textCardShape = {
  ...textAreaShape,
  oneCell: PropTypes.bool,
  fourCells: PropTypes.bool,
  promoClick: PropTypes.func,
};
