import addColumnComponentToContentResponse from 'components/ContentLocation/add-column-component-to-content-response';
import { deepFreeze } from 'utils/deepFreeze';

import {
  EXPERIENCE_FRAGMENT_SUBMIT_SUCCESS,
  ExperienceFragment,
} from 'redux/modules/experience-fragments/actions/types';

export const initialState = deepFreeze({});

type ActionType = {
  type: typeof EXPERIENCE_FRAGMENT_SUBMIT_SUCCESS | '';
  experienceFragmentKey: string;
  result?: ExperienceFragment;
  applyColumnTransformation?: boolean;
};

export default (state = initialState, action: ActionType) => {
  switch (action.type) {
    case EXPERIENCE_FRAGMENT_SUBMIT_SUCCESS: {
      return {
        ...state,
        [action.experienceFragmentKey]: {
          ...action.result,
          locations: action?.applyColumnTransformation
            ? addColumnComponentToContentResponse(action?.result?.locations)
            : action?.result?.locations,
        },
      };
    }
    default:
      return state;
  }
};
