import React from 'react';
import PropTypes from 'prop-types';

import { richTextNode } from 'constants/types/rich-text';
import RichText from 'components/RichText';
import { RICH_TEXT_NAME } from 'components/AemComponent/analyticsConstants';

const RichTextMapper = props => {
  const { blocks, styleIds, position, valid, ...rest } = props;

  const richTextProps = {
    ...rest,
    blocks,
    styleIds,
    valid,
  };

  return <RichText data={richTextProps} position={position} />;
};

RichTextMapper.displayName = RICH_TEXT_NAME;

RichTextMapper.defaultProps = {
  blocks: [],
  styleIds: [],
  valid: true,
  position: 'spa-position-1',
};

RichTextMapper.propTypes = {
  blocks: PropTypes.arrayOf(richTextNode),
  position: PropTypes.string,
  styleIds: PropTypes.arrayOf(PropTypes.string),
  valid: PropTypes.bool,
};

export default RichTextMapper;
