import PropTypes from 'prop-types';

export const dynamicImagesShape = {
  highQuality: PropTypes.string,
  defaultQuality: PropTypes.string,
  highQuality85: PropTypes.string,
  highQuality90: PropTypes.string,
  highQuality95: PropTypes.string,
  highQuality100: PropTypes.string,
};

export const DynamicImagesType = PropTypes.shape({ ...dynamicImagesShape });

export const orientedImageShape = {
  fileReference: PropTypes.string,
  altText: PropTypes.string,
  dynamicImages: DynamicImagesType,
};

export const OrientedImageType = PropTypes.shape({ ...orientedImageShape });

export const imageShape = {
  landscapeImage: OrientedImageType,
  portraitImage: OrientedImageType,
};

export const ImageType = PropTypes.shape({ ...imageShape });
