import { getStateAtNamespaceKey } from 'redux/get-state-at-namespace-key';
import { getFeatureFlags } from 'utils/feature-flags';

import { FavouritesExperienceSuccessState, FavouritesExperienceState } from '../types';

export const isFavouritesSuccessfullyLoaded = (
  state: FavouritesExperienceState,
): state is FavouritesExperienceSuccessState => 'categories' in state;

export const getFavourites = (state: WtrState) => {
  const { build_favouritesExperience: favouritesExperience } = getFeatureFlags();
  const favouritesLegacyState = state.favourites;

  const isPurchasedFrequency = favouritesLegacyState?.sortBy === 'weightedFavourites';

  return favouritesExperience && !isPurchasedFrequency
    ? getStateAtNamespaceKey(state, 'favouritesExperience')
    : getStateAtNamespaceKey(state, 'favourites');
};
