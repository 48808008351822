import { setMegaMenu } from 'redux/modules/page/actions/set-mega-menu';
import { setMegaMenuActiveLevel } from 'redux/modules/page/actions/set-mega-menu-active-level';
import { setPageLoading } from 'redux/modules/page/actions/set-page-loading';

export const navigateFromMegaMenu =
  (level, name, id = null, samePage = false) =>
  dispatch => {
    if (!samePage) dispatch(setPageLoading());
    dispatch(setMegaMenu(level, id, name));
    dispatch(setMegaMenuActiveLevel(-1));
  };
