import urls from 'constants/urls';
import history from 'utils/history';
import locator from 'utils/locator';
import { isInternal } from 'utils/is-internal';

export const urlsRequiringPageReload = [
  urls.checkout,
  urls.loyaltyExperiences,
  urls.rewardsJohnLewis,
  urls.rewards,
];

export const goToInternalOrExternalLocation = url => {
  if (__SERVER__) {
    locator.href = url;
    return;
  }

  if (isInternal(url) && !urlsRequiringPageReload.includes(url)) {
    history.push(url);
  } else {
    locator.href = url;
  }
};
