import React from 'react';
import { useSelector } from 'react-redux';
import { bool, func, string } from 'prop-types';

import locator from 'utils/locator';
import { getSearchValueFromSearchQuery } from 'utils/searchQuery';

import autoSuggestClear from 'redux/modules/auto-suggest/actions/auto-suggest-clear';
import autoSuggestSearch from 'redux/modules/auto-suggest/actions/auto-suggest-search';
import { updateSearchTerm } from 'redux/modules/search/actions/update-search-term';

import { getAutoSuggestActive } from 'redux/modules/auto-suggest/selectors';
import getEligibleForAutoSuggest from 'redux/modules/auto-suggest/selectors/eligible-for-autosuggest';
import { getFunctionalCookieState } from 'redux/modules/cookies/selectors';

import AutoComplete from 'components/Search/AutoComplete';
import SearchHistory from 'components/Search/SearchHistory';
import MultiSearchModal from 'components/MultiSearch/Modal';
import { SearchBox } from '@johnlewispartnership/wtr-ingredients/ingredients/SearchBox';

import { useSearchForm } from './use-search-form';

const SearchForm = ({ homepage, id, onSearch, placeholder }) => {
  const autoSuggestActive = useSelector(getAutoSuggestActive);
  const eligibleForAutoSuggest = useSelector(getEligibleForAutoSuggest);
  const functionalCookieConsent = useSelector(getFunctionalCookieState);
  const initialValue =
    (!locator.search.includes('searchType') && getSearchValueFromSearchQuery(locator.search)) || '';
  const {
    handleChange,
    handleClear,
    handleClick,
    handleFocus,
    handleKeyDown,
    handleSelectedAutoCompleteItem,
    handleSelectedSearchHistoryItem,
    handleSubmit,
    hasFocus,
    inputRef,
    insideRef,
    showSearchHistory,
    value,
  } = useSearchForm({
    autoSuggestActive,
    eligibleForAutoSuggest,
    functionalCookieConsent,
    initialValue,
    onChange: autoSuggestSearch,
    onClear: autoSuggestClear,
    onSubmit: updateSearchTerm,
    onSearch,
  });

  const inputProps = {
    onFocus: handleFocus,
    ref: inputRef,
    onClick: handleClick,
  };

  return (
    <SearchBox
      ariaActivedescendant={showSearchHistory ? 'search-history-item' : 'autocomplete-suggestion'}
      ariaAutocomplete="list"
      ariaOwns={showSearchHistory ? 'search-history' : 'autocomplete-listbox'}
      focus={hasFocus}
      formRef={insideRef}
      id={id}
      initialValue={initialValue}
      inputProps={inputProps}
      inputRef={inputRef}
      isHomepage={homepage}
      onChange={handleChange}
      onClearClick={handleClear}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      onSubmit={handleSubmit}
      placeholder={placeholder}
      value={value}
    >
      {hasFocus && !showSearchHistory && (
        <AutoComplete
          homepage={homepage}
          inputRef={inputRef}
          onClear={autoSuggestClear}
          onSelectItem={handleSelectedAutoCompleteItem}
        />
      )}

      {showSearchHistory && (
        <SearchHistory
          inputRef={inputRef}
          homepage={homepage}
          onSelectTerm={handleSelectedSearchHistoryItem}
        />
      )}
      <MultiSearchModal hidden={homepage || value.length > 0} />
    </SearchBox>
  );
};
SearchForm.displayName = 'SearchForm';

SearchForm.propTypes = {
  homepage: bool,
  id: string,
  onSearch: func,
  placeholder: string,
};

SearchForm.defaultProps = {
  homepage: false,
  id: undefined,
  onSearch: null,
  placeholder: 'Search...',
};

export default SearchForm;
