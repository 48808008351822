import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';

import styles from 'components/PromoIconography/MessageIcon/MessageIcon.scss';

const MessageIcon = pure(({ display, message, theme }) => {
  if (!display || !message) return null;

  const messageTheme = styles[theme] || styles.default;

  return (
    <div className={styles.wrapper}>
      <span className={messageTheme} data-testid="message-icon">
        {message}
      </span>
    </div>
  );
});

MessageIcon.displayName = 'MessageIcon';

MessageIcon.propTypes = {
  display: PropTypes.bool,
  message: PropTypes.string,
  theme: PropTypes.string,
};

export default MessageIcon;
