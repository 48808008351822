import queryString from 'query-string';

import history from 'utils/history';
import { CLEAR_QUERY_PARAMS, UPDATE_QUERY_PARAMS } from './types';

const clearQueryParams = () => history.push({ search: '' });

const updateQueryParams = params => {
  const { search } = history.getLocation();
  const currentParams = queryString.parse(search);
  const nextParams = {
    ...currentParams,
    ...params,
  };
  const nextSearch = `?${queryString.stringify(nextParams)}`;
  history.push({ search: nextSearch });
};

const createMiddleware = () => next => action => {
  next(action);

  switch (action.type) {
    case UPDATE_QUERY_PARAMS: {
      updateQueryParams(action.payload);
      break;
    }
    case CLEAR_QUERY_PARAMS: {
      clearQueryParams();
      break;
    }
    default:
  }
};

export default createMiddleware;
