import React from 'react';
import propTypes from 'prop-types';
import classnames from 'classnames';
import AnchorLink from 'components/AnchorLink';

import promoClick from 'analytics/aem/promo-click';
import { IMAGE_NAME } from 'components/AemComponent/analyticsConstants';

import styles from './index.scss';

function ImageLink({
  analytics,
  children,
  className,
  componentId,
  linkURL,
  newWindow,
  position,
  getImageAltTextAndUrl,
}) {
  if (!linkURL) return children;

  const target = newWindow ? '_blank' : '_self';

  return (
    <AnchorLink
      className={classnames(styles.link, {
        [className]: !!className,
      })}
      onClick={() => {
        const { imageUrl, altText } = getImageAltTextAndUrl();

        return promoClick({
          ...analytics,
          altText,
          creative: IMAGE_NAME,
          id: componentId,
          imageUrl,
          linkURL,
          name: analytics.promoName,
          position,
          target,
        });
      }}
      target={target}
      to={linkURL}
    >
      {children}
    </AnchorLink>
  );
}

ImageLink.defaultProps = {
  analytics: {},
  children: undefined,
  className: undefined,
  linkURL: undefined,
  position: undefined,
  newWindow: undefined,
};

ImageLink.propTypes = {
  analytics: propTypes.shape({
    analyticsId: propTypes.string,
    promoName: propTypes.string,
  }),
  children: propTypes.node,
  className: propTypes.string,
  componentId: propTypes.string.isRequired,
  linkURL: propTypes.string,
  position: propTypes.string,
  newWindow: propTypes.bool,
  getImageAltTextAndUrl: propTypes.func.isRequired,
};

export default ImageLink;
