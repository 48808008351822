import React, { useMemo, useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Route, Link } from 'react-router-dom';
import classNames from 'classnames';
import { getFeatureFlags } from 'utils/feature-flags';
import { loginPagePath } from 'utils/login';

import HeaderLogo from 'components/HeaderLogo';
import LinksBar from 'components/SiteHeader/LinksBar';
import ConnectedLinksBar from 'components/SiteHeader/LinksBar/ConnectedLinksBar';
import ShoppingSummary from 'components/SiteHeader/ShoppingSummary';
import SearchForm from 'components/Search/SearchForm';
import MobileSearchButton from 'components/SiteHeader/MobileSearchButton';
import SlotButton from 'components/SiteHeader/SlotButton';
import DropDownNav from 'components/SiteHeader/LinksBar/DropDownNav/index';
import DropDownNavHidden from 'components/SiteHeader/LinksBar/DropDownNavHidden';
import { MenuButton } from 'components/SiteHeader/SlideOutNav/MenuButton';
import { useScrolled } from 'hooks/use-scrolled';

// TODO: [SSR][WARN] Does this impact SSR?
// TODO: migrate to useClientSideMediaQuery or SSRMediaQuery
import { useMediaQuery } from 'react-responsive';
import { BREAKPOINTS } from 'constants/grid';
import urls from 'constants/urls';
import { stickyHeaderOffset } from 'constants/site-header';

import { TextLink } from '@johnlewispartnership/wtr-ingredients/ingredients/TextLink';
import MultiSearchStrip from 'components/MultiSearch/Strip';
import TopNav from 'components/SiteHeader/TopNav';
import MobileOverlay from 'components/SiteHeader/MobileOverlay';
import SlideOutNav from 'components/SiteHeader/SlideOutNav';

import styles from './SiteHeader.scss';

// the static render is responsible for creating non-interactive version of the site for SSR to pre-render
// for customers to look at whilst we client render the page. Once SSR for auth users is in place, it can be
// removed.
const SiteHeader = ({
  isLoggedIn,
  staticRender,
  clearValue,
  isOverlayOpen,
  setClearValue,
  setMobileOverlay,
}) => {
  const { identity_enableOAuth2Web: OAuth2Enabled, renderStaticNavLinks } = getFeatureFlags();
  const [isTablet, setIsTablet] = useState(false);

  const { scrolled } = useScrolled(stickyHeaderOffset);

  const loginUrl = OAuth2Enabled ? loginPagePath() : urls.login;

  const SignInOrTrolley = isLoggedIn ? (
    <ShoppingSummary />
  ) : (
    <TextLink component={Link} data-testid="mobileHeaderSigninLink" to={loginUrl}>
      Sign in
    </TextLink>
  );

  const toggleOverlay = () => {
    setClearValue(true);
    setMobileOverlay(!isOverlayOpen);
  };

  const ref = useRef();
  const placeholderStyle = useMemo(() => {
    const { current: el } = ref;

    if (!scrolled || !el) return { display: 'none' };

    return { height: el.getBoundingClientRect().height };
  }, [scrolled]);

  // This is meant to force a rerender when switching from SSR to CSR as the useMediaQuery hook won't work server-side
  const isTabletBreakpoint = useMediaQuery({ minWidth: BREAKPOINTS.md, maxWidth: BREAKPOINTS.lg });
  useEffect(() => {
    setIsTablet(isTabletBreakpoint);
  }, [isTabletBreakpoint]);
  const placeholder = isTablet ? 'Search...' : 'Search groceries...';

  // Menu is rendered at a different position depending on whether in the
  // scrolled bar or not so that the tabbing order is correct
  const menu = (
    <div className={styles.menu} key="desktopmenu">
      {staticRender ? null : <DropDownNav scrolled={scrolled} />}
    </div>
  );

  const connectedHeaderComponents = (
    <div className={styles.desktop}>
      {renderStaticNavLinks && <DropDownNavHidden />}
      {scrolled && <div data-testid="DesktopHeaderPlaceholder" style={placeholderStyle} />}
      <div
        className={classNames(styles.desktopHeader, {
          [styles.scrolled]: scrolled,
        })}
        data-testid="DesktopHeader"
        ref={ref}
      >
        <div className={classNames('container-fluid', styles.container)}>
          <div className={styles.mobileMenu}>
            <MenuButton />
          </div>
          <div className={styles.mobileLogo}>
            <HeaderLogo small />
          </div>
          <div className={styles.logo}>
            <HeaderLogo />
          </div>
          {scrolled && menu}
          <div className={styles.inner}>
            <div className={styles.mobileSearch}>
              <MobileSearchButton toggleOverlay={toggleOverlay} />
            </div>
            <div className={styles.search}>
              <SearchForm placeholder={placeholder} />
            </div>

            <div className={styles.mobileSlot}>
              <SlotButton isMobile testId="mobile" textOnly shortTime />
            </div>
            <div className={styles.slot}>
              <SlotButton />
            </div>
            <div className={styles.trolley}>
              <ShoppingSummary />
            </div>
            <div className={styles.signInOrTrolley}>{SignInOrTrolley}</div>
          </div>
          {!scrolled && menu}
          {scrolled ? null : (
            <div className={styles.links}>
              <ConnectedLinksBar isLoggedIn={isLoggedIn} />
            </div>
          )}
        </div>
      </div>
    </div>
  );

  const staticHeaderComponents = (
    <div className="container-fluid">
      <div className={styles.logo}>
        <HeaderLogo staticRender />
      </div>
      <div className={styles.sticky}>
        <div className={styles.space} />
      </div>

      <div className={styles.links}>
        <LinksBar />
      </div>
    </div>
  );

  return (
    <header className={`${styles.header} no-print`} data-testid="site-header">
      <TopNav staticRender={staticRender} />
      {!staticRender && <SlideOutNav />}
      {staticRender ? staticHeaderComponents : connectedHeaderComponents}
      {staticRender ? null : (
        <div
          className={classNames(styles.multiSearch, {
            [styles.multiSearchScrolled]: scrolled,
          })}
        >
          <Route path={urls.multiSearch} component={MultiSearchStrip} />
        </div>
      )}
      {isOverlayOpen && <MobileOverlay clearValue={clearValue} setClearValue={setClearValue} />}
    </header>
  );
};

SiteHeader.propTypes = {
  isLoggedIn: PropTypes.bool,
  isOverlayOpen: PropTypes.bool,
  staticRender: PropTypes.bool,
  clearValue: PropTypes.bool,
  setClearValue: PropTypes.func,
  setMobileOverlay: PropTypes.func,
};

SiteHeader.defaultProps = {
  isLoggedIn: false,
  staticRender: false,
  clearValue: false,
  isOverlayOpen: false,
  setClearValue: null,
  setMobileOverlay: null,
};

export default SiteHeader;
