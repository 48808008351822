import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import AemComponentGrid from 'components/AemComponentGrid';

import styles from './index.scss';

const ResponsiveGrid = ({ childComponents, cqResponsive, position, preloadImages }) => (
  <div className={classNames(styles.grid)}>
    <AemComponentGrid
      childComponents={childComponents}
      cqResponsive={cqResponsive}
      position={position}
      preloadImages={preloadImages}
    />
  </div>
);

ResponsiveGrid.defaultProps = {
  childComponents: undefined,
  cqResponsive: undefined,
  position: undefined,
  preloadImages: false,
};

ResponsiveGrid.propTypes = {
  childComponents: PropTypes.arrayOf(PropTypes.shape({})),
  cqResponsive: PropTypes.shape({
    deviceBreakpoints: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  position: PropTypes.string,
  preloadImages: PropTypes.bool,
};

export default ResponsiveGrid;
