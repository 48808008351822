import PropTypes from 'prop-types';

export const richTextNodeShapeProps = {
  _type: PropTypes.string,
  text: PropTypes.string,
  mark: PropTypes.string,
  attributes: PropTypes.object,
  node: PropTypes.object,
  styles: PropTypes.string,
};

export const richTextNode = PropTypes.shape({
  ...richTextNodeShapeProps,
});

// recursive shape
richTextNode.children = PropTypes.arrayOf(richTextNode);
