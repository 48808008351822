import createReducer from 'redux/createReducer';
import { deepFreeze } from 'utils/deepFreeze';

import { LOCATION_CHANGE } from 'connected-react-router';

import {
  pending as createSessionsPending,
  fulfilled as createSessionsFulfilled,
  rejected as createSessionsRejected,
  tokenPending,
  tokenFulfilled,
  tokenRejected,
  clearError,
  throttleLogins,
  resetSessionState,
  saveShoppingContext,
} from './sessions';

import setAccessToken from './set-access-token';

import {
  CLEAR_ERROR,
  createSessionsActionTypes,
  tokenActionTypes,
  shoppingContextActionTypes,
  SET_ACCESS_TOKEN,
  THROTTLE_LOGINS,
  DISABLE_THROTTLE_LOGINS,
} from '../actions/types';

export const initialState = deepFreeze({
  // deepcode ignore HardcodedNonCryptoSecret - placeholder
  accessToken: 'Bearer unauthenticated',
  loading: false,
  error: null,
  customerId: '-1',
  customerOrderId: '0',
  customerOrderState: null,
  defaultBranchId: '651',
  expiresIn: null,
  permissions: [],
  principalId: '-1',
});

const actionTypeReducerMap = new Map([
  [createSessionsActionTypes.request, createSessionsPending],
  [createSessionsActionTypes.success, createSessionsFulfilled],
  [createSessionsActionTypes.failure, createSessionsRejected],
  [tokenActionTypes.request, tokenPending],
  [tokenActionTypes.success, tokenFulfilled],
  [tokenActionTypes.failure, tokenRejected],
  [shoppingContextActionTypes.request, createSessionsPending],
  [shoppingContextActionTypes.success, saveShoppingContext],
  [shoppingContextActionTypes.failure, resetSessionState],
  [SET_ACCESS_TOKEN, setAccessToken],
  [LOCATION_CHANGE, clearError],
  [CLEAR_ERROR, clearError],
  [THROTTLE_LOGINS, throttleLogins],
  [DISABLE_THROTTLE_LOGINS, resetSessionState],
]);

export default createReducer(initialState, actionTypeReducerMap);
