import { dataLayer } from 'analytics/data-layer';

export const getImpressionData = (elem, sponsored, sponsorshipId) => {
  const source = sponsorshipId ? 'citrus_ad' : 'AEM';
  return {
    id: elem.dataset.productId,
    'pod-type': elem.dataset.productPodType,
    index: elem.dataset.productIndex,
    availability: elem.dataset.productAvailability,
    sponsored: sponsored ? 'YES' : 'NO',
    sponsored_source: sponsored ? source : 'unknown',
  };
};

export const impressionsPerBatch = 20;

export const startImpressionService = () => {
  let impressions = [];
  let timerId = null;

  const reportImpressions = () => {
    dataLayer.push({
      event: 'product_impression',
      product_impressions: impressions,
    });
    impressions = [];
    clearTimeout(timerId);
  };

  const startTimer = () => {
    timerId = setTimeout(reportImpressions, 300000);
  };

  const reportWhen20 = () => {
    const impressionsSize = impressions.length;
    if (!impressionsSize) return;
    if (impressionsSize % impressionsPerBatch === 0) {
      reportImpressions();
    }
  };

  // this function is the interface of the service to pod visibility observer
  const addProductImpression = (elem, sponsored, sponsorshipId) => {
    const impression = getImpressionData(elem, sponsored, sponsorshipId);
    if (impressions.length === 0) {
      startTimer();
    }
    impressions.push(impression);
    reportWhen20();
  };

  const flushProductImpressions = () => {
    if (impressions.length) reportImpressions();
  };

  return { addProductImpression, flushProductImpressions };
};

export const impressionsService = startImpressionService();
