import React, { useCallback } from 'react';
import { bool, func, number, string } from 'prop-types';

import AnchorLink from 'components/AnchorLink';
import { OpenLink } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import styles from './CMSMenuLink.scss';

export const linkImagesTexts = {
  CELLAR: {
    image: {
      url: 'https://waitrose-prod.scene7.com/is/image/waitroseprod/wine-2?$Waitrose-Image-Preset-95$&wid=200&fit=constrain,0',
    },
    text: 'Explore more than 1,200 beers, wines and spirits',
    ariaLabel:
      'Waitrose Cellar. Explore more than 1,200 beers, wines and spirits. Opens in new window',
    className: 'imgBox cellar',
  },
  FLORIST: {
    image: {
      url: 'https://waitrose-prod.scene7.com/is/image/waitroseprod/ib-14961-May24-Menu-Florist?$Waitrose-Image-Preset-95$&wid=160&fit=constrain,0',
    },
    text: 'Explore our range of bouquets & plants - perfect for gifting and delivered to your door',
    ariaLabel:
      'Waitrose Florist. Explore our range of bouquets & plants - perfect for gifting and delivered to your door. Opens in new window',
    className: 'imgBox florist',
  },
  GARDEN: {
    image: {
      url: 'https://waitrose-prod.scene7.com/is/image/waitroseprod/garden-1?$Waitrose-Image-Preset-95$&wid=160&fit=constrain,0',
    },
    text: 'All your gardening needs - to plants, seeds, tools & furniture',
    ariaLabel:
      'Waitrose Garden. All your gardening needs - to plants, seeds, tools & furniture. Opens in new window',
    className: 'imgBox garden',
  },
};

const CMSMenuLink = ({
  card,
  id,
  level,
  name,
  navigateFromMegaMenu,
  newWindow,
  setMegaMenuState,
  styleAs,
  subtext,
  url,
}) => {
  const closeMegaMenuAction = useCallback(() => {
    setMegaMenuState(false);
    navigateFromMegaMenu(level, name, id);
  }, [id, level, name, navigateFromMegaMenu, setMegaMenuState]);

  const linkProps = {
    className: styles.link,
    'data-actiontype': 'redirect',
    'data-origincomponent': 'megamenu link',
    'data-shortdescription': name,
    'data-test': `mm-${name}`,
    inNewTab: newWindow,
    onClick: closeMegaMenuAction,
    title: name,
    to: url,
  };

  if (card) {
    return (
      <AnchorLink
        {...linkProps}
        aria-label={linkImagesTexts[name].ariaLabel}
        className={styles.card}
      >
        <img
          className={styles.linkImg}
          alt={name}
          src={linkImagesTexts[name].image.url}
          loading="lazy"
          height="80"
          width="80"
        />
        <span className={styles.textWrapper}>
          <span className={styles.name}>{name}</span>
          {subtext && <span className={styles.subtext}>{linkImagesTexts[name].text}</span>}
        </span>
      </AnchorLink>
    );
  }

  if (url) return <AnchorLink {...linkProps}>{name}</AnchorLink>;

  if (styleAs === 'small') {
    return (
      <span className={styles.smallWrapper}>
        {' '}
        <OpenLink size="small" />
        <span className={styles.small}>Each website opens in a new tab.</span>
      </span>
    );
  }

  return name === 'More from <span style="white-space:nowrap">Waitrose &amp; Partners</span>' ? (
    <span className={styles.newHeading}>MORE FROM WAITROSE</span>
  ) : (
    <span className={styles.heading} dangerouslySetInnerHTML={{ __html: name }} />
  );
};

CMSMenuLink.propTypes = {
  card: bool,
  id: string,
  level: number,
  name: string,
  navigateFromMegaMenu: func.isRequired,
  newWindow: bool,
  setMegaMenuState: func.isRequired,
  styleAs: string,
  subtext: string,
  url: string,
};

CMSMenuLink.defaultProps = {
  card: undefined,
  id: undefined,
  level: -1,
  name: undefined,
  newWindow: undefined,
  styleAs: undefined,
  subtext: undefined,
  url: undefined,
};

export default CMSMenuLink;
