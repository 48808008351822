import { SlotGridType } from 'api/definitions/slotdates/types';
import { SlotTypes } from 'constants/fulfilmentTypes';
import { deepFreeze } from 'utils/deepFreeze';

export interface CustomerSlotState {
  addressPostcode?: string;
  addressId: string | null;
  addressName: string;
  amendOrderCutOff: string;
  branchId: string | null;
  error: string | null;
  initialLoadComplete: boolean;
  loading: boolean;
  orderCutOff: string;
  slotDate: string;
  slotEndTime: string;
  slotExpiryTime: string;
  slotStartTime: string;
  slotType: SlotTypes;
  slotReservationId: string | null;
  substitutionPref: boolean;
  slotGridType: SlotGridType;
}

const initialState: Readonly<CustomerSlotState> = deepFreeze({
  addressId: null,
  addressName: '',
  amendOrderCutOff: '',
  branchId: null,
  error: null,
  initialLoadComplete: false,
  loading: false,
  orderCutOff: '',
  slotDate: '',
  slotEndTime: '',
  slotExpiryTime: '',
  slotStartTime: '',
  slotType: SlotTypes.UNDEFINED,
  slotGridType: SlotGridType.DEFAULT_GRID,
  slotReservationId: null,
  substitutionPref: false,
});

export default initialState;
