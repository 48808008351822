/**
 * Icons should be a STRING in this file
 * Corresponding icon should be mapped in src/components/CommonSnackbar/CommonSnackbar
 */

/**
 * !! A snackbar requires an ID for tracking to work !!
 */

/**
 * Config values
 * id: a unique id used for tracking
 * open: whether to open the snackbar
 * message: the message shown in the snackbar
 * type: the snackbar type (refer to Ingredients)
 * relationId: if multiple snackbars share a purpose, and only one should ever be shown/queued, this ID will replace any matching value in the queue
 */

export const slotCancelledSnackbar = {
  id: 'slotCancelledSnackbar',
  open: true,
  message: 'Slot reservation cancelled',
  type: 'success',
};

const checkoutPromoUniqueId = 'checkoutPromoId';
export const promoCodeAppliedAtCheckout = {
  id: 'promoCodeAppliedAtCheckout',
  open: true,
  message: 'Code successfully applied',
  type: 'success',
  relationId: checkoutPromoUniqueId,
};

export const promoCodeNotAppliedAtCheckout = {
  id: 'promoCodeNotAppliedAtCheckout',
  open: true,
  message: 'Code not applied, please check the alert',
  relationId: checkoutPromoUniqueId,
};

export const promoCodeRemovedAtCheckout = {
  id: 'promoCodeRemovedAtCheckout',
  open: true,
  message: 'Code successfully removed',
  type: 'success',
  relationId: checkoutPromoUniqueId,
};

export const partnerDiscountAppliedAtCheckout = {
  id: 'partnerDiscountAppliedAtCheckout',
  open: true,
  message: 'Partner discount successfully applied',
  type: 'success',
  relationId: checkoutPromoUniqueId,
};

export const partnerDiscountRemovedAtCheckout = {
  id: 'partnerDiscountRemovedAtCheckout',
  open: true,
  message: 'Partner discount successfully removed',
  type: 'success',
  relationId: checkoutPromoUniqueId,
};

export const rewardRedemptionSuccess = {
  id: 'rewardRedemptionSuccess',
  open: true,
  message: 'Your voucher is ready to use',
  type: 'success',
};

export const contactAddressChangedSnackbar = {
  id: 'contactAddressChangedSnackbar',
  open: true,
  message: 'Contact address updated',
  type: 'success',
};

export const incentiveLoadSuccess = {
  id: 'incentiveLoadSuccess',
  open: true,
  message: 'Selected voucher added to wallet',
  type: 'success',
  relationId: 'voucherSelectionDeselection',
};

export const incentiveUnloadSuccess = {
  id: 'incentiveUnloadSuccess',
  open: true,
  message: 'Voucher deselected',
  type: 'success',
  relationId: 'voucherSelectionDeselection',
};
