interface TacticalMealDealGroup {
  groupId: number;
  name: string;
  threshold: number;
}

interface TacticalMealDeal {
  id: number;
  name: string;
  startDate: string;
  endDate?: string;
  savings: string;
  title?: string;
  subtitle?: string;
  groups: TacticalMealDealGroup[];
}

/**
 * Temporary tactical representation of meal deals.
 *
 * TODO: https://www.jlpit.com/jira/browse/WPIP-53717
 */
export const mealDeals: TacticalMealDeal[] = [
  {
    id: 109,
    name: '£12 Our Very Best Dine In',
    startDate: '2024-02-15',
    endDate: '2024-09-10',
    savings: 'All for just £12',
    title: 'Enjoy with a drink',
    subtitle: 'Carefully chosen by our wine experts. Drinks are not included in the meal deal',
    groups: [
      {
        groupId: 106,
        name: 'Add 1 main',
        threshold: 1,
      },
      {
        groupId: 107,
        name: 'Add 1 side',
        threshold: 1,
      },
      {
        groupId: 108,
        name: 'Add 1 starter or dessert',
        threshold: 1,
      },
    ],
  },
  {
    id: 114,
    name: 'The Stir Fry Meal Deal',
    startDate: '2024-05-08',
    savings: 'All for just £7',
    title: 'Fancy more flavour?',
    subtitle:
      'Choose more delicious extras to add to your meal. Please note these are not included in the deal.',
    groups: [
      {
        groupId: 110,
        name: 'Add protein',
        threshold: 1,
      },
      {
        groupId: 111,
        name: 'Add veg',
        threshold: 1,
      },
      {
        groupId: 112,
        name: 'Add sauce',
        threshold: 1,
      },
      {
        groupId: 113,
        name: 'Add rice or noodles',
        threshold: 1,
      },
    ],
  },
  {
    id: 117,
    name: 'Pizza Meal Deal',
    startDate: '2022-10-19',
    endDate: '2022-11-01',
    savings: 'All for just £8',
    groups: [
      {
        groupId: 115,
        name: 'Add 2 pizzas',
        threshold: 2,
      },
      {
        groupId: 116,
        name: 'Add a side',
        threshold: 1,
      },
    ],
  },
  {
    id: 121,
    name: "Charlie Bigham's Meal Deal for Two: Choose a main and dessert",
    startDate: '2023-06-07',
    endDate: '2023-08-01',
    savings: 'All for £12',
    groups: [
      {
        groupId: 118,
        name: 'Add a main',
        threshold: 1,
      },
      {
        groupId: 120,
        name: 'Add a dessert',
        threshold: 1,
      },
    ],
  },
  {
    id: 125,
    name: 'Pizza Fakeaway Meal Deal',
    startDate: '2022-11-16',
    endDate: '2022-11-29',
    savings: 'All for £15',
    groups: [
      {
        groupId: 122,
        name: 'Add 2 pizzas',
        threshold: 2,
      },
      {
        groupId: 123,
        name: 'Add 2 extras',
        threshold: 2,
      },
      {
        groupId: 124,
        name: 'Add a drink',
        threshold: 1,
      },
    ],
  },
  {
    id: 129,
    name: '£12 Pizza Feast Meal Deal',
    startDate: '2024-06-05',
    endDate: '2024-07-02',
    savings: 'Save up to £7.80',
    title: 'Thirsty for more?',
    subtitle:
      'Choose another drink to enjoy with your meal. Please note these are not included in the deal.',
    groups: [
      {
        groupId: 126,
        name: 'Add 2 pizzas',
        threshold: 2,
      },
      {
        groupId: 127,
        name: 'Add 2 extras',
        threshold: 2,
      },
    ],
  },
  {
    id: 132,
    name: '£11 Indian Fakeaway Meal Deal',
    startDate: '2022-11-30',
    endDate: '2022-12-27',
    savings: 'All for £11',
    groups: [
      {
        groupId: 130,
        name: 'Add 2 mains',
        threshold: 2,
      },
      {
        groupId: 131,
        name: 'Add 2 sides',
        threshold: 2,
      },
    ],
  },
  {
    id: 135,
    name: '£12 South East & East Asian Meal Deal',
    startDate: '2024-07-03',
    endDate: '2024-07-30',
    savings: 'Save up to £6',
    title: 'Thirsty for more?',
    subtitle:
      'Choose another drink to enjoy with your meal. Please note these are not included in the deal.',
    groups: [
      {
        groupId: 133,
        name: 'Add 2 mains',
        threshold: 2,
      },
      {
        groupId: 134,
        name: 'Add 2 sides',
        threshold: 2,
      },
    ],
  },
  {
    id: 150,
    name: "£20 Valentine's Dine In",
    startDate: '2024-02-07',
    endDate: '2024-02-14',
    savings: 'Save up to £19.40',
    title: 'Surprise them with something special',
    subtitle:
      'Pick up a gift that says "I love you". Please note these are not included in the deal.',
    groups: [
      {
        groupId: 145,
        name: 'Add 1 starter',
        threshold: 1,
      },
      {
        groupId: 146,
        name: 'Add 1 main',
        threshold: 1,
      },
      {
        groupId: 147,
        name: 'Add 1 side',
        threshold: 1,
      },
      {
        groupId: 148,
        name: 'Add 1 dessert',
        threshold: 1,
      },
      {
        groupId: 149,
        name: 'Add 1 fine wine, cocktail or chocolates',
        threshold: 1,
      },
    ],
  },
  {
    id: 159,
    name: '£8 Easy Cook Meal Deal - Choose 1 Main + 2 Sides',
    startDate: '2024-07-31',
    endDate: '2024-08-27',
    savings: 'Save up to £5.50',
    title: 'Thirsty for more?',
    subtitle:
      'Choose a drink to enjoy with your meal. Please note these are not included in the deal.',
    groups: [
      {
        groupId: 157,
        name: 'Add 1 main',
        threshold: 1,
      },
      {
        groupId: 158,
        name: 'Add 2 sides',
        threshold: 2,
      },
    ],
  },
  {
    id: 162,
    name: '£12 Indian Meal Deal - Choose 2 Mains + 2 Sides',
    startDate: '2024-07-31',
    endDate: '2024-08-27',
    savings: 'Save up to £8',
    title: 'Thirsty for more?',
    subtitle:
      'Choose another drink to enjoy with your meal. Please note these are not included in the deal.',
    groups: [
      {
        groupId: 160,
        name: 'Add 2 mains',
        threshold: 2,
      },
      {
        groupId: 161,
        name: 'Add 2 extras',
        threshold: 2,
      },
    ],
  },
  {
    id: 169,
    name: '£15 Pizza & Prosecco Dine In',
    startDate: '2024-02-15',
    endDate: '2024-03-12',
    savings: 'Save up to £7.39',
    title: 'Thirsty for more?',
    subtitle:
      'Choose another drink to enjoy with your meal. Please note these are not included in the deal.',
    groups: [
      {
        groupId: 167,
        name: 'Add 2 pizzas',
        threshold: 2,
      },
      {
        groupId: 168,
        name: 'Add 1 drink',
        threshold: 1,
      },
    ],
  },
  {
    id: 175,
    name: '£12 Steakhouse Meal Deal',
    startDate: '2024-04-10',
    endDate: '2024-05-07',
    savings: 'Save up to £4',
    title: 'The Perfect Match',
    subtitle:
      'Choose a drink to enjoy with your meal. Please note these are not included in the deal.',
    groups: [
      {
        groupId: 173,
        name: 'Add 1 main',
        threshold: 1,
      },
      {
        groupId: 174,
        name: 'Add 2 sides',
        threshold: 2,
      },
    ],
  },
  {
    id: 181,
    name: '£8 Chicken Dinner Meal Deal',
    startDate: '2024-07-03',
    endDate: '2024-07-30',
    savings: 'Save up to £2.40',
    title: 'Thirsty for more?',
    subtitle:
      'Choose another drink to enjoy with your meal. Please note these are not included in the deal.',
    groups: [
      {
        groupId: 179,
        name: 'Add 1 main',
        threshold: 1,
      },
      {
        groupId: 180,
        name: 'Add 2 extras',
        threshold: 2,
      },
    ],
  },
  {
    id: 185,
    name: '£5 Food to Go Meal Deal',
    startDate: '2024-06-05',
    endDate: '2024-12-03',
    savings: 'All for £5',
    groups: [
      {
        groupId: 182,
        name: 'Add 1 main',
        threshold: 1,
      },
      {
        groupId: 183,
        name: 'Add 1 snack',
        threshold: 1,
      },
      {
        groupId: 184,
        name: 'Add 1 drink',
        threshold: 1,
      },
    ],
  },
  {
    id: 188,
    name: '£12 Italian Feast Dine In',
    startDate: '2023-10-18',
    endDate: '2023-11-28',
    savings: 'All for £12',
    title: 'Something to drink?',
    subtitle:
      'Take your Italian-inspired evening to the next level with Prosecco or Birra Moretti - or a soft drink. Please note these are not included in the deal.',
    groups: [
      {
        groupId: 186,
        name: 'Add 2 mains',
        threshold: 2,
      },
      {
        groupId: 187,
        name: 'Add 2 extras',
        threshold: 2,
      },
    ],
  },
  {
    id: 191,
    name: '£10 Pizza Express Dine In',
    startDate: '2024-03-13',
    endDate: '2024-04-09',
    savings: 'All for £10',
    title: 'Something to drink?',
    subtitle:
      'Enjoy a beer, soft drink or smoothie with your meal. Please note these are not included in the deal.',
    groups: [
      {
        groupId: 189,
        name: 'Add 2 pizzas',
        threshold: 2,
      },
      {
        groupId: 190,
        name: 'Add 1 side',
        threshold: 1,
      },
    ],
  },
  {
    id: 197,
    name: '£12 Japan Menyū Meal Deal',
    startDate: '2024-03-13',
    endDate: '2024-04-09',
    savings: 'Save up to £7',
    title: 'The perfect match',
    subtitle:
      'Choose another drink to enjoy with your meal. Please note these are not included in the deal.',
    groups: [
      {
        groupId: 195,
        name: 'Add 2 mains',
        threshold: 2,
      },
      {
        groupId: 196,
        name: 'Add 2 sides',
        threshold: 2,
      },
    ],
  },
  {
    id: 201,
    name: '£7 Fresh Pasta Meal Deal',
    startDate: '2024-05-08',
    endDate: '2024-06-04',
    savings: 'Save up to £2.80',
    title: 'The perfect match',
    subtitle:
      'Choose another drink to enjoy with your meal. Please note these are not included in the deal.',
    groups: [
      {
        groupId: 198,
        name: 'Add 1 pasta',
        threshold: 1,
      },
      {
        groupId: 199,
        name: 'Add 1 sauce',
        threshold: 1,
      },
      {
        groupId: 200,
        name: 'Add 1 extra',
        threshold: 1,
      },
    ],
  },
];

/**
 * Temporary mapping of tactical meal deal ids to their strategic equivalents
 * for use in Meal Deal route redirects following the go-live of the Strategic
 * Meal Deal feature.
 *
 * TODO: https://www.jlpit.com/jira/browse/WPIP-53717
 */
export const tacticalIdToStrategicIdRedirectMap: Record<string, string> = {
  '121': '88089',
  '129': '90550',
  '135': '90874',
  '144': '87428',
  '159': '90946',
  '162': '90969',
  '175': '90155',
  '181': '90758',
  '191': '89979',
  '197': '89977',
  '201': '90350',
};

type PreservedTacticalId = '109' | '114' | '150' | '185';

type NonEmptyArray<T> = [T, ...T[]];

interface StrategicMealDeal {
  id: string;
  startDate: string;
  endDate: string;
}

/**
 * Temporary mapping of tactical meal deal ids to all possible strategic
 * equivalents for "preserved" meal deals as agreed with the SEO Team.
 *
 * When briefed by the Online Merchandising Team per the
 * [Meal Deal Process Document](https://docs.google.com/document/d/1-7sk6UkpivFz4rHcT3zC-rxcdxSbVIe8CppFNa3iq_I)
 * update the map below for all active and upcoming PIC offers that are
 * configured against the 4 preserved tactical meal deal IDs we are supporting.
 *
 * TODO: Remove following phase 2 strategic solution where the BE supports
 * "persistent" meal deal ids that can abstract over multiple PIC offer IDs for
 * a long running or recurring meal deal offer.
 */
export const tacticalIdToStrategicFetcherMap: Record<string, NonEmptyArray<StrategicMealDeal>> = {
  '109': [
    {
      id: '90002',
      startDate: '2024-02-15',
      endDate: '2024-09-10',
    },
    {
      id: '91143',
      startDate: '2024-09-11',
      endDate: '2025-01-21',
    },
  ],
  '114': [
    {
      id: '90380',
      startDate: '2024-05-08',
      endDate: '2024-10-22',
    },
  ],
  '150': [
    {
      id: '87566',
      startDate: '2024-02-07',
      endDate: '2024-02-14',
    },
  ],
  '185': [
    {
      id: '90847',
      startDate: '2024-06-05',
      endDate: '2024-12-03',
    },
  ],
};

/**
 * Temporary mapping of strategic meal deal ids back to their tactical
 * equivalents for "preserved" meal deals as agreed with the SEO Team.
 *
 * TODO: Remove following phase 2 strategic solution where the BE supports
 * "persistent" meal deal ids that can abstract over multiple PIC offer IDs for
 * a long running or recurring meal deal offer.
 */
export const strategicIdToTacticalIdRedTextMap: Record<string, PreservedTacticalId> =
  Object.entries(tacticalIdToStrategicFetcherMap).reduce(
    (currentMap, [tacticalId, strategicMealDeal]) => {
      strategicMealDeal.forEach(({ id }) => {
        // eslint-disable-next-line no-param-reassign
        currentMap[id] = tacticalId as PreservedTacticalId;
      });

      return currentMap;
    },
    {} as Record<string, PreservedTacticalId>,
  );
