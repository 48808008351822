import React from 'react';
import PropTypes from 'prop-types';

import { backgroundImage } from 'utils/backgroundImage';

import styles from './Roundel.scss';

const Roundel = ({ image }) => (
  <div className={styles.wrapper} style={backgroundImage(image)}>
    <div className={styles.label}>{image.altText}</div>
  </div>
);

Roundel.propTypes = {
  image: PropTypes.object.isRequired,
};

export default Roundel;
