import React from 'react';
import { useSelector } from 'react-redux';

import ContentLocation from 'components/ContentLocation/ContentLocation';
import { getExperienceFragment } from 'redux/modules/experience-fragments/selectors';

type ExperienceFragmentProps = {
  experienceFragmentKey: string;
  locationName: string;
};

const ExperienceFragment = ({ experienceFragmentKey, locationName }: ExperienceFragmentProps) => {
  const locationData = useSelector((state: WtrState) =>
    getExperienceFragment(state, experienceFragmentKey, locationName),
  );

  return <ContentLocation childComponents={locationData} name={locationName} />;
};

export default ExperienceFragment;
