import { dataLayer } from 'analytics/data-layer';
import extractLineNumberFromSku from 'utils/extract-linenumber-from-sku';
import { objHasKeys } from 'utils/validation';
import { GTM_TRACKED_CONFLICTS, AVAILABLE, UNAVAILABLE } from 'constants/conflicts';
import { CampaignName, SlotGridType } from 'api/definitions/slotdates/types';
import { formatGroceriesMenuNavigation } from 'utils/format';

const lowerCaseString = variable => String(variable).toLowerCase();

const getPromoValues = promos => {
  const promoArray = [];
  if (!promos) {
    return false;
  }
  for (let int = 0; int < promos.length; int += 1) {
    promoArray.push(promos[int].promotionDescription);
  }
  return promoArray.join(' | ');
};

export function pushProductImpressions({
  products,
  listType,
  productList,
  taxonomyLevel,
  slideIndex,
  wrapperId,
}) {
  const itemToProduct = item =>
    typeof item.searchProduct === 'string' ? products[item.searchProduct] : item.searchProduct;

  const gtmEvent = {
    event: 'product_list_impression',
    ecommerce: {
      currencyCode: 'GBP',
      impressions: [],
    },
    taxonomyLevel,
  };

  const getListName = () => {
    if (wrapperId) return lowerCaseString(wrapperId);

    if (listType) return lowerCaseString(listType);

    return undefined;
  };

  const impressions = productList
    .map(itemToProduct)
    .filter(product => product)
    .map(product => ({
      // 'brand' not available, could be resolved by gtm at report-time based on SKU
      // 'category' not available, could be resolved by gtm at report-time based on SKU
      currencyCode: product.currentSaleUnitPrice.price.currencyCode || '',
      id: extractLineNumberFromSku(product.id) || '',
      list: getListName(),
      name: product.name || '',
      offer: getPromoValues(product.promotions[0]) || '',
      position: product.gridPosition ? product.gridPosition : slideIndex || '',
      price: product.currentSaleUnitPrice.price.amount || '',
      product_sku: product.id || '', // same as ID above, docs ask for more speific SKU?
      uom: product.defaultQuantity.uom || '',
      // 'variant' not available, could be resolved by gtm at report-time based on SKU
    }));

  if (impressions.length) {
    gtmEvent.ecommerce.impressions = impressions;
    dataLayer.push(gtmEvent);
  }
}

export function handleProductListClick({
  conflictMessage = '',
  isFavourite,
  productData,
  searchType,
  wrapperId,
  position,
  boosted,
  sponsored,
  viewAlternativesClick = false,
}) {
  // When clicking through to a product page
  const listName = wrapperId || searchType;
  const favourite = `YES${boosted ? ': Boosted' : ''}`;
  let availability = AVAILABLE;
  if (conflictMessage) {
    availability = GTM_TRACKED_CONFLICTS.includes(conflictMessage) ? conflictMessage : UNAVAILABLE;
  }

  dataLayer.push({
    event: 'product_click',
    ecommerce: {
      click: {
        actionField: {
          list: listName ? lowerCaseString(listName) : undefined,
          ...(viewAlternativesClick ? { click_alternatives: true } : {}),
        },
        products: [
          {
            brand: productData.brand,
            category: (productData.categories || []).map(category => category.name),
            favourite: isFavourite ? favourite : 'NO',
            name: productData.name || '',
            id: extractLineNumberFromSku(productData.id) || '',

            price: objHasKeys(productData, ['currentSaleUnitPrice', 'price', 'amount'])
              ? productData.currentSaleUnitPrice.price.amount
              : 0,
            position,
            offer: productData.promotion?.promotionDescription || '',
            variant: productData.productType,
            sponsored: sponsored ? 'YES' : 'NO',
            availability,
          },
        ],
      },
    },
  });
}
export function pushSlotResultsToGTMDataLayer({
  postcode,
  branchId,
  availableSlots,
  fulfilmentType,
  maxCharge,
  minCharge,
  customerId,
  greenSlotsCount,
  greenSlotVariant,
}) {
  const postcodeTrimmed = postcode.slice(0, -3).trim();

  const slotResultsObj = {
    event: 'book_slot_results',
    book_slot: {
      type: fulfilmentType,
      results: {
        number_of_available_slots: availableSlots,
        number_of_available_green_slots: greenSlotsCount,
        max_charge: maxCharge,
        min_charge: minCharge,
        customer_id: customerId,
        variant_type: greenSlotVariant,
      },
    },
    user: {
      branch_id: branchId,
      postcode: postcodeTrimmed,
    },
  };
  dataLayer.push(slotResultsObj);
}

export function pushSlotBookedToGTMDataLayer(
  postcode,
  branchId,
  earliestDate,
  time,
  bookSlotType,
  slotCharge,
  orderId,
  date,
  slotDuration,
  customerId,
  greenSlotVariant,
) {
  const postcodeTrimmed = postcode.slice(0, -3).trim();

  const bookedSlotObj = {
    event: 'book_slot_booked',
    book_slot: {
      type: bookSlotType,
      slot_charge: slotCharge,
      customer_id: customerId,
      variant_type: greenSlotVariant,
      booked: {
        earliest_date: earliestDate,
        date,
        time,
      },
      slot_booked_successful: 'YES',
      slot_duration: slotDuration,
    },
    user: {
      branch_id: branchId,
      postcode: postcodeTrimmed,
    },
    order_id: orderId,
  };
  dataLayer.push(bookedSlotObj);
}

export function pushFormErrorToGTMDataLayer(form, error) {
  dataLayer.push({
    event: 'form_error',
    form,
    error,
  });
}

export function pushRegistrationToGTMDataLayer(
  defaultMarketingConsents,
  marketingConsents,
  customerId,
) {
  dataLayer.push({
    event: 'registration_complete',
    default_marketing_consents: defaultMarketingConsents,
    marketingConsents,
    user: { customer_id: customerId },
  });
}

export function pushEnterCheckoutEvent(location) {
  dataLayer.push({
    event: 'enter_checkout',
    location,
  });
}

export function pushStartCheckoutEvent(isAmendOrder, products, canInstantCheckout) {
  const optionField = isAmendOrder ? 'amend' : 'purchase';

  dataLayer.push({
    event: 'checkout_start',
    instant_checkout_eligibility: canInstantCheckout,
    ecommerce: {
      checkout: {
        actionField: {
          option: optionField,
        },
        products,
      },
    },
  });
}

const getCustomerType = customerHasACompletedOrder => {
  if (customerHasACompletedOrder === undefined) {
    return undefined;
  }

  return customerHasACompletedOrder ? 'Existing' : 'New';
};

export function generateCustomerEventForGTMDataLayer(
  customerId,
  loginStatus,
  isMyWaitroseCustomer,
  customerHasACompletedOrder,
  daysSinceLastCompletedOrder,
  eventName = null,
) {
  const customerEvent = {
    event: eventName,
    user: {
      customer_id: customerId,
      login_status: loginStatus,
      customer_type: getCustomerType(customerHasACompletedOrder),
      days_since_last_shop: daysSinceLastCompletedOrder,
      mywaitrose_customer: isMyWaitroseCustomer,
    },
  };

  if (customerEvent.event === null || customerEvent.event === undefined) {
    delete customerEvent.event;
  }

  return customerEvent;
}

export function pushMenuClick(navigation, additionalData = {}) {
  dataLayer.push({
    event: 'click_megamenu',
    mega_menu: {
      button_clicked: navigation,
      ...additionalData,
    },
  });
}

export function pushMenuClickWithLevels(
  { level, name, menus, additionalData } = { additionalData: {} },
) {
  const navigation =
    level > 0
      ? `${formatGroceriesMenuNavigation(menus.flatMap(item => item.name))}|G${level + 1}:${name}`
      : `${name}`;

  pushMenuClick(navigation, additionalData);
}

export function pushSlotGridNavigationEventToGTMDataLayer(isLater) {
  const method = isLater ? 'Later Button' : 'Earlier Button';
  const slotGridNavigationEvent = {
    event: 'slot_grid_navigation',
    navigation: {
      method,
    },
  };

  dataLayer.push(slotGridNavigationEvent);
}

export const SUBS_NOTES_EVENT = {
  decline: 'product_pod_decline_substitution',
  addNote: 'product_pod_add_note_clicked',
  saveNote: 'product_pod_save_note',
};

export function trackSubsNotesClick(event) {
  if (Object.values(SUBS_NOTES_EVENT).includes(event)) {
    dataLayer.push({ event });
  }
}

export function pushAddToListClick() {
  dataLayer.push({
    event: 'click_add_to_list',
  });
}

export function pushInterstitialsStatus(status, orderId) {
  dataLayer.push({
    event: 'interstitials_journey',
    order_id: orderId,
    status,
  });
}

export function pushIsSaverSlotBranchToGTMDataLayer(isSaverSlotBranch) {
  const saverSlotShown = {
    event: 'saver_slot_shown',
    saver_slot_shown: isSaverSlotBranch ? 'YES' : 'NO',
  };

  dataLayer.push(saverSlotShown);
}

export function pushSlotGridChangeToGTMDataLayer(slotGrid) {
  const selectorStateMap = {
    [SlotGridType.DEFAULT_GRID]: 'Slots from today',
    [SlotGridType.SAVER_GRID]: 'Saver slots',
    [CampaignName.CHRISTMAS]: 'Christmas slots',
    [CampaignName.EASTER]: 'Easter slots',
  };

  const slotGridChange = {
    event: 'navigate_book_slot_selector',
    selector_state: selectorStateMap[slotGrid],
  };

  dataLayer.push(slotGridChange);
}

export function pushAddressDeliveryAvailability(status) {
  dataLayer.push({
    event: 'address_delivery_availability',
    delivery_availability_status: status,
  });
}
