import PropTypes from 'prop-types';

export const linkShape = {
  text: PropTypes.string,
  altText: PropTypes.string,
  newWindow: PropTypes.bool,
  url: PropTypes.string,
};

export const LinkType = PropTypes.shape({ ...linkShape });
