export const links = [
  {
    name: 'Cellar',
    tracking: 'topnav_control_cellar',
    url: 'http://www.waitrosecellar.com/',
  },
  {
    name: 'Florist',
    tracking: 'topnav_control_florist',
    url: 'http://www.waitroseflorist.com/',
  },
  {
    name: 'Garden',
    tracking: 'topnav_control_garden',
    url: 'http://www.waitrosegarden.com/',
  },
];

export const linksOld = [
  {
    name: 'Cellar',
    tracking: 'topnav_control_cellar',
    url: 'http://www.waitrosecellar.com/',
  },
  {
    name: 'Florist',
    tracking: 'topnav_control_florist',
    url: 'http://www.waitroseflorist.com/',
  },
  {
    name: 'Garden',
    tracking: 'topnav_control_garden',
    url: 'http://www.waitrosegarden.com/',
  },
  {
    name: 'Gifts',
    tracking: 'topnav_control_gifts',
    url: 'https://www.waitrosecellar.com/gifts',
  },
];
