import { getStateAtNamespaceKey } from 'redux/get-state-at-namespace-key';

export const getExperienceFragment = (
  state: WtrState,
  experienceFragmentKey: string,
  location: string,
) => {
  const { locations } =
    getStateAtNamespaceKey(state, 'experienceFragments')[experienceFragmentKey] || {};

  return locations?.[location];
};

export const hasExperienceFragment = (
  state: WtrState,
  experienceFragmentKey: string,
  location: string,
) => !!getExperienceFragment(state, experienceFragmentKey, location)?.length;

export const getExperienceFragmentPageTitle = (state: WtrState, experienceFragmentKey: string) =>
  getStateAtNamespaceKey(state, 'experienceFragments')[experienceFragmentKey]?.pageTitle?.text;

export default getExperienceFragment;
