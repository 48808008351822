export const getRecipe = ({ recipes }) => {
  const recipe = recipes[recipes.id] || {};
  return {
    ...recipes,
    isShoppable: !recipe.pimsError && recipes.isShoppable,
    metaDescription: recipes.metaDescription || recipes.description?.plainText || '',
    products: recipe.products,
    rating: recipe.rating,
    shoppableProducts: recipe.shoppable,
  };
};

export const getLineNumbers = state =>
  (state.recipes[state.recipes.id]?.products || []).map(
    ingredient => ingredient.products[0]?.lineNumber,
  );

export const getShoppableProducts = ({ recipes }) => {
  return recipes[recipes.id]?.shoppableProducts;
};

export const getNumberOfSelectedShoppableProducts = ({ recipes }) => {
  const { shoppableProducts } = recipes[recipes.id];

  return shoppableProducts?.length
    ? shoppableProducts.reduce((total, { ingredients }) => {
        const { storeCupboard, nonStoreCupboard } = ingredients;
        return (
          total +
          storeCupboard.filter(ingredient => ingredient.amountSelected > 0).length +
          nonStoreCupboard.filter(ingredient => ingredient.amountSelected > 0).length
        );
      }, 0)
    : 0;
};

export const getNumberOfShoppableProducts = ({ recipes }) => {
  const { shoppableProducts } = recipes[recipes.id];

  return shoppableProducts?.length > 0
    ? shoppableProducts.reduce((total, { ingredients }) => {
        const { storeCupboard, nonStoreCupboard } = ingredients;
        return total + storeCupboard.length + nonStoreCupboard.length;
      }, 0)
    : 0;
};
