/*
  used by all trolley transforms so that the output event data shares the same schema,
  differing only in the event type and the ecommerce method key (`add` or `remove`),
  with `click_source: "add_all"` being included at the root of the object for the
  "Add all X items to trolley" feature

  example object to be pushed to GTM data layer with the `add` method:

  ```
  {
    event: 'add_to_cart',
    ecommerce: {
      currencyCode: 'GBP',
      add: {
        products: [{
          'name': 'product name',
          'id': '174535',
          'price': '1.23',
          'quantity': 123
          'brand': undefined,
          'category': undefined,
          'position': undefined,
          'favourite': undefined,
          'offer': undefined,
        }]
      }
    }
  }
  ```

  any property with `undefined` value represents information that (at the moment)
  cannot be extracted from the store as not being provided by the back-end
*/

import round from 'lodash/round';
import { C62 } from 'constants/weightOptions';
import { getFeatureFlags } from 'utils/feature-flags';
import { isDefined } from 'utils/validation';
import concatenatePromotionValues from 'redux/middleware/analytics/actions-to-track/utilities/concatenate-promotion-values';
import { getProductByLineNumber } from 'redux/modules/entities/selectors/products';
import { getIsFavourite } from 'redux/modules/favourites-products/selectors';
import { getCustomerOrderId } from 'redux/modules/sessions/selectors';
import { getRecipe } from 'redux/modules/recipes/selectors';
import { getAnalyticsTrolleyItems } from 'redux/modules/trolley/selectors/get-analytics-trolley-items';
import {
  getProductMealDeal,
  getNumberCompletedDeals,
  getMealDealPromoGroup,
  getIncompleteGroups,
} from 'redux/modules/meal-deals/selectors/oldIndex';
import {
  getTotalCompletedMealDeals,
  getMealDealGroupCompletionStatus,
} from 'redux/modules/meal-deals/selectors/newIndex';
import { ADD_TO_TROLLEY_DESCRIPTION } from 'redux/middleware/analytics/actions-to-track/trolley/constants';
import { isSponsoredProduct } from 'redux/modules/search-and-browse/selectors';
import { getIsProductBoosted } from 'redux/modules/favourites/selectors/get-is-product-boosted';
import {
  getOfferBuilderItems,
  getMealDealPromotion,
} from 'redux/modules/entities/selectors/promotions';
import { SEARCH_TYPE as OFFER_DETAILS_SEARCH_TYPE } from 'components/Offers/OfferDetails/constants';
import { offerBuilderDetails } from 'redux/middleware/analytics/actions-to-track/trolley/utils/offerBuilderDetails';

const yesOrNo = (expression, isBoosted) => {
  const favourite = `YES${isBoosted ? ': Boosted' : ''}`;

  return expression ? favourite : 'NO';
};

const noPromo = { type: undefined, name: undefined };
const noPersonalisation = { type: undefined, name: undefined };

const undefinedAttributes = () => ({
  brand: undefined,
  category: undefined,
  favourite: undefined,
  id: undefined,
  name: undefined,
  offer: undefined,
  price: undefined,
  quantity: undefined,
  variant: undefined,
});

const getTacticalMealDealAnalytics = (state, payload) =>
  payload.map(({ analytics }) => {
    const product = getProductByLineNumber(state, analytics.lineNumber);

    if (!product) {
      return {};
    }

    // TODO: https://www.jlpit.com/jira/browse/WPIP-61729
    const mealDealData = getProductMealDeal(product.promotions);

    if (!mealDealData) {
      return {};
    }

    const mealDealPromoLine = getMealDealPromoGroup(mealDealData);

    return {
      id: mealDealData.mealDealId,
      line_id: `${mealDealPromoLine.name} (${mealDealPromoLine.groupId})`,
      total_complete: getNumberCompletedDeals(state, mealDealData.mealDealId),
      incomplete_status: getIncompleteGroups(state, mealDealData.mealDealId),
    };
  });

const getStrategicMealDealAnalytics = (state, payload) =>
  payload.map(({ analytics }) => {
    const product = getProductByLineNumber(state, analytics.lineNumber);

    if (!product) {
      return {};
    }

    // TODO: https://www.jlpit.com/jira/browse/WPIP-61729
    const mealDealPromotion = getMealDealPromotion(state, product.promotions);

    if (!mealDealPromotion) {
      return {};
    }

    const { groups, promotionId } = mealDealPromotion;
    const groupIndex = groups.findIndex(({ lineNumbers }) =>
      lineNumbers.includes(analytics.lineNumber),
    );
    const group = groups[groupIndex];
    const groupId = groupIndex + 1;

    return {
      id: promotionId,
      line_id: `${group.name} (${groupId})`,
      total_complete: getTotalCompletedMealDeals(state, groups),
      incomplete_status: getMealDealGroupCompletionStatus(state, groups),
    };
  });

const getOfferBuilderAnalytics = (state, payload, method) => {
  const { searchType, lineNumber } = payload[0].analytics;

  if (searchType !== OFFER_DETAILS_SEARCH_TYPE) {
    return {};
  }

  const product = getProductByLineNumber(state, `${lineNumber}`);
  const promotionId = product?.promotion?.promotionId;

  if (!promotionId) {
    return {};
  }

  const builders = getOfferBuilderItems(state, `${promotionId}`);

  const { offerId, totalComplete, completedStatus, completionStatus, offerType } =
    offerBuilderDetails({ builders }) || {};

  if (!offerId) {
    return {};
  }

  const buildYourOffer = {
    id: offerId,
    total_complete: totalComplete,
    completion_status: completedStatus ? 'complete' : 'incomplete',
    offer_type: offerType,
  };

  const gtmEventCompleteBuilder = completionStatus
    ? {
        event: 'complete_build_your_offer',
        build_your_offer: {
          id: offerId,
          offer_type: offerType,
        },
        method:
          method === 'add' ? 'offer completed by adding item' : 'offer completed by removing item',
      }
    : null;

  return { buildYourOffer, gtmEventCompleteBuilder };
};

const changeCartTransform = (state, payload, method, event) => {
  let currencyCode;
  const orderId = getCustomerOrderId(state);

  const productInfos = payload.map(({ analytics }) => {
    const unavailableAttributes = undefinedAttributes();
    const { lineNumber, uom, productPosition, searchType, ingredientType } = analytics;
    const boosted = getIsProductBoosted(state, lineNumber);

    const sponsored = isSponsoredProduct(state, lineNumber);

    const isRecipePage = searchType === 'recipe';
    const recipe = isRecipePage ? getRecipe(state) : null;
    const isWeighted = uom !== C62;

    const {
      prevPrice: priceBefore = 0,
      prevQuantity: quantityBefore = 0,
      price: priceAfter = 0,
      quantity: quantityAfter = 0,
    } = getAnalyticsTrolleyItems(state, lineNumber) ?? {};

    const priceDelta = Math.abs(priceAfter - priceBefore);
    const quantityDelta = Math.abs(quantityAfter - quantityBefore);

    const quantity = isWeighted ? 1 : quantityDelta;
    const price = quantity && round(priceDelta / quantity, 2);

    const product = getProductByLineNumber(state, lineNumber);

    let availableAttributes = {
      price,
      quantity,
    };

    if (product) {
      const {
        sponsorshipId,
        brand,
        brandName,
        categories = [],
        currentSaleUnitPrice: { price: { currencyCode: productCurrencyCode = 'GBP' } = {} } = {},
        name,
        productType,
        promotions,
      } = product;

      let sponsoredSource = 'unknown';

      if (sponsored) {
        sponsoredSource = sponsorshipId ? 'citrusad' : 'aem';
      }

      currencyCode = productCurrencyCode;
      const customerFavourite = getIsFavourite(state, lineNumber);
      const favourite = isDefined(customerFavourite)
        ? yesOrNo(customerFavourite, boosted)
        : undefined;

      availableAttributes = {
        ...availableAttributes,
        brand: brandName || brand,
        category: categories?.map(({ name: categoryName }) => categoryName) || undefined,
        favourite,
        id: lineNumber,
        name,
        offer: promotions ? concatenatePromotionValues(state, promotions) : '',
        position: productPosition,
        variant: productType,
        matchStrategy: isRecipePage
          ? recipe?.products?.[productPosition - 1]?.products?.[0]?.matchStrategy || null
          : undefined,
        sponsored: sponsored ? 'YES' : 'NO',
        ingredient_type: ingredientType,
        ingredient_swapped: isRecipePage ? 'NO' : undefined,
        sponsored_source: sponsoredSource,
      };
    }

    return {
      ...unavailableAttributes,
      ...availableAttributes,
    };
  });

  const { enableEnrichPromotionForWebsite: offersExperience } = getFeatureFlags();

  const mealDealAnalytics = offersExperience
    ? getStrategicMealDealAnalytics(state, payload)
    : getTacticalMealDealAnalytics(state, payload);

  const { buildYourOffer = {}, gtmEventCompleteBuilder } = getOfferBuilderAnalytics(
    state,
    payload,
    method,
  );

  const gtmEvent = {
    _clear: true,
    event,
    ecommerce: {
      currencyCode,
      [method]: {
        products: productInfos,
        build_your_offer: buildYourOffer,
      },
    },
    // TODO: https://www.jlpit.com/jira/browse/WPIP-61728
    meal_deal: mealDealAnalytics[0],
  };

  if (event === ADD_TO_TROLLEY_DESCRIPTION) {
    const product = payload[0]?.analytics;

    const aemPromotion = product?.clickContext?.aemPromotion;
    const personalisation = product?.clickContext?.personalisation;
    const sponsored = isSponsoredProduct(state, product?.lineNumber)
      ? { type: 'Sponsored Product', name: undefined }
      : undefined;

    gtmEvent.promotion = aemPromotion || sponsored || noPromo;
    gtmEvent.personalisation = personalisation || noPersonalisation;
    gtmEvent.order_id = orderId;
  }

  return gtmEventCompleteBuilder ? [gtmEvent, gtmEventCompleteBuilder] : [gtmEvent];
};

export default changeCartTransform;
