import React from 'react';
import PropTypes from 'prop-types';

import AnchorLink from 'components/AnchorLink';
import { User } from '@johnlewispartnership/wtr-ingredients/foundations/icons';
import HeaderLogo from 'components/HeaderLogo';

import urls from 'constants/urls';
import { getFeatureFlags } from 'utils/feature-flags';
import { loginPagePath } from 'utils/login';

import styles from './SiteHeaderMinimal.scss';

const SiteHeaderMinimal = ({ showMinimalHeaderSignIn, staticRender }) => {
  const loginUrl = getFeatureFlags().identity_enableOAuth2Web ? loginPagePath() : urls.login;

  return (
    <header>
      <HeaderLogo minimal staticRender={staticRender} />
      {showMinimalHeaderSignIn && !staticRender && (
        <AnchorLink to={loginUrl} className={styles.loginLink}>
          <User className={styles.loginLinkIcon} />
          Sign In
        </AnchorLink>
      )}
    </header>
  );
};

SiteHeaderMinimal.propTypes = {
  showMinimalHeaderSignIn: PropTypes.bool,
  staticRender: PropTypes.bool,
};

SiteHeaderMinimal.defaultProps = {
  showMinimalHeaderSignIn: false,
  staticRender: false,
};

export default SiteHeaderMinimal;
