import createReducer from 'redux/createReducer';

import {
  RECOMMENDATIONS_MEAL_DEALS_PRODUCTS_FULFILLED,
  MEAL_DEALS_PENDING,
  MEAL_DEALS_FULFILLED,
  MEAL_DEALS_REJECTED,
} from '../actions/types';

const initialState = {};

export const loadingMealDeal = (state, action) => {
  const { mealDealId, loadingRecommendations } = action;
  return {
    ...state,
    ...{ [mealDealId]: { ...state[mealDealId], loading: true, loadingRecommendations } },
  };
};

export const loadedMealDeal = loadingRecommendations => (state, action) => {
  const { mealDealId } = action;
  return {
    ...state,
    ...{
      [mealDealId]: {
        ...state[mealDealId],
        loading: false,
        ...(loadingRecommendations && { loadingRecommendations: false }),
      },
    },
  };
};

export const updateRecommendation = (state, action) => {
  const {
    mealDealId,
    result: { products = {} },
  } = action;
  return {
    ...state,
    ...{
      [mealDealId]: {
        ...state[mealDealId],
        recommendedProducts: products,
        loadingRecommendations: false,
      },
    },
  };
};

const actionTypeReducerMap = new Map([
  [RECOMMENDATIONS_MEAL_DEALS_PRODUCTS_FULFILLED, updateRecommendation],
  [MEAL_DEALS_PENDING, loadingMealDeal],
  [MEAL_DEALS_FULFILLED, loadedMealDeal(null)],
  [MEAL_DEALS_REJECTED, loadedMealDeal(true)],
]);

export default createReducer(initialState, actionTypeReducerMap);
