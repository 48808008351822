/* eslint-disable no-param-reassign */
import env from 'env/env';

import {
  CALLTOACTION_BUTTON,
  CALLTOACTION_SLIDE,
  EXTENDED_NAVIGATION_CARD,
  EXTENDED_NAVIGATION_ROW,
  FULLWIDTH_NAVIGATION,
  IMAGE,
  RICH_TEXT,
  TRADING_CELL,
  VISUAL_NAVIGATION,
  CLICK_TO_BUY,
} from 'constants/aemComponentNames';

const aemComponents = [
  { name: 'Call to Action', resourceType: CALLTOACTION_BUTTON },
  { name: 'Click To Buy Final Slide', resourceType: CALLTOACTION_SLIDE },
  { name: 'Click to Buy', resourceType: CLICK_TO_BUY },
  { name: 'Extended Navigation Card Row', resourceType: EXTENDED_NAVIGATION_ROW },
  { name: 'Extended Navigation Card', resourceType: EXTENDED_NAVIGATION_CARD },
  { name: 'Full-width Navigation', resourceType: FULLWIDTH_NAVIGATION },
  { name: 'Image Component', resourceType: IMAGE },
  { name: 'Rich Text', resourceType: RICH_TEXT },
  { name: 'Trading Module', resourceType: TRADING_CELL },
  { name: 'Visual Navigation', resourceType: VISUAL_NAVIGATION },
];

const getAnalyticsName = ({ analytics: { promoName = '' } = {} } = {}) => promoName;

const getComponentId = ({ componentId = '' } = {}) => componentId;

const getComponentName = ({ name = '' } = {}) => name;

export const getCreativeName = ({ resourceType } = {}) =>
  getComponentName(
    aemComponents.find(
      ({ resourceType: matchingResourceType } = {}) => resourceType === matchingResourceType,
    ),
  );

export const createContentImpression = ({ component, position }) => ({
  creative: getCreativeName(component),
  id: getComponentId(component),
  name: getAnalyticsName(component),
  position,
});

export const getParsedGridPosition = ({ gridPosition = 0 } = {}) => parseInt(gridPosition, 10) || 0;

export const getPathTaxonomyType = path => {
  if (path.includes('/browse/entertaining')) return 'E';
  if (path.includes('/lists')) return 'L';
  if (path.includes('/browse/offers')) return 'O';
  return 'G';
};

export const getPathDepth = path =>
  path
    .split(/[?#]/)
    .shift()
    .split('/')
    .filter(segment => segment && segment !== env.root).length;

export const getPositionsGenerator = () => {
  const breaks = ['xs', 'sm', 'md', 'lg', 'default'];
  const position = {
    xs: 0,
    sm: 0,
    md: 0,
    lg: 0,
    default: 0,
  };
  return cqResponsive => {
    const visible = {};
    if (cqResponsive && cqResponsive.deviceBreakpoints) {
      cqResponsive.deviceBreakpoints.forEach(breakpoint => {
        visible[breakpoint.name] = breakpoint.visible;
        position[breakpoint.name] += breakpoint.visible ? 1 : 0;
      });
    } else {
      breaks.forEach(name => {
        visible[name] = true;
        position[name] += 1;
      });
    }

    return {
      xs: visible.xs ? position.xs : null,
      sm: visible.sm ? position.sm : null,
      md: visible.md ? position.md : null,
      lg: visible.lg ? position.lg : null,
      xl: visible.default ? position.default : null,
    };
  };
};

const alwaysVisible = {
  deviceBreakpoints: [
    { name: 'xs', visible: true },
    { name: 'sm', visible: true },
    { name: 'md', visible: true },
    { name: 'lg', visible: true },
    { name: 'xl', visible: true },
  ],
};

export const calculateProductsGridPositions = items => {
  // it differs from the aboove it calculates only searchProducts
  const breakpointsPosMap = {};
  const itemsPosMap = {};
  items.forEach(({ searchProduct, cqResponsive = alwaysVisible }) => {
    if (searchProduct && cqResponsive?.deviceBreakpoints) {
      cqResponsive.deviceBreakpoints.forEach(({ name, visible }) => {
        if (name === 'default') name = 'xl';
        if (!breakpointsPosMap[name]) breakpointsPosMap[name] = 0;
        if (!itemsPosMap[searchProduct]) itemsPosMap[searchProduct] = {};
        if (visible) {
          breakpointsPosMap[name] += 1;
          itemsPosMap[searchProduct][name] = breakpointsPosMap[name];
        }
      });
    }
  });
  return itemsPosMap;
};
