import queryString from 'query-string';

import { formatUrl, formatJohnLewisUrl } from './format-url';

export const buildUrl = (api = {}, path = '', query) =>
  api.query && query ? `${path}?${queryString.stringify(query)}` : path;

export const buildApiUrlOfType = (api, url = '') => {
  const { johnLewis = false } = api || {};
  return johnLewis ? formatJohnLewisUrl(url) : formatUrl(url);
};
