import { apiCallback } from 'api';
import { handlePersonalisationHeaderWithPath } from 'api/utils/personalisation-header';
import orderWithRecommendations from './orderWithRecommendations.graphql';
import ProductFragment from '../favourites/product-fragment.graphql';

const graphDef = graphQuery => {
  const query = () => ProductFragment + graphQuery;
  const variables = ({
    customerOrderId,
    recommendationsSize,
    recommendationsStart,
    productsSize,
    trolleyId,
  }) => ({
    customerOrderId,
    recommendationsSize,
    recommendationsStart,
    productsSize,
    trolleyId,
  });

  return {
    graph: true,
    query,
    variables,
    headers: { experience: 'shop-from-previous-order' },
    queryString: { tag: 'get-order' },
    timeout: {
      handleTimeout: true,
      response: 45000,
      deadline: 45000,
    },
  };
};

export default {
  post: apiCallback(
    graphDef(orderWithRecommendations),
    'post',
    handlePersonalisationHeaderWithPath('getOrderWithRecommendations'),
  ),
};
