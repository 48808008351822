import React from 'react';
import PropTypes from 'prop-types';

import { log } from 'utils/error-logging';
import styles from './index.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error) {
    const { children: { props: { componentId = '', resourceType = '' } = {} } = {} } = this.props;
    log(error, {
      logger: 'aem',
      section: 'CmsErrorBoundary',
      extra: { componentId, resourceType },
    });
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;

    if (hasError) {
      return <h1 className={styles.errorState}>Something went wrong.</h1>;
    }

    return children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node,
};

ErrorBoundary.defaultProps = {
  children: null,
};

export default ErrorBoundary;
