import { deepFreeze } from 'utils/deepFreeze';

export interface Orders {
  loading: boolean;
  orderIds: string[];
}

export interface OrdersUserStats {
  customerHasACompletedOrder: boolean;
  daysSinceLastCompletedOrder: number;
}

export interface OrdersState {
  cancellationsInProgress: string[];
  completedOrders: Orders;
  pendingOrders: Orders;
  previousOrders: Orders;
  recentOrders: Orders;
  userStats?: OrdersUserStats;
}

export const initialState: Readonly<OrdersState> = deepFreeze({
  cancellationsInProgress: [],
  completedOrders: {
    loading: false,
    orderIds: [],
  },
  pendingOrders: {
    loading: false,
    orderIds: [],
  },
  previousOrders: {
    loading: false,
    orderIds: [],
  },
  recentOrders: {
    loading: false,
    orderIds: [],
  },
  userStats: undefined,
});
