export default (
  componentId: string,
  analytics: {
    promoName: string;
    enhancedEcommerceInternalPromotion: string;
    assetModuleType: string;
  },
  creative: string,
  position: string | undefined,
) => {
  const { promoName, enhancedEcommerceInternalPromotion, assetModuleType } = analytics || {};

  return {
    'data-analytics-root': 'true',
    'data-analytics-id': componentId,
    'data-analytics-name': promoName,
    'data-analytics-position': position,
    'data-analytics-creative': creative,
    'data-analytics-component-type': 'internal-promotion',
    'data-analytics-asset-module-type': assetModuleType,
    'data-analytics-enhanced-ecommerce-internal-promotion': enhancedEcommerceInternalPromotion,
  };
};
