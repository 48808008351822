import React from 'react';
import styles from 'components/RichText/RichText.scss';
import { extractInlineStyles } from '../utils';
import { RichTextNode } from '../types';

const RichTextBold = ({ children, value }: RichTextNode) => (
  <strong className={styles.richTextStrong} style={extractInlineStyles(value)}>
    {children}
  </strong>
);

RichTextBold.displayName = 'RichTextBold';

export default RichTextBold;
