import { apiPromiseTypes } from 'redux/middleware/api-promise/action-types';

export const MEAL_DEALS_PENDING = 'waitrose/mealDeals/MEAL_DEALS_PENDING';
export const MEAL_DEALS_FULFILLED = 'waitrose/mealDeals/MEAL_DEALS_FULFILLED';
export const MEAL_DEALS_REJECTED = 'waitrose/mealDeals/MEAL_DEALS_REJECTED';

export const GET_MEAL_DEAL = apiPromiseTypes('offers-experience', 'get');
export const MEAL_DEAL_OFFER_NOT_FOUND = 'waitrose/mealDeals/MEAL_DEAL_OFFER_NOT_FOUND';
export const MEAL_DEAL_BRANCH_NOT_APPLICABLE = 'waitrose/mealDeals/MEAL_DEAL_BRANCH_NOT_APPLICABLE';

export const RECOMMENDATIONS_MEAL_DEALS_PRODUCTS_FULFILLED =
  'waitrose/recommendations/RECOMMENDATIONS_MEAL_DEALS_PRODUCTS_FULFILLED';
