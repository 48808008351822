import type { Fetcher, FetcherArgs } from './fetcher-types';

export const conditional =
  <LocationState = never, MatchParams extends Record<string, string> = never>(
    condition: (args: FetcherArgs<LocationState, MatchParams>) => boolean,
    fetcher: Fetcher<LocationState, MatchParams>,
  ) =>
  (args: FetcherArgs<LocationState, MatchParams>) =>
  (dispatch: WtrDispatch) => {
    if (condition(args)) {
      return dispatch(fetcher(args));
    }

    return Promise.resolve();
  };
