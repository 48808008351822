import { retry } from 'utils/retry';
import type { Fetcher, FetcherArgs } from './fetcher-types';

/**
 * Makes a fetcher that lazily imports the module with a `default` export
 * @param importFn The function to dynamically import the fetcher
 *
 * @example
 * const cmsPageFetcher = lazyFetcher(() => import('redux/fetchers/cms-page'));
 */
export function lazyFetcher<
  LocationState,
  MatchParams extends Record<string, string>,
  TImportFn extends { default: Fetcher<LocationState, MatchParams> },
>(importFn: () => Promise<TImportFn>): Fetcher<LocationState, MatchParams>;

/**
 * Makes a fetcher that lazily imports the module with a named export of the fetcher
 * @param importFn The function to dynamically import the fetcher
 * @param exportName The name of the exported fetcher
 *
 * @example
 * const favouritesFetcher = lazyFetcher(
 *   () => import('redux/fetchers/favourites'),
 *   'favouritesFetcher',
 * );
 */
export function lazyFetcher<
  LocationState,
  MatchParams extends Record<string, string>,
  TImportFn extends Record<string, unknown>,
  TExportName extends keyof TImportFn,
>(importFn: () => Promise<TImportFn>, exportName: TExportName): Fetcher<LocationState, MatchParams>;

/**
 * Makes a fetcher that lazily imports the module with a named export of a function that creates a fetcher and the function to actually make the fetcher
 * @param importFn The function to dynamically import the fetcher
 * @param exportName The name of the exported fetcher
 * @param fetcherFn The function to construct the fetcher
 *
 * @example
 * const cmsExperienceFragment = (...args) =>
 *   lazyFetcher(
 *     () => import('redux/fetchers/experience-fragment'),
 *     'default',
 *     fetcher => fetcher(...args),
 *   );
 */
export function lazyFetcher<
  LocationState,
  MatchParams extends Record<string, string>,
  TImportFn extends Record<string, unknown>,
  TExportName extends keyof TImportFn,
>(
  importFn: () => Promise<TImportFn>,
  exportName: TExportName,
  fetcherFn: (fetcher: TImportFn[TExportName]) => Fetcher<LocationState, MatchParams>,
): Fetcher<LocationState, MatchParams>;

// Implementation
export function lazyFetcher<
  TImportFn extends Record<string, unknown>,
  TExportName extends keyof TImportFn,
  LocationState = never,
  MatchParams extends Record<string, string> = never,
>(
  importFn: () => Promise<TImportFn>,
  exportName: TExportName | 'default' = 'default',
  fetcherFn?: (fetcher: TImportFn[TExportName]) => Fetcher<LocationState, MatchParams>,
) {
  return (args: FetcherArgs<LocationState, MatchParams>) => async (dispatch: WtrDispatch) => {
    // try 3 times, original and two more, with no delay time as the CDN should not have same behaviour as an API
    const module = await retry(importFn, [0, 0]);
    if (module) {
      const fetcher = module[exportName];
      if (fetcherFn) {
        return dispatch(fetcherFn(fetcher as never)(args));
      }
      return dispatch((fetcher as Fetcher<LocationState, MatchParams>)(args));
    }

    return Promise.reject(new Error('Module could not be loaded'));
  };
}
