import { getFulfilmentTypeByURL } from 'components/BookSlot/utils/get-fulfilment-type-by-url';

type FulfilmentType = Record<string, any> | undefined;
let previousFulfilmentType: FulfilmentType;

const shouldResetState = (
  prevFulfilmentType: FulfilmentType,
  currentFulfilmentType: FulfilmentType,
) => {
  if (!currentFulfilmentType) {
    return true;
  }

  if (!prevFulfilmentType && !!currentFulfilmentType) {
    return true;
  }

  if (prevFulfilmentType?.category !== currentFulfilmentType.category) {
    return true;
  }

  return false;
};

const reducer = (state: WtrState, action: { payload: { location: { pathname: string } } }) => {
  const currentFulfilmentType = getFulfilmentTypeByURL(action.payload.location.pathname);
  let result;

  if (shouldResetState(previousFulfilmentType, currentFulfilmentType)) {
    result = {
      ...state,
      branches: [],
    };
  }

  result = {
    ...state,
  };

  previousFulfilmentType = currentFulfilmentType;

  return result;
};

export default reducer;
