import React, { useContext } from 'react';
import propTypes from 'prop-types';

import ClickToBuyInner, { ClickToBuyContext } from 'components/ClickToBuy/ClickToBuyInner';
import AemComponent from 'components/AemComponent';
import { CLICK_TO_BUY_NAME } from 'components/AemComponent/analyticsConstants';

export const useClickToBuyContext = () => useContext(ClickToBuyContext);

const ClickToBuy = ({
  analyticsTags,
  cqResponsiveClasses,
  paragraphSystem,
  position,
  taxonomyLevel,
}) => (
  <ClickToBuyInner
    analyticsTags={analyticsTags}
    cqResponsiveClasses={cqResponsiveClasses}
    position={position}
  >
    <AemComponent component={paragraphSystem} taxonomyLevel={taxonomyLevel} position={position} />
  </ClickToBuyInner>
);

ClickToBuy.propTypes = {
  analyticsTags: propTypes.shape({}),
  paragraphSystem: propTypes.shape({}),
  cqResponsiveClasses: propTypes.arrayOf(propTypes.string),
  taxonomyLevel: propTypes.string,
  position: propTypes.string.isRequired,
};

ClickToBuy.defaultProps = {
  analyticsTags: undefined,
  cqResponsiveClasses: [],
  paragraphSystem: undefined,
  taxonomyLevel: '',
};

ClickToBuy.displayName = CLICK_TO_BUY_NAME;

export default ClickToBuy;
