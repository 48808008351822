import React from 'react';
import PropTypes from 'prop-types';

import SpecialistShopsLinks from 'components/SpecialistShopsLinks';
import styles from 'components/SiteHeader/TopNav/TopNav.scss';
import CustomerServiceLinks from 'components/CustomerServiceLinks';

const TopNav = ({ staticRender }) => (
  <nav
    className={`visible-lg-block visible-xl-block ${styles.wrapper}`}
    role="navigation"
    aria-label="Top Navigation Bar"
  >
    <div className="container-fluid">
      <div className={`row ${styles.row}`}>
        <SpecialistShopsLinks />
        {!staticRender ? <CustomerServiceLinks /> : null}
      </div>
    </div>
  </nav>
);

TopNav.displayName = 'TopNav';

TopNav.propTypes = {
  staticRender: PropTypes.bool,
};

TopNav.defaultProps = {
  staticRender: false,
};

export default TopNav;
