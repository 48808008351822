/* eslint-disable jsx-a11y/no-noninteractive-tabindex, jsx-a11y/no-noninteractive-element-interactions */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import kebabCase from 'lodash/kebabCase';
import debounce from 'lodash/debounce';

import { KEY_ENTER } from 'constants/keys';

import { slowlyScrollElementHorizontallyIntoView } from 'utils/scroll';

import styles from 'components/MultiSearch/Button/MultiSearchButton.scss';

const SCROLL_DEBOUNCE_MS = 200;
const BUTTON_SCROLL_PADDING = 150;

class MultiSearchButton extends Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
    selected: PropTypes.bool,
    value: PropTypes.string.isRequired,
  };

  static defaultProps = {
    selected: false,
  };

  constructor(props) {
    super(props);
    this.element = React.createRef();
    this.scrollIntoView = debounce(this.scrollIntoView, SCROLL_DEBOUNCE_MS);
  }

  componentDidMount() {
    this.scrollIntoView();
  }

  componentDidUpdate(prevProps) {
    const { selected: prevSelected } = prevProps;
    const { selected } = this.props;

    if (!prevSelected && selected) this.scrollIntoView();
  }

  handler = event => {
    const { onClick } = this.props;

    // Prevent Chrome's crude scrolling into view before firing handler
    event.preventDefault();

    return onClick(event);
  };

  onKeyDown = event => {
    if (event.charCode === KEY_ENTER || event.keyCode === KEY_ENTER) {
      return this.handler(event);
    }

    return false;
  };

  scrollIntoView = () => {
    setTimeout(() => {
      const { selected } = this.props;
      const { current: element } = this.element;

      if (selected && element) {
        slowlyScrollElementHorizontallyIntoView(element, BUTTON_SCROLL_PADDING);
      }
    }, 0);
  };

  render() {
    const { selected, value } = this.props;
    const id = `msv-${kebabCase(value)}`;

    return (
      <div className={styles.multiSearchButton} ref={this.element}>
        <input
          className={styles.input}
          defaultChecked={selected}
          id={id}
          name="multi-search-value"
          type="radio"
          value={value}
        />
        <label
          className={classNames(styles.button, {
            [styles.selected]: selected,
          })}
          data-testid={`msv-${selected ? 'active' : 'label'}`}
          htmlFor={id}
          onClick={this.handler}
          onKeyDown={this.onKeyDown}
          tabIndex="0"
        >
          {value}
        </label>
      </div>
    );
  }
}

export default MultiSearchButton;

/* eslint-enable jsx-a11y/no-noninteractive-tabindex, jsx-a11y/no-noninteractive-element-interactions */
