export const CALLTOACTION_BUTTON_NAME = 'Call To Action Button';
export const CLICK_TO_BUY_NAME = 'Click To Buy';
export const CONTACT_US_NAME = 'ContactUsCard';
export const CONTAINER_NAME = 'Container';
export const CONTENT_FRAGMENT_NAME = 'ContentFragment';
export const EXTENDED_NAVIGATION_CARD_NAME = 'Extended Navigation Card';
export const EXTENDED_NAVIGATION_ROW_NAME = 'Full Width Row';
export const FORM_NAME = 'CustomerServiceForms';
export const FULLWIDTH_NAVIGATION_NAME = 'Full Width Navigation';
export const GENERIC_NAVIGATION_NAME = 'CmsCategoryLinks';
export const HERO_BANNER_NAME = 'Hero Banner';
export const HORIZONTAL_LINE_NAME = 'Horizontal Line';
export const IMAGE_NAME = 'Image';
export const RICH_TEXT_NAME = 'Rich Text';
export const SHOP_WINDOWS_NAME = 'Shop Windows';
export const TRADING_CELL_NAME = 'Trading Module';
export const VIDEO_NAME = 'Video';
export const VISUAL_NAVIGATION_NAME = 'Visual Navigation';
