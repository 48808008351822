import { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { KEY_ESCAPE } from 'constants/keys';
import { MAX_SEARCH_WORDS, MAX_SEARCH_CHARACTERS } from 'constants/search';
import SETTINGS from 'constants/settings';

import { sanitizeString, removeDiacriticsFromLetters } from 'utils/format';
import history from 'utils/history';
import { limitWords } from 'utils/limit-words';
import { limitCharacters } from 'utils/limit-characters';
import { slowlyScrollPageVerticallyToTop } from 'utils/scroll';

import {
  RESET_CURRENT_RECIPES_PAGE,
  RESET_FILTERED_SORTED_RECIPES,
} from 'redux/modules/cms-hybrid-recipes-page/actions/types';
import { getURLParamValue } from 'redux/modules/recipes/utils/get-url-param-value';

import { getMinSuggestChars } from 'components/Search/utils/get-min-suggest-chars';
import { saveSearchTerm } from 'components/Search/SearchForm/save-search-term';

import { useOnClickOutside } from 'hooks';
import { yieldToMain } from 'utils/yield-to-main';

export const useSearchForm = ({
  autoSuggestActive,
  eligibleForAutoSuggest,
  functionalCookieConsent,
  initialValue,
  onChange,
  onClear,
  onSearch,
  onSubmit,
  recipes,
  url,
  searchType,
}) => {
  const dispatch = useDispatch();
  const inputRef = useRef();
  const insideRef = useRef();
  const [hasFocus, setHasFocus] = useState(false);
  const [hasHighlighted, setHasHighlighted] = useState(false);
  const [showSearchHistory, setShowSearchHistory] = useState(false);
  const [value, setValue] = useState(initialValue);
  const hasValue = !!value?.length;

  const focusInput = useCallback(focus => {
    const { current: input } = inputRef;
    if (!input) return;

    if (focus) input.focus();
    else input.blur();
  }, []);

  const handleChange = useCallback(
    async event => {
      const eventValue = event.target.value;
      const searchTerm = limitCharacters(
        limitWords(sanitizeString(eventValue || '', true), MAX_SEARCH_WORDS),
        MAX_SEARCH_CHARACTERS,
      );
      setValue(searchTerm);

      await yieldToMain();

      const isSameSearchTerm = value === searchTerm;
      const minimumCharacters = getMinSuggestChars();
      setShowSearchHistory(searchTerm.length === 0);

      if (
        eligibleForAutoSuggest &&
        searchTerm.trim().length >= minimumCharacters &&
        !isSameSearchTerm &&
        onChange
      ) {
        dispatch(onChange(removeDiacriticsFromLetters(searchTerm)));
      }

      if (searchTerm.length < 2 && autoSuggestActive && onClear) dispatch(onClear());
    },
    [autoSuggestActive, dispatch, eligibleForAutoSuggest, onChange, onClear, value],
  );

  const handleFocus = useCallback(() => {
    if (!hasFocus) {
      focusInput(true);
      setHasFocus(true);
      setShowSearchHistory(!hasValue);
    }
  }, [focusInput, hasFocus, hasValue]);

  /*
  On first click it will focus and highlight the word,
  On second and after clicks the cursor will appear on position
  */
  const handleClick = useCallback(() => {
    const { current: input } = inputRef;

    if (!hasHighlighted && hasValue) {
      input.select();
      input.focus();

      setHasHighlighted(true);
      return;
    }

    setHasHighlighted(!!hasFocus);
  }, [hasFocus, hasValue, hasHighlighted]);

  const handleBlur = useCallback(() => {
    if (hasFocus) {
      focusInput(false);
      setHasFocus(false);
      setHasHighlighted(false);
      setShowSearchHistory(false);
    }
  }, [focusInput, hasFocus]);

  const handleClear = useCallback(
    event => {
      if (event) event.preventDefault();
      focusInput(true);
      if (onClear) dispatch(onClear());
      setShowSearchHistory(true);
      setValue('');
      setHasFocus(true);
    },
    [focusInput, dispatch, onClear],
  );

  const handleKeyDown = useCallback(
    event => {
      if (event.keyCode === KEY_ESCAPE) {
        handleBlur();
      }
    },
    [handleBlur],
  );

  const onRecipesSubmit = useCallback(
    ({ searchTerm }) => {
      if (searchTerm !== getURLParamValue('searchTerm')) {
        dispatch({
          type: RESET_CURRENT_RECIPES_PAGE,
        });
        dispatch({
          type: RESET_FILTERED_SORTED_RECIPES,
        });
      }

      return encodeURIComponent(searchTerm).replace(/%20/g, '+').toString();
    },
    [dispatch],
  );

  const searchForTerm = useCallback(
    async ({ searchTerm, origin }) => {
      const trimmedTerm = searchTerm.trim();
      const queryString = recipes
        ? onRecipesSubmit({ searchTerm })
        : dispatch(
            onSubmit({
              searchTerm: removeDiacriticsFromLetters(trimmedTerm),
              origin,
            }),
          );

      setValue(trimmedTerm);

      await yieldToMain();

      if (trimmedTerm !== '') {
        if (onClear) dispatch(onClear());
        history.push(url ? url(queryString) : `${SETTINGS.searchPath}?${queryString}`);
        slowlyScrollPageVerticallyToTop();
      }

      handleBlur();

      if (functionalCookieConsent) {
        saveSearchTerm(trimmedTerm, searchType);
      }

      if (onSearch) dispatch(onSearch());
    },
    [
      recipes,
      dispatch,
      onSubmit,
      onRecipesSubmit,
      handleBlur,
      functionalCookieConsent,
      onSearch,
      onClear,
      url,
      searchType,
    ],
  );

  const handleSubmit = useCallback(
    event => {
      if (event) event.preventDefault();
      searchForTerm({ searchTerm: value, origin: 'regular' });
    },
    [searchForTerm, value],
  );

  const handleSelectedSearchHistoryItem = useCallback(
    searchTerm => {
      setShowSearchHistory(false);
      setHasFocus(false);
      searchForTerm({ searchTerm, origin: 'search history' });
    },
    [searchForTerm],
  );

  const handleSelectedAutoCompleteItem = useCallback(
    searchTerm => {
      searchForTerm({ searchTerm, origin: 'auto complete' });
    },
    [searchForTerm],
  );

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useOnClickOutside(insideRef, () => {
    handleBlur();
  });

  return {
    handleChange,
    handleClear,
    handleClick,
    handleFocus,
    handleKeyDown,
    handleSelectedAutoCompleteItem,
    handleSelectedSearchHistoryItem,
    handleSubmit,
    hasFocus,
    inputRef,
    insideRef,
    setValue,
    showSearchHistory,
    value,
  };
};
