import React from 'react';
import { TableHeading } from '@johnlewispartnership/wtr-ingredients/ingredients/Table';

import { buildElementStyling } from '../utils';
import { RichTextTableHeadingProps } from '../types';

// This stores the table headings, referenced by index. They are then used in RichTextTableCell as a data-heading-label attribute on the component
// This allows us to style the RichText table with the corresponding headings in RichText.scss for mobile viewports
// In theory the headings should always match as the heading and the corresponding cell should be rendered before the next table is rendered
export const renderedTableHeadings: { [key: string]: string } = {};

const RichTextTableHeading = ({ children, value, index }: RichTextTableHeadingProps) => {
  const { className, style } = buildElementStyling({
    baseClass: 'richTextTableHeading',
    value,
  });

  renderedTableHeadings[index] = children?.[0] as string;

  return (
    <TableHeading className={className} style={style} styleAs="paragraphSmall">
      {children}
    </TableHeading>
  );
};

RichTextTableHeading.displayName = 'RichTextTableHeading';

export default RichTextTableHeading;
