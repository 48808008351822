import React from 'react';
import PropTypes from 'prop-types';
import { pure } from 'recompose';

import Roundel from 'components/Roundel/Roundel';

import styles from 'components/PromoIconography/PromoRoundel/PromoRoundel.scss';

const PromoRoundel = pure(({ display, image, supercharged }) => {
  if (!display || !image || supercharged) return null;

  return (
    <div className={styles.promoRoundel} data-testid="promo-roundel">
      <Roundel image={image} />
    </div>
  );
});

PromoRoundel.displayName = 'PromoRoundel';

PromoRoundel.propTypes = {
  display: PropTypes.bool,
  image: PropTypes.object,
  supercharged: PropTypes.bool,
};

export default PromoRoundel;
