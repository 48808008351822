export const ACCORDION = 'waitrosegroceriescms/components/content/accordion';
export const AMEND_ORDER_WIDGET =
  'waitrosegroceriescms/components/content/tradingcontent/amendorder';
export const CALLTOACTION_BUTTON = 'waitrosegroceriescms/components/content/ctabutton';
export const CALLTOACTION_SLIDE =
  'waitrosegroceriescms/components/content/carousels/calltoactionslide';
export const CAROUSEL = 'waitrosegroceriescms/components/content/carousel';
export const CLICK_TO_BUY = 'waitrosegroceriescms/components/structure/wrappers/clicktobuywrapper';
export const COLUMN_LAYOUT =
  'waitrosegroceriescms/components/content/tradingcontent/center-column-layout';
export const CONTACT_US_CONTENT_FRAGMENT =
  '/conf/waitrosegroceriescms/cf-models/settings/dam/cfm/models/contact-us-card-model';
export const CONTAINER = 'waitrosegroceriescms/components/content/container';
export const CONTENT_FRAGMENT = 'waitrosegroceriescms/components/content/contentfragment';
export const CUSTOM_CONTENT = 'waitrosegroceriescms/components/content/customContent';
export const CUSTOMER_FEEDBACK = 'waitrosegroceriescms/components/content/wasthishelpful';
export const CUSTOMER_SEARCH_GRID = 'waitrosegroceriescms/components/structure/customersearchgrid';
export const DIVIDER = 'waitrosegroceriescms/components/content/divider';
export const DYNAMIC_MEDIA = 'dam/components/scene7/dynamicmedia';
export const EDITABLE_RESPONSIVE_GRID =
  'waitrosegroceriescms/components/structure/editableresponsivegrid';
export const EXPERIENCE_FRAGMENT_EDITABLE_RESPONSIVE_GRID =
  'waitrosegroceriescms/components/structure/xfeditableresponsivegrid';
export const EXTENDED_NAVIGATION_CARD =
  'waitrosegroceriescms/components/content/tradingcontent/extendednavigationcard';
export const EXTENDED_NAVIGATION_ROW =
  'waitrosegroceriescms/components/structure/wrappers/extendednavigationcardwrapper';
export const FORGOTTEN_FAVOURITES =
  'waitrosegroceriescms/components/content/grids/missedfavourites';
export const FORM = 'waitrosegroceriescms/components/content/clientdefinedform';
export const COMPETITIONS_FORM = 'waitrosegroceriescms/components/content/competitions';
export const FULLWIDTH_INFORMATION =
  'waitrosegroceriescms/components/content/tradingcontent/fullwidthinformation';
export const FULLWIDTH_NAVIGATION =
  'waitrosegroceriescms/components/content/tradingcontent/fullwidthnavigation';
export const GENERIC_NAVIGATION =
  'waitrosegroceriescms/components/content/navigation/genericnavigation';
export const HERO_BANNER = 'waitrosegroceriescms/components/content/tradingcontent/herobanner';
export const HERO_PRODUCT = 'waitrosegroceriescms/components/content/heroProduct';
export const HORIZONTAL_LINE = 'waitrosegroceriescms/components/content/horizontalline';
export const IMAGE = 'waitrosegroceriescms/components/content/image';
export const IMAGE_WITH_TEXT = 'waitrosegroceriescms/components/content/imageWithTextBlock';
export const MISSED_OFFERS = 'waitrosegroceriescms/components/content/grids/missedoffers';
export const NAV_LINKS = 'waitrosegroceriescms/components/content/navigation/navigationlinks';
export const OFFERS_HEADER = 'waitrosegroceriescms/components/content/navigation/childpagenav';
export const PAGE_TITLE = 'waitrosegroceriescms/components/content/title';
export const PREVIEW_RECIPE = 'waitrosegroceriescms/components/content/recipePreview';
export const PRODUCT_CAROUSEL = 'waitrosegroceriescms/components/content/carousels/productcarousel';
export const PRODUCT_COLLECTION_GRID =
  'waitrosegroceriescms/components/structure/productcollectiongrid';
export const PRODUCT_GRID = 'waitrosegroceriescms/components/structure/productgrid';
export const PRODUCT_HOVER = 'waitrosegroceriescms/components/content/producthoverfordetails';
export const PRODUCT_MODULE = 'waitrosegroceriescms/components/content/productModule';
export const RECIPE = 'waitrosegroceriescms/components/content/recipe';
export const RECIPE_CARDS_GRID = 'waitrosegroceriescms/components/content/recipecardsgrid';
export const RECIPE_DROPDOWN = 'waitrosegroceriescms/components/content/recipeDropdown';
export const RECIPE_SEARCHBAR = 'waitrosegroceriescms/components/content/recipesearchbar';
export const REFERENCE_COMPONENT =
  'waitrosegroceriescms/components/content/tradingcontent/reference/tradingcellreference';
export const RESPONSIVE_GRID = 'waitrosegroceriescms/components/structure/responsivegrid';
export const RICH_TEXT = 'waitrosegroceriescms/components/content/richtext';
export const SECTION_TITLE = 'waitrosegroceriescms/components/content/tradingcontent/sectiontitle';
export const SHOP_FROM_RECENT_ORDER =
  'waitrosegroceriescms/components/content/tradingcontent/recentorder';
export const SHOP_WINDOW = 'waitrosegroceriescms/components/content/homepage/shopwindow';
export const SHOP_WINDOWS = 'waitrosegroceriescms/components/content/homepage/shopwindows';
export const TEXT = 'waitrosegroceriescms/components/content/text';
export const TRADING_CELL = 'waitrosegroceriescms/components/content/tradingcontent/tradingcell';
export const VIDEO = 'waitrosegroceriescms/components/content/video';
export const VISUAL_NAVIGATION =
  'waitrosegroceriescms/components/content/tradingcontent/visualnavigation';
export const VISUAL_NAVIGATION_ROW =
  'waitrosegroceriescms/components/structure/wrappers/visualnavigationwrapper';
export const WAITROSE_MOBILE_CONTENT =
  'waitrosegroceriescms/components/content/mobile/mobilemodule';

// AEM PAGES
export const BROWSE_PAGE = 'waitrosegroceriescms/components/structure/browsepage';
export const CUSTOM_AISLE_PAGE = 'waitrosegroceriescms/components/structure/customaislepage';
export const DEFAULT_SEARCH_PAGE = 'waitrosegroceriescms/components/structure/defaultsearchpage';
export const EDITABLE_PAGE = 'waitrosegroceriescms/components/structure/editablepage';
export const HOME_PAGE = 'waitrosegroceriescms/components/structure/homepage';
export const LIBRARY_PAGE = 'waitrosegroceriescms/components/structure/librarypage';
export const OFFERS_CATEGORY_PAGE = 'waitrosegroceriescms/components/structure/offerscategory';
export const OFFERS_PAGE = 'waitrosegroceriescms/components/structure/offerslandingpage';
export const OFFERS_TYPE_PAGE = 'waitrosegroceriescms/components/structure/offertypepage';
export const PAGE = 'waitrosegroceriescms/components/structure/page';
export const SEARCH_PAGE = 'waitrosegroceriescms/components/structure/searchPage';
export const CARD_CONTAINER = 'waitrosegroceriescms/components/content/cardcontainer';
export const EXPERIENCE_FRAGMENT_REFERENCE =
  'waitrosegroceriescms/components/content/experiencefragmentreference';
