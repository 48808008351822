import { FailedOrderPaymentResponse } from 'api/definitions/failed-order-payments';
import { GiftVoucherStatus } from 'redux/modules/order/state/initial-state';
import { deepFreeze } from 'utils/deepFreeze';

export const placeOrderOriginMain = 'main' as const;
export const placeOrderOriginMobile = 'mobile' as const;

type ResolveOrderPaymentNotLoaded = {
  loading: false;
  loaded: false;
};

type ResolveOrderPaymentLoading = {
  loading: true;
  loaded: false;
};

export type ResolveOrderPaymentLoaded = {
  loading: false;
  loaded: true;
} & FailedOrderPaymentResponse;

export interface CheckoutState {
  currentApiRequest: string | null;
  errorResponse: { code: string } | undefined;
  errorSource: string | null;
  errorStatusCode: number | null;
  giftVoucherInlineErrors: null | Record<string, GiftVoucherStatus>;
  newCardBillingAddressId: string | undefined;
  orderNumber: string | null;
  partnerDiscountConfirmationModalIsShown: boolean;
  requestTimedOut: boolean;
  savedCardBillingAddressId: string | undefined;
  sessionTimedOut: boolean;
  waiting: boolean;
  TRASessionId: string | undefined;
  saveNewCard: boolean | undefined;
  /**
   * Defines that the page finished loading. This is set by the route files
   */
  checkoutLoaded: boolean;
  placeOrderOrigin: typeof placeOrderOriginMain | typeof placeOrderOriginMobile | undefined;
  placeOrderOriginMobileAvailable: boolean;
  resolveOrderPayment: Record<
    string,
    | ResolveOrderPaymentNotLoaded
    | ResolveOrderPaymentLoading
    | ResolveOrderPaymentLoaded
    | undefined
  >;
}

const initialState: Readonly<CheckoutState> = deepFreeze({
  currentApiRequest: null,
  errorResponse: undefined,
  errorSource: null,
  errorStatusCode: null,
  giftVoucherInlineErrors: null,
  newCardBillingAddressId: undefined,
  orderNumber: null,
  partnerDiscountConfirmationModalIsShown: false,
  requestTimedOut: false,
  savedCardBillingAddressId: undefined,
  sessionTimedOut: false,
  waiting: false,
  TRASessionId: undefined,
  saveNewCard: undefined,
  checkoutLoaded: false,
  placeOrderOrigin: undefined,
  placeOrderOriginMobileAvailable: false,
  resolveOrderPayment: {},
});

export default initialState;
