import React from 'react';
import PropTypes from 'prop-types';

import AemComponent from 'components/AemComponent';
import { calculateColumnsRelativeToParent } from './calculateColumnsRelativeToParent';

const AemComponentGrid = ({
  childComponents,
  cqResponsive,
  position,
  taxonomyLevel,
  name,
  asContentLocation,
  preloadImages,
}) => {
  if (!childComponents || childComponents.length < 1) return null;

  const renderAemComponents = () =>
    childComponents.map((component, index) => {
      const modifiedComponent = {
        ...component,
        cqResponsive: cqResponsive
          ? calculateColumnsRelativeToParent(cqResponsive, component.cqResponsive)
          : component.cqResponsive,
      };

      return (
        <AemComponent
          component={modifiedComponent}
          key={component.componentId}
          taxonomyLevel={taxonomyLevel}
          position={`${position}-${index + 1}`}
          index={index}
          preloadImages={preloadImages}
        />
      );
    });

  return asContentLocation ? (
    <div id={`cms-${name}-content`} data-testid="cms-content-location">
      {renderAemComponents()}
    </div>
  ) : (
    renderAemComponents()
  );
};

AemComponentGrid.propTypes = {
  childComponents: PropTypes.arrayOf(
    PropTypes.shape({
      componentId: PropTypes.string,
      resourceType: PropTypes.string,
    }),
  ),
  cqResponsive: PropTypes.shape({
    deviceBreakpoints: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  position: PropTypes.string,
  taxonomyLevel: PropTypes.string,
  name: PropTypes.string,
  asContentLocation: PropTypes.bool,
  preloadImages: PropTypes.bool,
};

AemComponentGrid.defaultProps = {
  childComponents: [],
  cqResponsive: undefined,
  position: null,
  taxonomyLevel: null,
  name: 'content-location',
  asContentLocation: false,
  preloadImages: false,
};

AemComponentGrid.displayName = 'AemComponentGrid';

export default AemComponentGrid;
