import { createSelector, lruMemoize } from 'reselect';
import isEqual from 'lodash/isEqual';

import extractLineNumberFromSku from 'utils/extract-linenumber-from-sku';

import { categoryNameToUrl } from 'utils/format';
import { isPersonalisableProduct } from 'utils/product';
import { pathJoin } from 'utils/general';

const EMPTY_ARRAY = [];
const EMPTY_OBJECT = {};

const getEntities = ({ entities } = {}) => entities;
const getLineNumber = (_state, lineNumber) => lineNumber;
const getLineNumberFromSku = (_state, productId = '') => extractLineNumberFromSku(productId);

export const getProducts = createSelector(getEntities, ({ products } = {}) => products);

export const makeGetProductByLineNumber = () =>
  createSelector([getProducts, getLineNumber], (products = {}, lineNumber) => products[lineNumber]);

export const getProductByLineNumber = makeGetProductByLineNumber();

export const makeGetProductById = () =>
  createSelector(
    [getProducts, getLineNumberFromSku],
    (products = {}, lineNumber) => products[lineNumber],
    {
      memoize: lruMemoize,
      memoizeOptions: {
        resultEqualityCheck: isEqual,
      },
    },
  );

export const getProductById = makeGetProductById();

export const getProductClickData = createSelector(
  [getProductById],
  ({
    sponsorshipId,
    metadata,
    brand,
    categories,
    name,
    id,
    currentSaleUnitPrice,
    promotion,
    productType,
  } = {}) => ({
    sponsorshipId,
    metadata,
    brand,
    categories: categories?.map(category => ({ name: category.name })),
    name,
    id,
    currentSaleUnitPrice,
    promotion,
    productType,
  }),
  {
    memoize: lruMemoize,
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  },
);

export const getProductBadgeText = createSelector(getProductById, (product = {}) =>
  isPersonalisableProduct(product) ? 'Personalise' : undefined,
);

export const makeGetProductBreadcrumbs = () =>
  createSelector(getProductById, ({ categories = [] } = {}) =>
    categories?.map(({ name }) => ({
      name,
      urlName: categoryNameToUrl(name).toLowerCase(),
    })),
  );

export const extractParentCategory = ({ categories = [] } = {}) => {
  if (categories === null) {
    return EMPTY_OBJECT;
  }

  const { id, name } = categories?.[categories.length - 1] || {};

  const url = pathJoin(
    '/ecom/shop/browse',
    ...categories.map(crumb => categoryNameToUrl(crumb.name).toLowerCase()),
  );

  return { id, name, url };
};

export const getProductBreadcrumbs = makeGetProductBreadcrumbs();

export const makeGetCategoryParentInfoByProductId = () =>
  createSelector(getProductById, extractParentCategory);

export const getCategoryParentInfoByProductId = makeGetCategoryParentInfoByProductId();

export const makeGetAvailableUnits = () =>
  createSelector(getProductById, ({ weights } = {}) => weights?.uoms ?? EMPTY_ARRAY, {
    memoizeOptions: {
      resultEqualityCheck: isEqual,
    },
  });

export const getAvailableUnits = makeGetAvailableUnits();

export const getProductIdByLineNumber = createSelector(getProductByLineNumber, ({ id } = {}) => id);
