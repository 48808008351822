import PropTypes from 'prop-types';

import { LinkType } from './link';

export const textAreaShape = {
  buttonStyle: PropTypes.oneOf(['primary', 'primaryLight', 'secondary']),
  disclaimer: PropTypes.string,
  displayDisclaimer: PropTypes.bool,
  heading: PropTypes.string,
  link: LinkType,
  offerHeading: PropTypes.string,
  subheading: PropTypes.string,
  supportingTitle: PropTypes.string,
  theme: PropTypes.oneOf(['light', 'red']),
};

export const TextAreaType = PropTypes.shape({ ...textAreaShape });
