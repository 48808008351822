import root from 'window-or-global';
import throttle from 'lodash/throttle';

import { scrollTo } from 'utils/scroll-to-wrapper';

const scrollWhenReady = (position = 0) => {
  setTimeout(() => {
    if (position > document.body.scrollHeight) {
      scrollWhenReady(position);
    } else {
      scrollTo(0, position);
    }
  }, 200);
};

const initScrollRestorer = history => {
  const positions = {};
  let prevKey;
  let prevPathname = root.location.pathname;
  let prevSearch = root.location.search;

  root.document.addEventListener(
    'scroll',
    throttle(() => {
      positions[prevKey] = root.pageYOffset;
    }, 200),
  );

  history.listen(({ key, pathname, search, state } = {}, action) => {
    prevKey = key;

    // If neither the pathname nor search have changed, return early to allow links to document fragments to work.
    if (pathname === prevPathname && search === prevSearch) {
      return;
    }

    prevPathname = pathname;
    prevSearch = search;

    if (state?.noScroll) return;

    if (action === 'PUSH') {
      // New page in history
      scrollWhenReady(0);
    } else {
      // Forward OR backward
      scrollWhenReady(positions[key] ?? 0);
    }
  });
};

export default initScrollRestorer;
