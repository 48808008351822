import { matchPath } from 'react-router-dom';
import { routes } from 'route-data/routes';

export const findRouteMatch = pathname => {
  let found;
  for (let index = 0; index < routes.length; index += 1) {
    const { exact, fetch, clientFetch, path, strict } = routes[index];

    const match = matchPath(pathname, {
      path,
      strict,
      exact,
    });

    if (match) {
      found = {
        fetch,
        clientFetch,
        index,
        match,
      };
      break;
    }
  }
  return found;
};
