import React from 'react';
import { buildElementStyling } from '../utils';
import { RichTextNode } from '../types';

const RichTextH3 = ({ children, value }: RichTextNode) => {
  const { className, style } = buildElementStyling({ baseClass: 'richTextH3', value });
  return (
    <h3 className={className} style={style}>
      {children}
    </h3>
  );
};

RichTextH3.displayName = 'RichTextH3';

export default RichTextH3;
