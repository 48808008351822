import React from 'react';

import AemComponentGrid from 'components/AemComponentGrid';

const ColumnLayout = props => (
  <div className="container-fluid">
    <AemComponentGrid {...props} />
  </div>
);

export default ColumnLayout;
