module.exports = {
  adminDomain: '',
  allowStubs: false,
  apiPath: '/api',
  assetsPath: '/assets',
  cfDomain: '',
  cmsDirectory: '/bin/v1',
  contentRoot: '/content/dam/waitrosegroceriescms',
  failsafe: true,
  features: {
    autosuggest: false,
    eagerSuggest: false,
    enablePredictiveAddress: false,
    loyalty_competitions: true,
    loyalty_enableNonIPickSelection: false,
    manualAddressFormOnly: true,
    multisearch: true,
    onlineClipToCard: false,
    orderItemSubs: false,
    savePaymentCard: true,
    searchHistory: true,
    showPlasticCardDelayAlert: false,
    siteBanner: false,
    slots_allSlotsDisabled: false,
    slots_allDeliverySlotsDisabled: false,
    ssrForAll: false,
    throttleLogins: false,
    cspCheckoutEnforceRules: false,
    cspCheckoutScriptUnsafeEval: false,
    browse_mealDealEndDateToday: false,
    browse_mealDealEndDate1Day: false,
    browse_mealDealEndDate2Day: false,
    renderStaticNavLinks: false,
    sentry: false,
    sentryContentsquare: false, // Contentsquare integration only works if sentry is true
    getGraphqlOrderWithRecs: false,
    podHighlight: false,
    recommendationsWithFallback: false,
    apmRum: false,
    apmRumErrorLogging: false,
    modalErrorLogging: false,
    nlpCarousel: false,
    glpWithRecommendations: false,
    showPrideLogo: false,
    showPDPRecommendations: false,
    identity_enableOAuth2Web: false,
    glpNLPCarousel: false,
    browse_showSecondaryPageTitle: false,
    browse_offersLinksWithRelNofollow: false,
    checkoutAutoApplyIncentives: false,
    enableEnrichPromotionForWebsite: false,
    build_favouritesExperience: false,
  },
  featureFlagsToPassToAPI: ['enAppleWallet', 'onlineClipToCardPilotUser'],
  graphPath: '/graph',
  impactTrackerId: '22892',
  ofDomain: '',
  monetateApiChannel: 'a-18da6672/d/dev.waitrose.com',
  cardinalScriptUrl: 'https://songbirdstag.cardinalcommerce.com/edge/v1/songbird.js',
  kountClientID: '888099',
  paymentCardAuthReturn: '/v1/orders/payments/authentication',
  prodHost: 'www.waitrose.com',
  verifoneDomain: 'https://sandbox.omni.verifone.cloud/v1',
  verifoneOrganizationID: '5d15d6671a8e7b2d3a83cc5a',
  reCaptchaPublicKey: '6LfbWzEaAAAAALZVveWFuQj_9sLLIzGYfuw4VPK8',
  root: '/ecom',
  thirdPartyPath: '/external',
  timeouts: {
    interstitials: {
      response: 2000,
      deadline: 4000,
    },
    minimumOrderValues: {
      response: 5000,
      deadline: 7000,
    },
  },
  verifoneCardFormPostUrl: 'https://vg-cst.cxmlpg.com/vanguard.aspx',
  wcsDomain: '',
  authDomain: '',
  tokenExScriptUrl: 'https://api.tokenex.com/inpage/js/TokenEx-Lite.js',
  tokenExPublicKey:
    'MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAvWpIQFjQQCPpaIlJKpegirp5kLkzLB1AxHmnLk73D3TJbAGqr1QmlsWDBtMPMRpdzzUM7ZwX3kzhIuATV4Pe7RKp3nZlVmcrT0YCQXBrTwqZNh775z58GP2kZs+gVfNqBampJPzSB/hB62KkByhECn6grrRjiAVwJyZVEvs/2vrxaEpO+aE16emtX12RgI5JdzdOiNyZEQteU6zRBRJEocPWVxExaOpVVVJ5+UnW0LcalzA+lRGRTrQJ5JguAPiAOzRPTK/lYFFpCAl/F8wtoAVG1c8zO2NcQ0Pko+fmeidRFxJ/did2btV+9Mkze3mBphwFmvnxa35LF+Cs/XJHDwIDAQAB',
  recipesPerPage: 54,
  sitemapRecipesCategories:
    'https://waitrose-prod65.adobecqms.net/content/waitrosegroceriescms/en/recipes.sitemap.recipes.categories.xml',
  sitemapRecipesDetailPages:
    'https://api.ecom.waitrose.com/recipes-prod/v1/sitemap.recipe.detail.pages.xml',
  version: process.env.VERSION,
  apmRum: {
    url: 'https://wtr-ecom-logging-nonprod.apm.eu-west-1.aws.found.io',
    transactionSampleRate: 1.0,
  },
  sitemapContentPages:
    'https://waitrose-prod.adobecqms.net/content/waitrosegroceriescms/en/wtr-content.sitemap.contentpage.xml',
  clipToCardPilotCustomerIds: [],
};
