import React from 'react';
import { pure } from 'recompose';

import Warrant from 'components/Warrant';

const QueenWarrant = pure(() => (
  <Warrant
    armsUrl="https://ecom.waitrose.com/content/dam/waitrosegroceriescms/ecomm/homepage/queen.png"
    byAppointmentTo="Her Majesty The Queen"
  />
));

QueenWarrant.displayName = 'QueenWarrant';

export default QueenWarrant;
