import React from 'react';

import PropTypes from 'prop-types';
import classNames from 'classnames';
import { ChevronRight } from '@johnlewispartnership/wtr-ingredients/foundations/icons';

import { ModalCloseLabels, ModalCloseOptions } from 'constants/modalCloseOptions';
import ListModal from 'components/Modal/ListModal';

// [SSR][OK] This should be ok as it is not rendered on load
// eslint-disable-next-line no-restricted-imports
import { mediaBreakpointUp } from 'utils/mediaQueries';

import styles from './MultiSearchModal.scss';
import { useModal } from '../Hooks/use-modal';

const MAX_LENGTH = 100;

const multiSearchButtons = ({ enableSaveButton }) => {
  const ctaButtons = [
    {
      buttonText: ModalCloseLabels.CLEAR_LIST,
      buttonValue: ModalCloseOptions.CLEAR_LIST,
      disabled: !enableSaveButton,
      theme: 'secondary',
    },
    {
      buttonText: ModalCloseLabels.SEARCH,
      buttonValue: ModalCloseOptions.SAVE,
      disabled: !enableSaveButton,
      theme: 'primary',
    },
  ];
  return mediaBreakpointUp('sm') ? ctaButtons : ctaButtons.reverse();
};

const MultiSearchModal = ({ hidden, mobile, onToogleModal, onSearch }) => {
  const { text, modalOpen, toogleModal, onTextChange, onClose } = useModal({
    onToogleModal,
    onSearch,
  });

  const buttonProps = {
    'aria-label': 'Search multiple items',
    className: classNames({
      [styles.multiSearch]: !mobile,
      [styles.mobileMultiSearch]: mobile,
      [styles.hidden]: hidden,
    }),
    'data-testid': 'multi-search-button',
    disabled: hidden,
    onClick: toogleModal,
    role: 'button',
    tabIndex: 0,
  };

  const modalProps = {
    customButtons: multiSearchButtons,
    flexButtons: true,
    isOpen: modalOpen,
    label: `Multiple item search. Press Enter after each item.`,
    maxListLength: MAX_LENGTH,
    mobile,
    onClose,
    onTextChange,
    text,
    placeholder: 'Type or paste your list here',
    titleText: 'Multi-search',
  };

  return (
    <>
      <button {...buttonProps} type="button">
        Multi-search
      </button>
      {mobile && <ChevronRight size="xsmall" className={styles.chevronRightIcon} />}
      <ListModal {...modalProps} />
    </>
  );
};

MultiSearchModal.displayName = 'MultiSearchModal';

MultiSearchModal.propTypes = {
  hidden: PropTypes.bool,
  mobile: PropTypes.bool,
  onToogleModal: PropTypes.func,
  onSearch: PropTypes.func,
};

MultiSearchModal.defaultProps = {
  hidden: true,
  mobile: false,
  onToogleModal: () => {},
  onSearch: () => {},
};

export default MultiSearchModal;
