import React from 'react';
import { TableRow } from '@johnlewispartnership/wtr-ingredients/ingredients/Table';
import { buildElementStyling } from '../utils';
import { RichTextNode } from '../types';

const RichTextTableRow = ({ children, value }: RichTextNode) => {
  const { className, style } = buildElementStyling({ value, baseClass: 'richTextTableRow' });
  return (
    <TableRow className={className} style={style}>
      {children}
    </TableRow>
  );
};

RichTextTableRow.displayName = 'RichTextTableRow';

export default RichTextTableRow;
