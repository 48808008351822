import React from 'react';
import { TableCell } from '@johnlewispartnership/wtr-ingredients/ingredients/Table';
import { buildElementStyling } from '../utils';
import { RichTextNode } from '../types';
import { renderedTableHeadings } from './TableHeading';

const RichTextTableCell = ({ children, value, index }: RichTextNode) => {
  const { className, style } = buildElementStyling({
    baseClass: 'richTextTableCell',
    value,
  });

  return (
    <TableCell
      className={className}
      style={style}
      verticalAlign="top"
      styleAs="paragraphSmallLight"
      data-heading-label={renderedTableHeadings[index]}
    >
      {children}
    </TableCell>
  );
};

RichTextTableCell.displayName = 'RichTextTableCell';

export default RichTextTableCell;
