import { DEFAULT_SORT_BY } from 'redux/modules/recipes/constants';
import get from 'lodash/get';
import initial from 'lodash/initial';

import {
  GET_CMS_HYBRID_RECIPES_PAGE_LOADING,
  GET_CMS_HYBRID_RECIPES_PAGE_SUCCESS,
  GET_CMS_HYBRID_RECIPES_PAGE_FAILURE,
  GET_CMS_TAGGED_RECIPES_LOADING,
  GET_CMS_TAGGED_RECIPES_SUCCESS,
  GET_CMS_TAGGED_RECIPES_FAILURE,
  GET_CMS_FILTERED_SORTED_RECIPES_SUCCESS,
  INCREMENT_CURRENT_RECIPES_PAGE,
  RESET_CURRENT_RECIPES_PAGE,
  RESET_FILTERED_SORTED_RECIPES,
  RECIPES_REMEMBER_FOCUS,
} from 'redux/modules/cms-hybrid-recipes-page/actions/types';

const initialState = Object.freeze({
  error: false,
  loading: false,
  loadingCount: 0,
});

/*

  WARNING
  This file is not independent of the src/redux/modules/content redux module as the ContentLocation
  is a connected component that uses that section of the redux modules to pull the content locations. Care whilst adding
  to this file is advised to make sure that responsibilities are not duplicated with the other almost identical
  redux modules.

  WARNING 2
  It copies functionality from src/redux/modules/cms-page/reducers/index.js
  The whole thing is candidate for redesign due to that

*/

const mergeRecipes = (recipes, moreRecipes, start) => {
  const newRecipes = [...recipes];

  moreRecipes.forEach((recipe, i) => {
    newRecipes[start + i] = recipe;
  });

  return newRecipes;
};

const extractTags = ({ locations }) => {
  let tags = [];
  if (locations) {
    locations.root.forEach(element => {
      if (element.resourceType === 'waitrosegroceriescms/components/content/recipecardsgrid') {
        tags = element.tagList || [];
      }
    });
  }

  return tags;
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CMS_HYBRID_RECIPES_PAGE_LOADING: {
      return {
        ...state,
        error: false,
        loading: true,
        loadingCount: (state.loadingCount || 0) + 1,
      };
    }

    case GET_CMS_HYBRID_RECIPES_PAGE_SUCCESS: {
      const result = action.result || {};

      // if this is authorable breadcrumb - drop last item
      const isAuthorableBreadcrumb = result.authorableBreadcrumb || false;
      const notIndexed = get(action, 'result.metaData.turnOffIndexing', false);

      // handle AEM paths
      const breadcrumbs = get(action, 'result.breadcrumbs', []).map(({ path, ...rest }) => {
        const newPath =
          path && path.includes('/content/waitrosegroceriescms/en')
            ? path.split('/content/waitrosegroceriescms/en/')[1]
            : path;

        return {
          ...rest,
          path: `/ecom/${newPath}`,
        };
      });

      const categoryChanged = action.contentUrl !== state.contentUrl;

      const newState = {
        ...state,
        breadcrumbs: isAuthorableBreadcrumb ? initial(breadcrumbs) : breadcrumbs,
        contentUrl: action?.contentUrl,
        error: false,
        loading: false,
        loadingCount: state.loadingCount - 1,
        locations: result.locations || null,
        metaData: { ...get(action, 'result.metaData', null), notIndexed },
        pageTitle: get(action, 'result.pageTitle', {}),
        image: get(action, 'result.image', null),
        showPageTitle: get(action, 'result.showPageTitle', true),
        recipeTags: extractTags(action.result || {}),
      };

      if (categoryChanged) {
        Object.assign(newState, {
          recipesByTags: null,
          currentPage: 1,
          paginatingOperation: false,
          filters: [],
        });
      }

      return newState;
    }

    case GET_CMS_HYBRID_RECIPES_PAGE_FAILURE: {
      return {
        ...state,
        error: true,
        loading: false,
        loadingCount: (state.loadingCount || 1) - 1,
      };
    }

    case GET_CMS_TAGGED_RECIPES_LOADING: {
      return {
        ...state,
        loadingCount: state.loadingCount + 1,
      };
    }

    case GET_CMS_TAGGED_RECIPES_SUCCESS: {
      const result = action.result || {};
      const filterParams = { sortBy: action.sortBy || DEFAULT_SORT_BY };

      return {
        ...state,
        filterParams,
        recipesByTags: mergeRecipes(
          state.recipesByTags || [],
          action.recipesByTags || result.recipes || [],
          action.start || 0,
        ),
        filters: result.filters,
        totalTaggedRecipes: result.totalMatches,
        currentPage: action.currentPage,
        loadingCount: state.loadingCount - 1,
      };
    }

    case GET_CMS_FILTERED_SORTED_RECIPES_SUCCESS: {
      const paramsChanged =
        (action.filterParams?.sortBy || DEFAULT_SORT_BY) !==
        (state.filterParams?.sortBy || DEFAULT_SORT_BY);

      const mergeFilters = (a = [], b = []) => {
        const filters = a.map(facet => {
          const facetCopy = { ...facet };
          const updated = b.find(v => v.title === facet.title);
          if (updated) {
            facet.values.forEach((item, i) => {
              const updatedItem = updated.values.find(v => v.name === item.name);
              facetCopy.values[i].count = updatedItem?.count || 0;
            });
          } else {
            facet.values.forEach((_item, i) => {
              facetCopy.values[i].count = 0;
            });
          }
          return facetCopy;
        });

        // Handle facets that were not present in the original results
        b.forEach(facet => {
          const existing = filters.find(v => v.title === facet.title);
          if (existing) {
            facet.values.forEach(item => {
              const val = existing.values.find(v => v.name === item.name);
              if (!val) {
                existing.values.push(item);
              }
            });
          } else {
            filters.push(facet);
          }
        });

        return filters;
      };

      return {
        ...state,
        recipesByTags: mergeRecipes(
          (!paramsChanged && state.recipesByTags) || [],
          action.recipesByTags || [],
          action.start,
        ),
        filters: mergeFilters(state.filters, action.filters),
        filterParams: action.filterParams,
        totalTaggedRecipes: action.totalTaggedRecipes,
        filteringOperation: false,
        paginatingOperation: false,
      };
    }

    case RESET_FILTERED_SORTED_RECIPES: {
      return {
        ...state,
        recipesByTags: [],
        filteringOperation: true,
        paginatingOperation: false,
        searchTerm: action?.payload?.searchTerm,
      };
    }

    case INCREMENT_CURRENT_RECIPES_PAGE: {
      return {
        ...state,
        currentPage: parseInt(state.currentPage || 1, 10) + 1,
        paginatingOperation: true,
      };
    }

    case RESET_CURRENT_RECIPES_PAGE: {
      return {
        ...state,
        currentPage: 1,
        paginatingOperation: false,
      };
    }

    case RECIPES_REMEMBER_FOCUS: {
      return {
        ...state,
        focusedRecipe: action.id,
      };
    }

    case GET_CMS_TAGGED_RECIPES_FAILURE: {
      return {
        ...state,
        error: true,
        loading: false,
        loadingCount: (state.loadingCount || 1) - 1,
      };
    }

    default:
      return state;
  }
};
