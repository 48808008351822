import {
  Image,
  AemImage,
  AemStandardImage,
  AemLandscapePortraitImage,
  ImageMeta,
} from 'analytics/aem/get-image-alt-text-and-url/types';
// [SSR][OK] Only used onClick and won't impact SSR
// eslint-disable-next-line no-restricted-imports
import { mediaBreakpointCurrent } from 'utils/mediaQueries';

/*
  AEM CMS components contain different properties for images. A 'large' or default image is located at the root of the object, 
  while other images such as mobile, portrait, tablet and landscape are nested properties.
*/

function extractAltTextAndUrl({ altText, fileReference }: Image): ImageMeta {
  return {
    altText: altText || '',
    imageUrl: fileReference,
  };
}

export function getImageAltTextAndUrl(image: AemImage): ImageMeta {
  const currentBreakpoint = mediaBreakpointCurrent();
  let smallImage;
  let mediumImage;
  let largeImage;
  let altText = '';
  let fileReference = '';

  const isStandardImage = (
    imageType: AemStandardImage | AemLandscapePortraitImage,
  ): imageType is AemStandardImage => 'fileReference' in imageType;

  if (isStandardImage(image)) {
    const { mobileImage, tabletImage } = image;
    smallImage = mobileImage?.fileReference && mobileImage;
    mediumImage = tabletImage?.fileReference && tabletImage;
    ({ altText, fileReference } = image);
  } else {
    const { portraitImage, landscapeImage } = image;
    smallImage = portraitImage?.fileReference && portraitImage;
    largeImage = landscapeImage?.fileReference && landscapeImage;
  }

  if (smallImage && ['xs', 'sm'].includes(currentBreakpoint)) {
    return extractAltTextAndUrl(smallImage);
  }

  if (mediumImage && currentBreakpoint === 'md') {
    return extractAltTextAndUrl(mediumImage);
  }

  if (largeImage) {
    return extractAltTextAndUrl(largeImage);
  }

  return extractAltTextAndUrl({ altText, fileReference });
}
