import { CLICK_TO_BUY } from 'constants/aemComponentNames';

import concatenatePromotionValues from 'redux/middleware/analytics/actions-to-track/utilities/concatenate-promotion-values';
import extractLineNumberFromSku from 'utils/extract-linenumber-from-sku';

import { getProductById } from 'redux/modules/entities/selectors/products';
import { getVisibleGridPosition } from 'redux/modules/search-and-browse/selectors/get-visible-grid-position';

import { productImpressions } from './product-impressions';

const getProp = (state = {}, propToResolve) => {
  let prop;

  switch (typeof propToResolve) {
    case 'function': {
      prop = propToResolve(state);
      break;
    }
    case 'string': {
      prop = propToResolve;
      break;
    }
    default:
      break;
  }

  return prop;
};

const createProductImpression = (
  state,
  {
    currentSaleUnitPrice: {
      price: { amount: price = '', currencyCode } = {},
      quantity: { uom = '' } = {},
    } = {},
    id: productId,
    lineNumber,
    name,
    pricing,
  } = {},
  list,
  index,
) => {
  const { promotions } = pricing || {};
  return {
    currencyCode,
    id: lineNumber || extractLineNumberFromSku(productId),
    list: list ? list.toLowerCase() : list,
    name,
    offer: concatenatePromotionValues(state, promotions) || '',
    /* [BM]: AEM controlled positions will never report back a valid visible position lower than the index ;) */
    position: Math.max(getVisibleGridPosition(state, lineNumber) ?? -1, index + 1),
    price,
    product_sku: productId,
    uom,
  };
};

const getProductGridImpressions = (state, gridItems, list) =>
  gridItems
    .filter(({ searchProduct } = {}) => searchProduct)
    .map(({ searchProduct = {} } = {}) => {
      const { id } = searchProduct;

      return id ? searchProduct : getProductById(state, searchProduct);
    })
    .filter(product => product) // filter invalid elements
    .filter((product, index, products) => products.indexOf(product) === index) // remove duplicates
    .map((product, index) => createProductImpression(state, product, list, index));

const getClickToBuyImpressions = (state, gridItems, list) =>
  gridItems
    .filter(({ aemComponent: { resourceType } = {} } = {}) => resourceType === CLICK_TO_BUY)
    .map(
      ({
        aemComponent: {
          paragraphSystem: {
            childComponents: [
              tradingCell, // eslint-disable-line @typescript-eslint/no-unused-vars
              {
                items: [{ searchProduct }], // [BM]: only map first product in carousel component
              },
            ] = [],
          } = {},
        } = {},
      } = {}) => searchProduct,
    )
    .filter(searchProduct => searchProduct)
    .map(searchProduct => getProductById(state, searchProduct))
    .filter(product => product)
    .map((product, index) => createProductImpression(state, product, list, index));

const getImpressionsMap = (state = {}, fetch = () => [], list) => {
  const gridItems = fetch(state) || [];
  const listType = getProp(state, list);

  return [
    ...getProductGridImpressions(state, gridItems, listType),
    ...getClickToBuyImpressions(state, gridItems, listType),
  ];
};

export default ({ fetch, list, taxonomy } = {}) =>
  state => {
    if (!fetch || typeof fetch !== 'function') return null;

    const impressions = getImpressionsMap(state, fetch, list);
    const taxonomyLevel = getProp(state, taxonomy);

    return productImpressions(impressions, taxonomyLevel);
  };
