import locator from 'utils/locator';
import { userAgent } from 'utils/user-agent';

export type AppType = 'android' | 'ios' | null;

let appType: AppType;

function calculateAppType(): AppType {
  // User Agent: MobileApp/<AppVersion>.<BuildNumber> (<Manufacturer>; Android; <OS>)

  // on Debug:   MobileApp/1.3.12-Debug (iPhone7,1; CPU iPhone OS 12_1 like Mac OS X)
  // on Beta:    MobileApp/1.3.12.1234-Beta (iPhone7,1; CPU iPhone OS 12_1 like Mac OS X)
  // on Release: MobileApp/1.3.12.1234 (iPhone7,1; CPU iPhone OS 12_1 like Mac OS X)

  // istanbul ignore next
  const { ua, os: { family } = {} } = userAgent();
  const os = family?.toLocaleLowerCase();

  if (ua?.startsWith('MobileApp/') && (os === 'android' || os === 'ios')) {
    return os;
  }

  // mobileApp=Android
  // mobileApp=iOS
  const { search } = locator;
  const mobileAppQs = new URLSearchParams(search).get('mobileApp')?.toLowerCase();

  if (mobileAppQs === 'android' || mobileAppQs === 'ios') {
    return mobileAppQs;
  }

  return null;
}

/**
 * Checks the user agent for prefix "MobileApp/" then the URL query string for "mobileApp=Android|iOS".
 * @returns the type of the app running (android or ios), or null if not running in an app.
 */
export function getAppType(): AppType {
  if (__SERVER__) {
    return calculateAppType();
  }

  if (typeof appType !== 'undefined') {
    return appType;
  }

  appType = calculateAppType();

  return appType;
}

/**
 * @returns true if page is running in a WebView within a mobile app.
 *
 * @see {@link getAppType} for how it works
 */
export const isEmbeddedInWebView = () => {
  return !!getAppType();
};

/**
 * @returns false if page is running in a WebView within a mobile app. The opposite of {@link isEmbeddedInWebView}
 *
 * @see {@link getAppType} for how it works
 */
export const isNotEmbeddedInWebView = () => {
  return !getAppType();
};
