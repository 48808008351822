import environment from 'env/env';
import { asyncLocalStorage } from 'server/utils/async-local-storage';
import locator from 'utils/locator';
import { consoleInfo } from 'utils/logging';
import { local } from 'utils/storage';
import root from 'window-or-global';
import type { SzObject, SzType } from 'zodex';

let lazyStubs: Awaited<typeof import('stubs/stubs')> | undefined;

declare global {
  type StubFlagsDefinitions = Record<string, SzObject<Record<string, SzType>>>;

  /**
   * This is the function that loads the stubs metadata.
   * It is meant to be used by the test helper extension on {@link https://gitlab.com/JohnLewisPartnership/WTReCom/frontend/playground/wtr-stubs-extension}
   */
  // eslint-disable-next-line no-var, vars-on-top
  var stubFlagsLoader: () =>
    | Promise<ReturnType<Exclude<typeof lazyStubs, undefined>['loadFlagsMetadata']>>
    | undefined;
}

if (!__SERVER__) {
  const params = new URLSearchParams(locator.search);
  if (params.has('usestubs') || params.has('stubflag')) {
    root.usestubs = true;
  }
}

const importStubs = async () => {
  if (!lazyStubs) {
    consoleInfo('Import stubs...');
    lazyStubs = await import(
      /* webpackChunkName: "stubs" */
      /* webpackMode: "lazy" */
      'stubs/stubs'
    );
    consoleInfo('Import stubs complete');
  }

  return lazyStubs;
};

let stubsLoaded = false;

if (!__SERVER__ && environment.allowStubs) {
  root.stubFlagsLoader = () => {
    consoleInfo('Loading stubs metadata');
    return importStubs().then(module => module.loadFlagsMetadata());
  };
}

export const usingStubs = (): boolean => {
  if (__SERVER__) {
    const requestContext = asyncLocalStorage.getStore();
    return requestContext.get('useStubs');
  }
  if (root.usestubs) {
    return true;
  }
  const localUseStubs = local.get('usestubs');
  return (
    (localUseStubs &&
      ['covfefe', 'on', 'true', 'stubs', 'usestubs'].some(
        el => el === localUseStubs.toLowerCase(),
      )) ||
    false
  );
};

export const loadStubs = async (state: WtrState) => {
  if (usingStubs()) {
    const module = await importStubs();

    module.loadStubs(state);
    stubsLoaded = true;
  }
};

/**
 * Teardown must **ALWAYS** be called immediately after the request has been initiated,
 * since in an SSR environment, all users' requests would receive stub responses!
 * @returns teardown function to remove superagent Prototype patch
 */
export const setupStubs = (): (() => void) | null => {
  if (environment.allowStubs && stubsLoaded && lazyStubs && usingStubs()) {
    const tearDown = lazyStubs.setupMocks() || null;
    return tearDown && typeof tearDown === 'function' ? tearDown : null;
  }
  return null;
};
