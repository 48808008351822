import { useEffect } from 'react';
import { usePrevious } from 'hooks';
import {
  handleOptimisticBatchItemsUpdates,
  handleOptimisticSingleItemUpdates,
  removeLastUpdatedOptimisticItems,
} from './utils';

export const useOptimisticTrolleyHandler = (
  hasTrolleyError,
  removeAllOptimisticTrolleyItems,
  trolleyLoading,
  trolleyAddLoading,
  trolleyGetLoading,
  optimisticTrolleyItems,
  removeOptimisticTrolleyItem,
  removeMultipleOptimisticTrolleyItems,
  optimisticallyUpdateTrolleyItem,
  optimisticallyUpdateMultipleTrolleyItems,
  trolleyItems,
  addNewProductToTrolley,
  updateTrolleyItem,
  onAllowSubstitutions,
  onToggleSubAll,
  onSaveShopperNotes,
) => {
  const previousHasTrolleyError = usePrevious(hasTrolleyError);
  const trolleyJustErrored = hasTrolleyError && hasTrolleyError !== previousHasTrolleyError;
  useEffect(() => {
    if (trolleyJustErrored) {
      removeAllOptimisticTrolleyItems();
    } else if (!trolleyAddLoading && !trolleyGetLoading && optimisticTrolleyItems.length) {
      const previouslyProcessedItem = optimisticTrolleyItems[0].processing
        ? optimisticTrolleyItems[0]
        : undefined;

      // Remove last updated item from optimisticTrolleyItems
      if (!trolleyLoading && previouslyProcessedItem) {
        removeLastUpdatedOptimisticItems(
          previouslyProcessedItem,
          optimisticTrolleyItems,
          removeOptimisticTrolleyItem,
          removeMultipleOptimisticTrolleyItems,
        );
      }

      const nextItemToUpdate = optimisticTrolleyItems.find(({ processing }) => !processing);

      // Update next optimisticTrolleyItem in line
      if (nextItemToUpdate) {
        if (nextItemToUpdate.batchId) {
          handleOptimisticBatchItemsUpdates(
            nextItemToUpdate,
            optimisticTrolleyItems,
            optimisticallyUpdateMultipleTrolleyItems,
            onToggleSubAll,
          );
        } else {
          handleOptimisticSingleItemUpdates(
            nextItemToUpdate,
            trolleyItems,
            optimisticallyUpdateTrolleyItem,
            addNewProductToTrolley,
            updateTrolleyItem,
            onAllowSubstitutions,
            onSaveShopperNotes,
          );
        }
      }
    }
  }, [
    addNewProductToTrolley,
    onAllowSubstitutions,
    onSaveShopperNotes,
    onToggleSubAll,
    optimisticTrolleyItems,
    optimisticallyUpdateTrolleyItem,
    optimisticallyUpdateMultipleTrolleyItems,
    removeAllOptimisticTrolleyItems,
    removeOptimisticTrolleyItem,
    removeMultipleOptimisticTrolleyItems,
    trolleyItems,
    trolleyJustErrored,
    trolleyLoading,
    trolleyGetLoading,
    updateTrolleyItem,
    trolleyAddLoading,
  ]);
};
