import React, { useState } from 'react';
import classNames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { useClientOnlyLayoutEffect } from 'hooks/use-client-only-layout-effect';
import { BREAKPOINTS, BreakpointWidth } from 'constants/grid';
import styles from './SSRMediaQuery.scss';

interface SSRMediaQueryProps {
  className?: string;
  maxWidth?: BreakpointWidth;
  minWidth?: BreakpointWidth;
}

interface DisplayValueProps extends SSRMediaQueryProps {
  breakpoint: BreakpointWidth;
}

const getDisplayValue = ({ breakpoint, maxWidth, minWidth }: DisplayValueProps) => {
  if (typeof maxWidth === 'undefined' && typeof minWidth === 'undefined') {
    return undefined;
  }

  if (typeof minWidth === 'undefined') {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return breakpoint >= maxWidth! ? 'none' : undefined;
  }

  if (typeof maxWidth === 'undefined') {
    return breakpoint < minWidth ? 'none' : undefined;
  }

  return breakpoint < minWidth || breakpoint >= maxWidth ? 'none' : undefined;
};

/**
 * This component serves to allow viewport specific components be server-side
 * rendered without causing a CLS.
 *
 * ---
 *
 * 🚨 Before using this component you should first consider:
 *
 * - Can you use CSS media queries in your component directly to handle
 * viewport specific requirements? If so, use them over this component.
 *
 * Only if the layout of the page is sufficiently different between
 * viewports (such that CSS grid etc. would cause a11y issues) should we then
 * consider:
 *
 * - If the component requires user interaction to load, is lazy loaded, or
 * "deferred" in some other way, then using the useClientSideMediaQuery hook is
 * fine.
 * - If the component is part of the critical render path then it should use
 * this wrapper SSRMediaQuery component to ensure no CLS.
 *
 * ---
 *
 * **Description**
 *
 * It first assumes we are always in a server-side / pre-hydration world until
 * it is told otherwise (by a layout hook) to ensure that when the page first
 * loads all possible markup is rendered to cater to any viewport. This is
 * necessary as we currently have no way to hint to our servers what viewport
 * the customer is using, so we must cater to all scenarios.
 *
 * As we want to ensure that there is no flash of unstyled content (FOUC) when
 * we server-side render, this component wraps the server rendered content in a
 * div which is assigned styles to ensure that it is hidden from display (and
 * assistive technology) through CSS media queries.
 *
 * Once we know that we are definitely client-side this wrapper is no longer
 * required and removed.
 *
 * **Note:** the wrapper div used to control the display / hiding of the
 * content at different viewports inherits the CSS `display` of it's parent by
 * default. Sometimes this isn't sufficient to ensure correct rendering and you
 * can get a CLS. E.g. if you are using flex and the intermediary div means the
 * children aren't flex-wrapped. To avoid this you can pass a `className` prop
 * that will be placed on the wrapper `div`. When passing a custom class, avoid
 * setting a `display` value otherwise it break the display logic.
 */
export const SSRMediaQuery: React.FC<SSRMediaQueryProps> = ({
  children,
  className,
  maxWidth,
  minWidth,
}) => {
  const [isClientSide, setIsClientSide] = useState(false);
  const queryMaxWidth = maxWidth ? maxWidth - 1 : maxWidth;
  const isMatch = useMediaQuery({ maxWidth: queryMaxWidth, minWidth });

  useClientOnlyLayoutEffect(() => {
    setIsClientSide(true);

    return () => {
      setIsClientSide(false);
    };
  }, []);

  if (!isClientSide) {
    const style = {
      '--xs': getDisplayValue({ breakpoint: BREAKPOINTS.xs, maxWidth, minWidth }),
      '--sm': getDisplayValue({ breakpoint: BREAKPOINTS.sm, maxWidth, minWidth }),
      '--md': getDisplayValue({ breakpoint: BREAKPOINTS.md, maxWidth, minWidth }),
      '--lg': getDisplayValue({ breakpoint: BREAKPOINTS.lg, maxWidth, minWidth }),
      '--xl': getDisplayValue({ breakpoint: BREAKPOINTS.xl, maxWidth, minWidth }),
    } as React.CSSProperties;

    return (
      <div className={classNames(styles.mediaQueryContainer, className)} style={style}>
        {children}
      </div>
    );
  }

  if (!isMatch) {
    return null;
  }

  return <>{children}</>;
};
