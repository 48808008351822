const authenticationUrls = ['/registration', '/login', '/reset-password', '/forgot-password'];

const isAuthenticationUrl = path => authenticationUrls.some(url => path.includes(url));

const reducer = (state, action) => {
  if (
    action?.payload?.location?.pathname &&
    !isAuthenticationUrl(action.payload.location.pathname)
  ) {
    return {
      ...state,
      lastNonAuthenticationPath: `${action.payload.location.pathname}${
        action.payload.location.search || ''
      }`,
    };
  }
  return state;
};

export default reducer;
