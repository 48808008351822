import React from 'react';
import { TableContainer } from '@johnlewispartnership/wtr-ingredients/ingredients/Table';
import { buildElementStyling } from '../utils';
import { RichTextNode } from '../types';

const RichTextTable = ({ children, value }: RichTextNode) => {
  const { className, style } = buildElementStyling({ value });
  return (
    <TableContainer className={className} style={style} fullWidth>
      {children}
    </TableContainer>
  );
};

RichTextTable.displayName = 'RichTextTable';

export default RichTextTable;
