import { elapsed } from 'server/utils/elapsed';

const stats = () => {
  if (__SERVER__) {
    // this has to be imported synchronously, hot-shots depends on a module and is therefore incompatible client side
    const {
      restEntryMessage,
      restExitErrorMessage,
      restExitSuccessMessage,
    } = require('utils/logging'); // eslint-disable-line global-require, @typescript-eslint/no-var-requires

    const { api, increaseRequestCounter, decreaseRequestCounter } = require('server/utils/stats'); // eslint-disable-line global-require, @typescript-eslint/no-var-requires

    return {
      restTime: () => {
        const start = process.hrtime();
        return (url, status) => api({ url, status, ms: elapsed(start) });
      },
      restEntryMessage,
      restExitErrorMessage,
      restExitSuccessMessage,
      increaseRequestCounter,
      decreaseRequestCounter,
    };
  }

  return {
    restTime: () => () => {},
    restEntryMessage: () => {},
    restExitErrorMessage: () => {},
    restExitSuccessMessage: () => {},
    increaseRequestCounter: () => {},
    decreaseRequestCounter: () => {},
  };
};

export default stats();
