export const CLIENT_PAGE_LOADED = 'waitrose/page/CLIENT_PAGE_LOADED' as const;
export const CLIENT_PAGE_LOADING = 'waitrose/page/CLIENT_PAGE_LOADING' as const;
export const PAGE_CLEAR_COOKIES = 'waitrose/page/CLEAR_COOKIES' as const;
export const PAGE_CLEAR_PRELOADED = 'waitrose/page/CLEAR_PRELOADED' as const;
export const PAGE_CLEAR_SERVER_GTM_EVENTS = 'waitrose/page/CLEAR_SERVER_GTM_EVENTS' as const;
export const PAGE_CLEAR_SERVER_MONETATE_EVENTS =
  'waitrose/page/CLEAR_SERVER_MONETATE_EVENTS' as const;
export const PAGE_SET_PRELOADED = 'waitrose/page/SET_PRELOADED' as const;
export const PAGE_SET_REQUEST_COOKIES = 'waitrose/page/SET_REQUEST_COOKIES' as const;
export const PAGE_SET_RESPONSE_COOKIES = 'waitrose/page/SET_RESPONSE_COOKIES' as const;
export const PAGE_SET_SERVER_GTM_EVENTS = 'waitrose/page/SET_SERVER_GTM_EVENTS' as const;
export const PAGE_SET_SERVER_MONETATE_EVENTS = 'waitrose/page/SET_SERVER_MONETATE_EVENTS' as const;
export const SETMEGAMENU = 'waitrose/page/SET_MEGA_MENU' as const;
export const SET_MEGAMENU_ACTIVE_CATEGORY = 'waitrose/page/SET_MEGAMENU_ACTIVE_CATEGORY' as const;
export const SET_MEGAMENU_ROOT_CATEGORY = 'waitrose/page/SET_MEGAMENU_ROOT_CATEGORY' as const;
export const SETCLEARVALUE = 'waitrose/page/SET_CLEAR_VALUE' as const;
export const SETMEGAMENUACTIVELEVEL = 'waitrose/page/SET_MEGA_MENU_ACTIVE_LEVEL' as const;
export const SETMEGAMENUSTART = 'waitrose/page/SET_MEGA_MENU_START' as const;
export const SETMEGAMENUSTATE = 'waitrose/page/SET_MEGA_MENU_STATE' as const;
export const SETMOBILEOVERLAY = 'waitrose/page/SET_MOBILE_OVERLAY' as const;
export const SETPAGEDEPTH = 'waitrose/page/SET_PAGE_DEPTH' as const;
export const SET_PAGE_LOADING = 'waitrose/page/SET_PAGE_LOADING' as const;
export const TOGGLE_PIN_BAR_OPEN = 'waitrose/page/TOGGLE_PIN_BAR_OPEN' as const;
