import { dataLayer } from 'analytics/data-layer';

// [SSR][OK] Only used onClick and won't impact SSR
// eslint-disable-next-line no-restricted-imports
import { mediaBreakpointCurrent } from 'utils/mediaQueries';

const getLinkTarget = (newWindow: boolean) => ({ target: newWindow ? '_blank' : '_self' });

interface Products {
  id: string;
}

interface PromoClick {
  analyticsComponentType?: string;
  analyticsId?: string;
  assetModuleType?: string;
  bannerPurpose?: string;
  buyingOfficeAndGroup?: string;
  creative?: string;
  enhancedEcommerceInternalPromotion?: string;
  id?: string;
  imageUrl?: string;
  linkURL?: string;
  name?: string;
  newWindow?: boolean;
  position?: string;
  products?: Products[];
  promoName?: string;
  promoPeriod?: string;
  promoType?: string;
}

export default function promoClick(
  {
    bannerPurpose,
    buyingOfficeAndGroup,
    creative,
    id,
    name,
    position,
    promoPeriod,
    promoType,
    assetModuleType = 'Unassigned',
    enhancedEcommerceInternalPromotion = 'Unassigned',
    newWindow,
    products,
    ...props
  }: PromoClick = {},
  taxonomyLevel?: number,
) {
  dataLayer.push({
    event: 'promo_click',
    ecommerce: {
      promoClick: {
        products,
        promotions: [
          {
            assetModuleType,
            ...(!!bannerPurpose && { banner_purpose: bannerPurpose }),
            breakpoint: mediaBreakpointCurrent(),
            ...(!!buyingOfficeAndGroup && { buying_office_and_group: buyingOfficeAndGroup }),
            creative,
            enhancedEcommerceInternalPromotion,
            id,
            name,
            position,
            ...(!!promoPeriod && { promo_period: promoPeriod }),
            ...(!!promoType && { promo_type: promoType }),
            ...(newWindow !== undefined && getLinkTarget(newWindow)),
            ...props,
          },
        ],
      },
      ...(!!taxonomyLevel && { taxonomyLevel }),
    },
  });
}
