import { matchPath } from 'react-router-dom';
import urls from './urls';

/**
 * List of pages that handle payment.
 *
 * This list is used to remove 3rd party scripts, except for those that process payment
 */
export const paymentPages = [
  urls.resolveOrderPayment,
  // todo: add checkout page
];

export function isPaymentPage(pathname: string) {
  return paymentPages.some(path => matchPath(pathname, { path }));
}
