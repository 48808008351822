type Builder = {
  groups: Array<{ items: Array<string>; groupId: string; threshold: number }>;
  completed: boolean;
  promotionDescription: string;
};

export const offerBuilderDetails = ({ builders }: { builders: [(Builder | undefined)?] }) => {
  if (!builders.length) return null;
  const lastElementBuilder: Builder | undefined = builders?.[builders.length - 1];

  const numberOfBuilders: number = builders?.length;
  const allLines = lastElementBuilder?.groups.flatMap(group => group.items);

  const elementsOnLastBuilder = allLines?.filter(item => item !== null)?.length || 0;

  const { groupId: offerId } = lastElementBuilder?.groups[0] || {};

  const isWineBuilder = offerId === '101';

  const completionStatus = isWineBuilder
    ? elementsOnLastBuilder === 0 && numberOfBuilders === 2
    : elementsOnLastBuilder === 0;

  const completedStatus = isWineBuilder ? numberOfBuilders >= 2 : elementsOnLastBuilder === 0;

  const offerType = isWineBuilder ? 'Wine offers' : 'All offers';

  return {
    offerId,
    totalComplete: isWineBuilder && completedStatus ? 1 : numberOfBuilders - 1,
    completionStatus,
    completedStatus,
    offerType,
  };
};
