import { RouteComponentProps } from 'react-router-dom';
import urls from 'constants/urls';
import { WINE_WEEK_REDIRECT, matchDecisionByExperiment } from 'constants/monetateExperiments';
import { getAllDecisions } from 'redux/modules/experiments/selectors';

type Fetcher = (args: RouteComponentProps) => WtrThunkAction;

const getIsWineWeekRedirectMatch = (args: RouteComponentProps, getState: () => WtrState) => {
  if (args.location.pathname !== urls.wineBrowse) {
    return false;
  }

  const state = getState();
  // @ts-expect-error experiments state is not typed
  const decisions = getAllDecisions(state);
  const variant = matchDecisionByExperiment(
    WINE_WEEK_REDIRECT.experiment,
    decisions?.[WINE_WEEK_REDIRECT.experiment],
  );

  return variant === WINE_WEEK_REDIRECT.variant.showBuilderPage;
};

export const exceptWhenWineWeekRedirect =
  (fetcher: Fetcher) =>
  (args: RouteComponentProps): WtrThunkAction =>
  (dispatch, getState) =>
    getIsWineWeekRedirectMatch(args, getState)
      ? Promise.resolve()
      : fetcher(args)(dispatch, getState);
