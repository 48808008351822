import React from 'react';
import { RichTextParagraphProps } from '../types';
import { buildElementStyling } from '../utils';

const RichTextParagraph = ({ children, value, removeEmptyParagraphs }: RichTextParagraphProps) => {
  const nodeChildrenText = value?.children?.[0]?.text;

  // Some values can have nested children e.g value.children so need to ensure we're dealing with a direct child.
  if (removeEmptyParagraphs && nodeChildrenText) {
    if (nodeChildrenText.trim() === '') return null;
  }

  const { className, style } = buildElementStyling({
    baseClass: 'richTextParagraph',
    value,
  });

  return (
    <p style={style} className={className}>
      {children}
    </p>
  );
};

RichTextParagraph.displayName = 'RichTextParagraph';

export default RichTextParagraph;
