import React from 'react';
import PropTypes from 'prop-types';

import { PortableText } from '@portabletext/react';

import cqResponsivePropType from 'constants/data-shapes/cq-responsive';
import analyticsPropType from 'constants/data-shapes/analytics';

import { Card } from '@johnlewispartnership/wtr-ingredients/ingredients/Card';

import { RICH_TEXT_NAME } from 'components/AemComponent/analyticsConstants';
import {
  anchor as Anchor,
  bold,
  heading1,
  heading2,
  heading3,
  heading4,
  heading5,
  heading6,
  italics,
  listItem,
  orderedList,
  paragraph as Paragraph,
  span,
  tableBody,
  tableCaption,
  tableCell,
  tableContainer,
  tableHeading,
  tableRow,
  underline,
  unorderedList,
} from 'components/RichText/serializers';
import { richTextNode } from 'constants/types/rich-text';
import classNames from 'classnames';

import promoClick from 'analytics/aem/promo-click';

import { getNewLineCharacterCount, richTextSerializer } from './utils';
import styles from './RichText.scss';

const RichText = ({
  data: {
    analytics,
    analyticsTags,
    blocks,
    componentId,
    cqResponsiveClasses,
    noMargin = false,
    styleIds,
    valid = true,
  },
  position,
  removeEmptyParagraphs = false,
}) => {
  if (!blocks || !blocks.length || !valid) return null;

  const onRichTextClick = link => {
    const { promoName, ...remainingAnalyticsProps } = analytics || {};
    promoClick({
      ...link,
      ...remainingAnalyticsProps,
      creative: 'Rich Text',
      id: componentId,
      name: promoName,
      position,
    });
  };

  const serializers = {
    unknownMark: () => null,
    unknownType: () => null,
    unknownBlockStyle: () => null,
    unknownList: () => null,
    unknownListItem: () => null,
    marks: {
      span: ({ text }) => {
        const numberOfNewLines = getNewLineCharacterCount(text);
        return (
          <>
            {[...Array(numberOfNewLines - 1)].map(() => (
              <br />
            ))}
          </>
        );
      },
    },
    block: {
      anchor: props => <Anchor {...props} onRichTextClick={onRichTextClick} />,
      bold,
      heading1,
      heading2,
      heading3,
      heading4,
      heading5,
      heading6,
      italics,
      listItem,
      orderedList,
      paragraph: props => <Paragraph {...props} removeEmptyParagraphs={removeEmptyParagraphs} />,
      span,
      tableBody,
      tableCaption,
      tableCell,
      tableContainer,
      tableHeading,
      tableRow,
      underline,
      unorderedList,
    },
  };

  const richTextBlocks = richTextSerializer(blocks);

  // card style is property set on the rich text component
  // card style mean show rich text inside white box, ie. card
  const onWhiteBackground =
    styleIds && styleIds.includes('faq_landing_page_frequent_questions_card');

  return onWhiteBackground ? (
    <section
      className={classNames(cqResponsiveClasses, styles.richText, {
        [styles.noMargin]: noMargin,
      })}
      {...analyticsTags}
    >
      <Card data-testid="rich-text-card" className={styles.noMargin}>
        <PortableText value={richTextBlocks} components={serializers} onMissingComponent={false} />
      </Card>
    </section>
  ) : (
    <section
      className={classNames(cqResponsiveClasses, styles.richText, {
        [styles.noMargin]: noMargin,
      })}
      {...analyticsTags}
    >
      <PortableText value={richTextBlocks} components={serializers} onMissingComponent={false} />
    </section>
  );
};

RichText.displayName = RICH_TEXT_NAME;

RichText.defaultProps = {
  data: {
    analytics: {},
    analyticsTags: undefined,
    blocks: [],
    componentId: '',
    cqResponsive: {},
    cqResponsiveClasses: undefined,
    noMargin: false,
    styleIds: [],
    valid: true,
  },
  removeEmptyParagraphs: false,
};

RichText.propTypes = {
  data: PropTypes.shape({
    analytics: analyticsPropType(),
    analyticsTags: PropTypes.shape({}),
    blocks: PropTypes.arrayOf(richTextNode),
    componentId: PropTypes.string,
    cqResponsive: cqResponsivePropType(),
    cqResponsiveClasses: PropTypes.arrayOf(PropTypes.string),
    noMargin: PropTypes.bool,
    styleIds: PropTypes.arrayOf(PropTypes.string),
    valid: PropTypes.bool,
  }),
  position: PropTypes.string.isRequired,
  removeEmptyParagraphs: PropTypes.bool,
};

export default RichText;
