import { apiCallback } from 'api';
import { DocumentNode } from 'graphql';
import getTrolleyQuery from 'api/definitions/trolley/trolley.graphql';
import getTrolleyWithRecommendation from 'api/definitions/trolley/get-trolley-with-recommendations.graphql';
import ProductFragment from '../favourites/product-fragment.graphql';

const graphDef = (type: string) => {
  if (type === 'getTrolley') {
    return {
      graph: true,
      query: (): DocumentNode => getTrolleyQuery,
      variables: ({ orderId }: { orderId: string }) => {
        return {
          orderId,
        };
      },
      queryString: { tag: 'get-trolley' },
    };
  }
  return {
    graph: true,
    query: (): string => `${ProductFragment}${getTrolleyWithRecommendation}`,
    variables: ({
      orderId,
      recommendationsSize = 8,
      recommendationsStart = 0,
    }: {
      orderId: string;
      recommendationsSize: number;
      recommendationsStart: number;
    }) => {
      return {
        orderId,
        recommendationsSize,
        recommendationsStart,
      };
    },
    queryString: { tag: 'get-trolley-with-recommendation' },
    headers: { experience: 'trolley-page' },
  };
};

export default {
  post: apiCallback(graphDef('getTrolley'), 'post'),
  getWithRecommendation: apiCallback(graphDef('getTrolleyWithRecommendations'), 'post'),
};
