import React, { useState, useMemo, useEffect, createContext } from 'react';
import classNames from 'classnames';
import propTypes from 'prop-types';

import styles from 'components/ClickToBuy/index.scss';
import { setClickToBuyContext } from 'utils/clickContext/clickToBuyContext';

export const ClickToBuyContext = createContext({
  isInClickToBuy: false,
  isAccordionOpen: false,
  setIsAccordionOpen: () => {},
});

function ClickToBuyInner({ analyticsTags, children, cqResponsiveClasses, position }) {
  const [isAccordionOpen, setIsAccordionOpen] = useState(false);

  const clickToBuyContextValue = useMemo(
    () => ({
      isInClickToBuy: true,
      isAccordionOpen,
      setIsAccordionOpen,
    }),
    [isAccordionOpen],
  );

  useEffect(() => {
    if (isAccordionOpen) {
      window.dispatchEvent(new Event('resize'));
    }
  }, [isAccordionOpen]);

  return (
    <ClickToBuyContext.Provider value={clickToBuyContextValue}>
      <div
        id={`trading-asset-${position}`}
        data-testid="click-to-buy-component"
        className={classNames(styles.tradingCell, cqResponsiveClasses)}
        {...analyticsTags}
        onClickCapture={setClickToBuyContext}
        onKeyDownCapture={setClickToBuyContext}
      >
        {children}
      </div>
    </ClickToBuyContext.Provider>
  );
}

ClickToBuyInner.propTypes = {
  analyticsTags: propTypes.shape({}),
  cqResponsiveClasses: propTypes.arrayOf(propTypes.string),
  children: propTypes.node,
  position: propTypes.string,
};

ClickToBuyInner.defaultProps = {
  analyticsTags: undefined,
  cqResponsiveClasses: undefined,
  children: undefined,
  position: undefined,
};

export default ClickToBuyInner;
