import uuid from 'uuid/v4';

import {
  COLUMN_LAYOUT,
  FORGOTTEN_FAVOURITES,
  HERO_BANNER,
  SHOP_WINDOWS,
} from 'constants/aemComponentNames';

const FULL_PAGE_COMPONENTS = [FORGOTTEN_FAVOURITES, HERO_BANNER, SHOP_WINDOWS];

const isFullWidth = ({ resourceType, fullWidth }) =>
  fullWidth || FULL_PAGE_COMPONENTS.includes(resourceType);

const extractLastElement = array => [array.slice(0, array.length - 1), array[array.length - 1]];

const newCenterLayoutComponent = childComponents => ({
  componentId: `column-placeholder-${uuid()}`,
  childComponents,
  cqResponsive: {
    deviceBreakpoints: [
      { width: '12', name: 'xs', auto: false, visible: true },
      { width: '12', name: 'sm', auto: false, visible: true },
      { width: '12', name: 'md', auto: false, visible: true },
      { width: '12', name: 'lg', auto: false, visible: true },
      { width: '12', name: 'default', auto: false, visible: true },
    ],
  },
  resourceType: COLUMN_LAYOUT,
  styleIds: [],
  uniqueId: `column-placeholder-${uuid()}`,
});

// The contents of this file is a dirty mess, hopefully some day in the future it can be deleted and we can forget it ever
// happened. So what's going on? Normally content is displayed in a 1200px, however some components are 'fullWidth' (via a property)
// or inheriently fullWidth e.g. Hero Banner.

// This logic isn't understood in the author and this is one of the key reasons why the author preview doesn't match the live site.

// So sometimes we need to break out of the center column to allow something to be full width but we want to do so in a way that will allow AEM
// to take over the responsibility in the future. Ideally, when components should be contained inside 1200px column, AEM will send a wrapper container
// that I have mocked with the newCenterLayoutComponent above.

// The problem is that getting the backend work to do a massive migration of all the pages will be a high-risk and unpopular suggestion. If you are reading this in 2 years
// then I am truely sorry.

// Note, when this is called on the Graphql path (for interstitials), the locations come back as null, not undefined.

const addColumnToLocation = components => {
  if (!components) {
    return undefined;
  }

  return components.reduce((previousValue, component) => {
    if (isFullWidth(component)) return [...previousValue, component];

    const [restOfArray, previousComponent] = extractLastElement(previousValue);

    if (!previousComponent || isFullWidth(previousComponent))
      return [...previousValue, newCenterLayoutComponent([component])];

    return [
      ...restOfArray,
      {
        ...previousComponent,
        childComponents: [...previousComponent.childComponents, component],
      },
    ];
  }, []);
};

export default input =>
  Object.entries(input).reduce(
    (previousValue, [key, object]) => ({
      ...previousValue,
      [key]: addColumnToLocation(object),
    }),
    {},
  );
