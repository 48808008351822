import { createSelector } from 'reselect';

import urls, { shopFromPreviousURL } from 'constants/urls';

import { getPageType } from 'utils/get-page-type';

const getRouting = state => state?.router;

export const getLocation = createSelector(getRouting, ({ location = {} } = {}) => location);

export const getLocationSearchParams = createSelector(
  getLocation,
  location => (location && location.search) || '',
);

export const getPathname = createSelector(getLocation, location =>
  location ? location.pathname : undefined,
);

export const getPreviousPath = createSelector(getLocation, location =>
  location ? location.previousPath : undefined,
);

export const getIsSsrPage = createSelector(
  getPreviousPath,
  previousPath => typeof previousPath === 'undefined',
);

export const getRoutingPageType = createSelector(getPathname, path =>
  path ? getPageType(path) : path,
);

export const getOrderIdFromPath = createSelector(getPathname, (path = '') =>
  path.includes(shopFromPreviousURL('')) ? path.replace(shopFromPreviousURL(''), '') : undefined,
);

// Too specific name for a too specific query
export const userIsTryingToBookSlotComingFromMealDealsPage = ({
  router: {
    location: { previousPath, previousPaths },
  },
}) => {
  /** return negative if previousPaths is empty */
  if (!previousPaths?.length) {
    return false;
  }

  /** return positive if previousPath was mealDealPromoPath */
  if (previousPath?.includes(urls.mealDealPromoPath)) {
    return true;
  }

  /**
   * Check if user went in mealDealPromoPath at any moment
   * and return positive only if the user went right before trying to book (or change) a slot
   */
  const mealDealIndexes = previousPaths
    .map((path, index) => [path, index])
    .filter(([path]) => path.includes(urls.mealDealPromoPath))
    .map(([, index]) => index);

  if (!mealDealIndexes.length) {
    return false;
  }

  const mealDealIndex = Math.max(mealDealIndexes);
  const mealDealFound = mealDealIndex >= 0;

  if (mealDealFound) {
    const pathsAfterMealDeal = previousPaths.filter((_, index) => index > mealDealIndex);
    return (
      pathsAfterMealDeal.filter(
        path => !path.includes('bookslot') && !path.includes('serviceselection'),
      ).length === 0
    );
  }

  return false;
};

export const isSuppressAlternatives = createSelector(
  getLocation,
  location =>
    location.pathname.includes(urls.offers) ||
    location.pathname.includes(urls.offerDetails) ||
    location.pathname.includes(urls.featuredOffers) ||
    location.pathname.includes(urls.mealDealPromoPath) ||
    location.pathname.includes(urls.bundles),
);
