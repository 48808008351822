import React from 'react';
import { Route } from 'react-router-dom';
import root from 'window-or-global';
import render from 'react-render';

// Don't import any project code above index.scss as it must appear first in the CSS
import 'styles/index.scss';
import 'utils/sentry-wrapper';
import { getFeatureFlags } from 'utils/feature-flags';

import { createStore } from 'redux/create-store';
import initScrollRestorer from 'scroll-restorer';

import { addInteractiveElement } from 'utils/add-interactive-element';
import { clientHistory } from 'utils/history';
import { removeClientSideLegacyCookies } from 'utils/cookie';
import setupTestEntryPoints from 'utils/test-entry-points';
import { addWebViewDialogEventListeners } from 'utils/webview/dialog';
import addPageUnloadListener from 'utils/add-page-unload-listener';
import { waitFor } from 'utils/settimeout-wrapper';

import { notifyLoginSuccess } from 'analytics/login-tracking';
import { readCookieConsentCookies } from 'redux/modules/cookies/actions';
import { addToTrolleyPostAuthentication } from 'redux/modules/sessions/actions/add-to-trolley-post-authentication';
import { favouritePostAuthentication } from 'redux/modules/sessions/actions/favourite-post-authentication';

import OnRouteChange from 'route-actions/RouteChange';
import preRenderActions from 'route-actions/pre-render-actions';

import AppComponent from 'components/App';

import 'setup-dev-browser';
import { initApmRum } from 'utils/apm-rum';

const { apmRum } = getFeatureFlags();

initApmRum({ isEnabled: apmRum });
addPageUnloadListener();
addWebViewDialogEventListeners();
initScrollRestorer(clientHistory);

// eslint-disable-next-line no-underscore-dangle
const initialData = root.__PRELOADED_STATE__;
// eslint-disable-next-line no-underscore-dangle
if (initialData) root.__PRELOADED_STATE__ = true;

// This reference to the dataLayer is needed to make the main GTM script work. It's entirely reasonable
// to relocate to a better area though.
// eslint-disable-next-line no-restricted-properties
window.dataLayer = window.dataLayer || [];
const store = createStore(clientHistory, initialData);

const renderApp = () =>
  render(
    <OnRouteChange>
      <Route component={AppComponent} />
    </OnRouteChange>,
    clientHistory,
    store,
    root.document.getElementById('content'),
  );

const entryPoint = async () => {
  removeClientSideLegacyCookies();
  if (module.hot) module.hot.accept();
  store.dispatch(readCookieConsentCookies());
  try {
    await preRenderActions(store);
  } catch (e) {
    // swallow, handled in middleware
  } finally {
    renderApp();

    store.dispatch(notifyLoginSuccess());
    store.dispatch(addToTrolleyPostAuthentication());
    waitFor(500).then(() => store.dispatch(favouritePostAuthentication()));
    addInteractiveElement();
    setupTestEntryPoints(store);
  }
};

entryPoint();
