import React from 'react';
import { Redirect } from 'react-router-dom';
import { getFeatureFlags } from 'utils/feature-flags';
import locator from 'utils/locator';
import { loginPagePath } from '.';

// this supports SSR redirects
export function RedirectToLoginPage() {
  if (getFeatureFlags().identity_enableOAuth2Web) {
    locator.href = loginPagePath();
    return null;
  }

  return <Redirect to={loginPagePath()} />;
}
