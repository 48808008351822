import mapProductsIntoLegacyFormat from 'redux/transforms/transform-product-into-legacy-format';
import { Product } from 'constants/products';
import { TransformedCriteria, TransformedConflict, TransformedLegacyProduct } from './types';

type ProductsAndRecommendations<Data extends Record<string, unknown>> = Data & {
  recommendations: Product[];
} & ({ products: Product[] } | { componentsAndProducts: Product[] });

type TransformedProducts<Data extends Record<string, unknown>> = Data & {
  criteria: TransformedCriteria[];
  conflicts: TransformedConflict[];
  totalMatches: number;
} & (
    | { products: TransformedLegacyProduct[] }
    | { componentsAndProducts: TransformedLegacyProduct[] }
  );

export type TransformedProductsAndRecommendations<Data extends Record<string, unknown>> =
  TransformedProducts<Data> & {
    recommendations: TransformedLegacyProduct[];
  };

const mapProductsAndRecommendationsIntoLegacyFormat = <Data extends Record<string, unknown>>(
  data: ProductsAndRecommendations<Data>,
): TransformedProductsAndRecommendations<Data> => {
  const dataWithMappedProducts = mapProductsIntoLegacyFormat(data) as TransformedProducts<Data>;
  const { recommendations: products = [] } = data;
  const { products: recommendations } = mapProductsIntoLegacyFormat({
    products,
  }) as { products: TransformedLegacyProduct[] };

  return {
    ...dataWithMappedProducts,
    recommendations,
  };
};

export default mapProductsAndRecommendationsIntoLegacyFormat;
