import { connect } from 'react-redux';

import amendingOrder from 'redux/modules/trolley/selectors/amending-order';

import { readMonetateCookie } from 'redux/modules/cookies/actions';
import { isUserLoggedIn } from 'redux/modules/sessions/selectors';

import App from './App';

export default connect(
  state => ({
    hasSitePinBar: amendingOrder(state),
    isUserLoggedIn: isUserLoggedIn(state),
  }),
  { readMonetateCookie },
)(App);
