import { createSelector } from 'reselect';
import root from 'window-or-global';

import { getCookie, removeCookie } from 'utils/cookie';

import { getCustomerId, isUserLoggedIn } from 'redux/modules/sessions/selectors';

const getValuesFromLocalStorage = key => {
  try {
    const values = JSON.parse(root.localStorage.getItem(key));
    return values.every(value => typeof value === 'string') ? values : null;
  } catch (e) {
    return null;
  }
};

const saveValuesToLocalStorage = (key, values) => {
  try {
    root.localStorage.setItem(key, JSON.stringify(values));
    return values.every(value => typeof value === 'string') ? values : null;
  } catch (e) {
    return null;
  }
};

const migrateFromCookieToLocalStorage = key => {
  try {
    const values = getCookie(key);
    if (Array.isArray(values) && values.every(value => typeof value === 'string')) {
      root.localStorage.setItem(key, JSON.stringify(values));
    }
  } catch (e) {
    // ignore
  }
  removeCookie(key);
};

export const getJotterStorage = createSelector(
  [isUserLoggedIn, getCustomerId],
  (authenticated, customerId) => {
    const anonymousOrAuthenticatedValue = (valueAnon, valueAuth) => explicitAnon =>
      !authenticated || explicitAnon ? valueAnon : valueAuth;

    const key = anonymousOrAuthenticatedValue('multisearch:-1', `multisearch:${customerId}`);

    migrateFromCookieToLocalStorage(key());

    return {
      clear: ({ anonymous } = {}) => saveValuesToLocalStorage(key(anonymous), []),
      set: ({ anonymous, values } = {}) => saveValuesToLocalStorage(key(anonymous), values),
      get: ({ anonymous } = {}) => getValuesFromLocalStorage(key(anonymous)),
    };
  },
);
