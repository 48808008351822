import { RecipeSummaryItem, RecipeFilter } from 'api/definitions/recipes/index.d';
import { MealPlannerState } from '../index.d';
import { MEAL_PLANNER_SUCCESS, MEAL_PLANNER_FAILURE } from '../actions/types';

const SERVING_SIZE_FILTER_TITLE = 'Serving Size';
const DIETARY_REQUIREMENTS_FILTER_TITLE = 'Dietary';

const initialState: MealPlannerState = Object.freeze({
  error: false,
  loading: false,
  recipes: [],
  servingSizes: [],
  dietaryRequirements: [],
});

type MealPlannerAction = {
  type: string;
  result: {
    recipes: RecipeSummaryItem[];
    filters: RecipeFilter[];
  };
};

export default (state = initialState, action: MealPlannerAction) => {
  switch (action.type) {
    case MEAL_PLANNER_SUCCESS: {
      const result = action.result || {};
      const filters = result.filters || [];

      return {
        ...state,
        recipes: result.recipes,
        servingSizes:
          filters?.find((filter: RecipeFilter) => filter.title === SERVING_SIZE_FILTER_TITLE)
            ?.values || [],
        dietaryRequirements:
          filters?.find(
            (filter: RecipeFilter) => filter.title === DIETARY_REQUIREMENTS_FILTER_TITLE,
          )?.values || [],
      };
    }

    case MEAL_PLANNER_FAILURE: {
      return {
        ...state,
        error: true,
        loading: false,
      };
    }

    default:
      return state;
  }
};
