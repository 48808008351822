import React from 'react';
import { RichTextNode } from '../types';

const RichTextTableCaption = ({ children }: RichTextNode) => {
  return <caption className="sr-only">{children}</caption>;
};

RichTextTableCaption.displayName = 'RichTextTableCaption';

export default RichTextTableCaption;
