import createReducer from 'redux/createReducer';
import update from 'immutability-helper';
import { LegacyProductReference } from 'constants/products';

import { FavouritesExperienceResponse } from 'api/definitions/favourites-experience';
import { isFavouritesSuccessfullyLoaded } from 'redux/modules/favourites/selectors/get-favourites';
import { GET_FAVOURITES } from '../actions/types';

import { initialState } from '../state';
import {
  FavouritesExperienceRequestState,
  FavouritesExperienceSuccessState,
  FavouritesExperienceState,
  TrimmedCategory,
  Criteria,
  FilterCategory,
} from '../types';

interface FavouritesExperienceFetcherResult
  extends Omit<
    FavouritesExperienceResponse,
    'recommendations' | 'content' | 'componentsAndProducts'
  > {
  componentsAndProducts: LegacyProductReference[];
  recommendations: LegacyProductReference[];
  criteria: Criteria;
  filterCategories: FilterCategory[];
}

export type FavouritesExperienceRequestAction = {
  start: number;
  onOffer: boolean;
  categoryId: string | null;
};

export type FavouritesExperienceSuccessAction = {
  result: FavouritesExperienceFetcherResult;
  start: number;
  categoryId: string | undefined;
};

const getFavouritesExperienceRequest = (
  state: FavouritesExperienceState,
  { onOffer, start, categoryId }: FavouritesExperienceRequestAction,
): FavouritesExperienceRequestState => {
  return {
    ...state,
    offersFilter: !!onOffer,
    categoryIdFilter: categoryId,
    loading: !start,
    loadingMore: !!start,
  };
};

const getFavouritesExperienceSuccess = (
  state: FavouritesExperienceRequestState,
  {
    result: {
      categories,
      componentsAndProducts,
      filterCategories,
      recommendations,
      criteria,
      ignoreCategories,
      metadata,
    },
    start,
    categoryId,
  }: FavouritesExperienceSuccessAction,
): FavouritesExperienceSuccessState => {
  const trimmedCategories: TrimmedCategory[] = categories.map(
    ({ name, id, orderedComponentsAndProducts }) => ({
      name,
      id,
      orderedComponentsAndProducts,
    }),
  );

  const isLoadMore = start && isFavouritesSuccessfullyLoaded(state);

  // If loading more products, check to see if the categories match and merge the products into the same category
  // this is to prevent overwriting what already exists in state
  if (isLoadMore) {
    const lastCategoryInState = state.categories[state.categories.length - 1];
    const firstCategoryInTrimmed = trimmedCategories[0];

    if (lastCategoryInState.id === firstCategoryInTrimmed.id) {
      trimmedCategories[0].orderedComponentsAndProducts = [
        ...lastCategoryInState.orderedComponentsAndProducts,
        ...firstCategoryInTrimmed.orderedComponentsAndProducts,
      ];
    }
  }

  const offerGroup = criteria?.filters.find(filter => filter.group === 'OFFER_TYPE');
  const offersFilter = offerGroup?.filters[0].applied || false;

  return {
    loading: false,
    loadingMore: false,
    categories: isLoadMore
      ? update(state.categories, { $push: trimmedCategories })
      : trimmedCategories,
    componentsAndProducts: isLoadMore
      ? update(state.componentsAndProducts, { $push: componentsAndProducts })
      : componentsAndProducts,
    categoryIdFilter: categoryId,
    rootCategories: filterCategories,
    ignoreCategories,
    recommendations,
    totalResults: metadata.totalResults,
    offersFilter,
  };
};

const actionTypeReducerMap = [
  [GET_FAVOURITES.request, getFavouritesExperienceRequest],
  [GET_FAVOURITES.success, getFavouritesExperienceSuccess],
] as const;

export type PayloadMap = ReducerMapTupleToRecord<typeof actionTypeReducerMap>;
export default createReducer(initialState, actionTypeReducerMap);
