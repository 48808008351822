import round from 'lodash/round';
import { getCustomerEmail } from 'redux/modules/accounts/selectors/get-customer-account';
import { isBookedSlotACollectionSlot } from 'redux/modules/order/selectors/get-order-slot-selectors';
import { getEstimatedTotals } from 'redux/modules/order/selectors/get-order-totals';
import {
  getQualifiedPromoCodesDescriptionsInline,
  getQualifiedPromoCodesInline,
} from 'redux/modules/order/selectors/promo-codes';
import { getCustomerHasACompletedOrder } from 'redux/modules/orders/selectors';
import { getCustomerId, getCustomerOrderId } from 'redux/modules/sessions/selectors';
import { createSelector } from 'reselect';
import sha1 from 'sha1';
import { isEmbeddedInWebView } from 'utils/webview';

const limitString = maxLength => value => (value || '').slice(0, maxLength);
const string64 = limitString(64);

export const getImpactConversion = createSelector(
  [
    getCustomerEmail,
    getCustomerId,
    getCustomerOrderId,
    getQualifiedPromoCodesInline,
    getQualifiedPromoCodesDescriptionsInline,
    getEstimatedTotals,
    isBookedSlotACollectionSlot,
    getCustomerHasACompletedOrder,
  ],
  (
    email,
    customerId,
    orderId,
    promoCodesInline,
    promoCodesDescriptionsInline,
    estimatedTotals,
    isCollection,
    customerHasACompletedOrder,
  ) => {
    // "orderDiscount" must be order-level savings and it will be applied
    // evenly by Impact across item subTotals
    const orderLineAmount = estimatedTotals?.orderLines?.amount;
    const totalSavingsAmount = estimatedTotals?.totalSavings?.amount ?? 0;
    const orderSubTotalPostDiscount =
      typeof orderLineAmount === 'number'
        ? round(orderLineAmount - totalSavingsAmount, 2).toFixed(2)
        : undefined;

    return {
      currencyCode: 'GBP',
      // TODO: snyk code test warning - InsecureHash
      customerEmail: sha1(email),
      customerId,
      money1: orderSubTotalPostDiscount,
      orderId,
      orderPromoCode: string64(promoCodesInline),
      orderPromoCodeDesc: string64(promoCodesDescriptionsInline),
      orderSubTotalPostDiscount,
      text1: isEmbeddedInWebView() ? 'App' : 'Web',
      text2: isCollection ? 'C&C' : 'Delivery',
      customerStatus: customerHasACompletedOrder ? 'Returning' : 'New',
    };
  },
);

export const getImpactIdentify = state => {
  const email = getCustomerEmail(state);
  const customerId = getCustomerId(state);

  return {
    // TODO: snyk code test warning - InsecureHash
    customerEmail: email ? sha1(email) : '',
    customerId: customerId === '-1' ? '' : customerId ?? '',
  };
};
