export const CLEAR = 'waitrose/products/CLEAR' as const;
export const FAILED = 'waitrose/products/FAILED' as const;
export const FAVOURITE_TOGGLING = 'waitrose/products/FAVOURITE_TOGGLING' as const;
export const FAVOURITE_TOGGLED = 'waitrose/products/FAVOURITE_TOGGLED' as const;
export const FAVOURITE_FAILED = 'waitrose/products/FAVOURITE_FAILED' as const;
export const HOMEPAGE_FAILED = 'waitrose/HOMEPAGE_FAILED' as const;
export const HOMEPAGE_LOADED = 'waitrose/HOMEPAGE_LOADED' as const;
export const HOMEPAGE_LOADING = 'waitrose/HOMEPAGE_LOADING' as const;
export const INVALIDATEPRODUCTS = 'waitrose/products/INVALIDATEPRODUCTS' as const;
export const LOADED = 'waitrose/products/LOADED' as const;
export const LOADING = 'waitrose/products/LOADING' as const;
export const NEXTLOADED = 'waitrose/products/NEXT_LOADED' as const;
export const OFFER_PRODUCTS_LOADED = 'waitrose/products/OFFER_PRODUCTS_LOADED' as const;
export const RESETERROR = 'waitrose/products/RESETERROR' as const;
export const SETACTIVEPROMOTION = 'waitrose/products/SETACTIVEPROMOTION' as const;
export const MEAL_DEAL_LINE_PRODUCTS_LOADED =
  'waitrose/products/MEAL_DEAL_LINE_PRODUCTS_LOADED' as const;
export const MEAL_DEAL_PRODUCTS_LOADED = 'waitrose/products/MEAL_DEAL_PRODUCTS_LOADED' as const;
export const RECOMMENDATIONS_PLP_PRODUCTS_FULFILLED =
  'waitrose/recommendations/plp/RECOMMENDATIONS_PRODUCTS_FULFILLED';
