import { createSelector } from 'reselect';
import { SEASONAL_MENU } from 'constants/experience-fragments';
import { getStateAtNamespaceKey } from 'redux/get-state-at-namespace-key';

const getSeasonalMenuExperienceFragmentState = (state: WtrState) =>
  getStateAtNamespaceKey(state, 'experienceFragments')[SEASONAL_MENU.key];

export const getSeasonalMenuExperienceFragment = createSelector(
  [getSeasonalMenuExperienceFragmentState],
  seasonalMenuExperienceFragment => {
    const seasonalMenuTitle = seasonalMenuExperienceFragment?.pageTitle?.text;
    const seasonalMenuContent = seasonalMenuExperienceFragment?.locations?.[SEASONAL_MENU.location];

    return {
      seasonalMenuTitle,
      seasonalMenuContent,
    };
  },
);
