import history from 'utils/history';
import queryString from 'query-string';
import omit from 'lodash/omit';
import { setAccessToken } from 'redux/modules/sessions/actions/set-access-token';

const allowedOriginValues = ['sign_on_as_customer'];

export const extractAccessTokenAndCustomerIdFromQueryString = () => {
  const queryAsString = history.getLocation().search;

  if (queryAsString) {
    const parsedQuery = queryString.parse(queryAsString);
    const result = {};

    if (parsedQuery.access_token) {
      result.accessToken = parsedQuery.access_token;
    }

    if (parsedQuery.customerId && /^-?\d+$/.test(parsedQuery.customerId)) {
      result.customerId = parsedQuery.customerId;
    }

    if (parsedQuery.origin && allowedOriginValues.includes(parsedQuery.origin)) {
      result.origin = parsedQuery.origin;
    }

    return result;
  }

  return {};
};

export const replaceCurrentUrlWithoutAccessTokenAndCustomerIdInQueryString = () => {
  const location = history.getLocation();

  const newSearch = omit(queryString.parse(location.search), [
    'access_token',
    'customerId',
    'origin',
  ]);

  history.replace({
    pathname: location.pathname,
    search: queryString.stringify(newSearch),
    hash: location.hash,
  });
};

export const signOnAsCustomerFromUrlToken = dispatch => {
  const { accessToken, customerId, origin } = extractAccessTokenAndCustomerIdFromQueryString();

  if (accessToken) {
    dispatch(setAccessToken(accessToken, customerId, origin));
    replaceCurrentUrlWithoutAccessTokenAndCustomerIdInQueryString();
  }
};
