import {
  getCustomerId,
  getCustomerOrderId,
  getPrincipalId,
} from 'redux/modules/sessions/selectors';
import { sentry } from 'utils/sentry-wrapper';
import { createStoreSubscriber } from './create';

export default store =>
  createStoreSubscriber({
    once: false,
    onChange: (dispatch, getState) => {
      const state = getState();

      // TODO: Elastic RUM Migration - https://www.jlpit.com/jira/browse/WPIP-52934
      if (sentry && sentry.configureScope) {
        sentry.configureScope(scope => {
          scope.setUser({ id: getCustomerId(state) });
          scope.setTag('orderId', getCustomerOrderId(state));
          scope.setTag('principalId', getPrincipalId(state));
        });
      }
    },
    select: state => state.sessions.customerId,
    store,
  });
