import React from 'react';

import { hasBrandImage, hasRoundel } from 'utils/promotionUtilities';

import PromoIconography from 'components/PromoIconography';

import { generateSizesByBreakpoint } from 'components/Image/generateSizesByBreakpoint';
import { buildSrcSet } from 'components/Image/Sources';
import { buildUrl } from 'utils/backgroundImage';
import styles from './index.scss';

interface PromoIcon {
  myWaitroseIcon: {
    display: boolean;
    data: {
      supercharged: boolean;
    };
  };
}

interface PromoImage {
  altText: string;
  src: string;
  dynamicImages: {
    highQuality95: string;
  };
  assetMetadataHeight: string;
  assetMetadataWidth: string;
}

interface PromoImagery {
  hideMessage: boolean;
  icons: PromoIcon | null;
  image: {
    landscapeImage: PromoImage;
    portraitImage: PromoImage;
  } | null;
  externalImageSrc?: boolean;
  imageRef?: React.RefObject<HTMLImageElement> | null;
  lazyLoad?: boolean;
}

const hasMyWaitrose = (icons: PromoIcon) => !!icons?.myWaitroseIcon?.display;
const isSupercharged = (icons: PromoIcon) => !!icons?.myWaitroseIcon?.data?.supercharged;

const PromoImagery = ({
  hideMessage = false,
  icons = null,
  image = null,
  externalImageSrc = false,
  imageRef = null,
  lazyLoad = true,
}: PromoImagery) => {
  let wrapperClass = styles.wrapper;

  if (icons) {
    if (hasBrandImage(icons)) {
      if (hasMyWaitrose(icons) && hasRoundel(icons)) {
        wrapperClass = styles.bannerWithBrand;
      }

      if (isSupercharged(icons)) {
        wrapperClass = styles.superchargedWithBrand;
      }
    }
  }

  const { portraitImage, landscapeImage } = image || {};

  const renderImage = (img: PromoImage | undefined, style: string) => {
    const dynamicImages = img?.dynamicImages;
    const loadingProp = lazyLoad ? 'lazy' : undefined;

    if (dynamicImages) {
      return (
        <img
          ref={imageRef}
          height={img?.assetMetadataHeight}
          width={img?.assetMetadataWidth}
          srcSet={buildSrcSet(img?.dynamicImages?.highQuality95)}
          alt={img.altText || ''}
          loading={loadingProp}
          sizes={generateSizesByBreakpoint({
            fallback: '75vw',
            xs: '100vw',
          })}
          className={style}
        />
      );
    }

    if (img?.src) {
      return (
        <img
          ref={imageRef}
          height={img?.assetMetadataHeight}
          width={img?.assetMetadataWidth}
          src={`${buildUrl(img.src, externalImageSrc)}`}
          alt={img.altText || ''}
          loading={loadingProp}
          className={style}
        />
      );
    }

    return null;
  };

  return (
    <div data-testid="promo-imagery" className={wrapperClass}>
      <div className={styles.imageWrapper}>
        {renderImage(portraitImage, styles.portrait)}
        {renderImage(landscapeImage, styles.landscape)}
        <PromoIconography icons={icons} hideMessage={hideMessage} />
      </div>
    </div>
  );
};

export default PromoImagery;
