import createReducer from 'redux/createReducer';
import setSelectedBranch from 'redux/modules/slot-booking/reducers/set-selected-branch';
import clearSelectedBranch from 'redux/modules/slot-booking/reducers/clear-selected-branch';
import setSelectedAddress from 'redux/modules/slot-booking/reducers/set-selected-address';
import setSelectedDate from 'redux/modules/slot-booking/reducers/set-selected-date';
import setNewSlotSelected from 'redux/modules/slot-booking/reducers/set-new-slot-selected';
import lastNonBookslotPage from 'redux/modules/slot-booking/reducers/last-non-bookslot-page';
import setPageDefaultsOnRouteChange from 'redux/modules/slot-booking/reducers/set-page-defaults-on-route-change';
import setFulfilmentType from 'redux/modules/slot-booking/reducers/set-fulfilment-type';
import setSelectedSlotGridType from 'redux/modules/slot-booking/reducers/set-selected-slot-grid-type';
import showExpiredOffersModal from 'redux/modules/slot-booking/reducers/show-expired-offers-modal';
import hideExpiredOffersModal from 'redux/modules/slot-booking/reducers/hide-expired-offers-modal';
import saveProposedSlot from 'redux/modules/slot-booking/reducers/save-proposed-slot';
import toggleGridLength from 'redux/modules/slot-booking/reducers/toggle-grid-length';
import toggleRestrictedProductsModal from 'redux/modules/slot-booking/reducers/toggle-restricted-products-modal';
import { setSlotBookingRedirectPath } from 'redux/modules/slot-booking/reducers/set-slot-booking-redirect-path';

import {
  SET_SELECTED_ADDRESS,
  SET_SELECTED_BRANCH,
  SET_SELECTED_DATE,
  SET_SELECTED_SLOT_GRID_TYPE,
  CLEAR_SELECTED_BRANCH,
  SET_FULFILMENT_TYPE,
  SHOW_EXPIRED_OFFERS_MODAL,
  HIDE_EXPIRED_OFFERS_MODAL,
  SAVE_PROPOSED_SLOT,
  SET_NEW_SLOT_SELECTED,
  SHOW_RESTRICTED_PRODUCTS_MODAL,
  HIDE_RESTRICTED_PRODUCTS_MODAL,
  CLEAR_SELECTED_ADDRESS,
  SET_SLOT_BOOKING_REDIRECT_PATH,
  TOGGLE_GRID_LENGTH,
} from 'redux/modules/slot-booking/actions/types';

import { LOCATION_CHANGE } from 'connected-react-router';

import initialState from '../state/initial-state';

const locationChangeActions = (state: WtrState, action: unknown) => {
  const newState = setPageDefaultsOnRouteChange(state, action);
  return lastNonBookslotPage(newState, action);
};

const actionTypeReducerMap = [
  [SET_SELECTED_ADDRESS, setSelectedAddress],
  [SET_SELECTED_BRANCH, setSelectedBranch],
  [SET_SELECTED_DATE, setSelectedDate],
  [SET_SELECTED_SLOT_GRID_TYPE, setSelectedSlotGridType],
  [CLEAR_SELECTED_BRANCH, clearSelectedBranch],
  [LOCATION_CHANGE, locationChangeActions],
  [SET_FULFILMENT_TYPE, setFulfilmentType],
  [SHOW_EXPIRED_OFFERS_MODAL, showExpiredOffersModal],
  [HIDE_EXPIRED_OFFERS_MODAL, hideExpiredOffersModal],
  [SAVE_PROPOSED_SLOT, saveProposedSlot],
  [SET_NEW_SLOT_SELECTED, setNewSlotSelected],
  [SHOW_RESTRICTED_PRODUCTS_MODAL, toggleRestrictedProductsModal],
  [HIDE_RESTRICTED_PRODUCTS_MODAL, toggleRestrictedProductsModal],
  [CLEAR_SELECTED_ADDRESS, setSelectedAddress],
  [SET_SLOT_BOOKING_REDIRECT_PATH, setSlotBookingRedirectPath],
  [TOGGLE_GRID_LENGTH, toggleGridLength],
] as const;

export type PayloadMap = ReducerMapTupleToRecord<typeof actionTypeReducerMap>;
export default createReducer(initialState, actionTypeReducerMap);
