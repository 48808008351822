import React from 'react';
import propTypes from 'prop-types';
import env from 'env/env';
import classNames from 'classnames';

import Sources from 'components/Image/Sources';

import styles from './index.scss';

const getImageUrl = (fileReference, scene7Url) => {
  if (scene7Url) return scene7Url;

  if (fileReference) return `${env.cfDomain}${fileReference}`;
  return null;
};

const Picture = ({
  altText,
  fileReference,
  hasFixedHeight,
  height,
  width,
  scene7Url,
  scene7UrlMobile,
  mobileHeight,
  mobileWidth,
  scene7UrlTablet,
  tabletHeight,
  tabletWidth,
  sizes,
  preload,
}) => {
  const imageUrl = getImageUrl(fileReference, scene7Url || scene7UrlTablet || scene7UrlMobile);

  if (!imageUrl) return null;

  return (
    <picture>
      <Sources
        scene7Url={scene7UrlMobile}
        breakpoint="mobile"
        sizes={sizes}
        height={mobileHeight}
        width={mobileWidth}
        preload={preload}
      />
      <Sources
        scene7Url={scene7UrlTablet}
        breakpoint="tablet"
        sizes={sizes}
        height={tabletHeight}
        width={tabletWidth}
        preload={preload}
      />
      <Sources
        scene7Url={scene7Url}
        sizes={sizes}
        height={height}
        width={width}
        preload={preload}
      />
      <img
        className={classNames(styles.image, {
          [styles.fixedHeight]: hasFixedHeight,
        })}
        alt={altText}
        loading={preload ? undefined : 'lazy'}
        // eslint-disable-next-line react/no-unknown-property
        fetchpriority={preload ? 'high' : undefined}
        src={imageUrl}
        height={height}
        width={width}
      />
    </picture>
  );
};

Picture.displayName = 'Picture';

Picture.defaultProps = {
  altText: undefined,
  fileReference: undefined,
  hasFixedHeight: undefined,
  height: undefined,
  scene7Url: undefined,
  scene7UrlMobile: undefined,
  mobileHeight: undefined,
  mobileWidth: undefined,
  scene7UrlTablet: undefined,
  tabletHeight: undefined,
  tabletWidth: undefined,
  sizes: undefined,
  width: undefined,
  preload: false,
};

Picture.propTypes = {
  altText: propTypes.string,
  fileReference: propTypes.string,
  hasFixedHeight: propTypes.bool,
  height: propTypes.string,
  scene7Url: propTypes.string,
  scene7UrlMobile: propTypes.string,
  mobileHeight: propTypes.string,
  mobileWidth: propTypes.string,
  scene7UrlTablet: propTypes.string,
  tabletHeight: propTypes.string,
  tabletWidth: propTypes.string,
  sizes: propTypes.string,
  width: propTypes.string,
  preload: propTypes.bool,
};

export default Picture;
